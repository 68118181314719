import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {map} from "rxjs/operators";
import {Capacitor} from "@capacitor/core";

@Injectable({
  providedIn: 'root'
})
export class NativeAppGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return of(Capacitor.isNativePlatform()).pipe(
      map(isNative => isNative || this.router.createUrlTree(['/']))
    );
  }
}
