import {LocationRepository as BaseRepository} from "../domain/endpoints.repositories";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {MyBuddyGard} from "../domain/models";
import {HttpHeaders, HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LocationRepository extends BaseRepository {
  // NOTE: Overrides parent method
  // Reason: Dates transformed using Date.toString() will not be recognized by API when passed as query param
  deviceHistory(deviceId: string, start: Date, end: Date): Observable<(MyBuddyGard.Domain.Models.Locations.Location)[]> {
    let params = new HttpParams()
      .set('deviceId',deviceId.toString())
      .set('start',start.toISOString())
      .set('end',end.toISOString())
    ;
    let headers = new HttpHeaders()
      .set('x-intellis-authorization', 'true')
      .set('x-intellis-authorization-policies', '')
    ;
    return this.http.get<(MyBuddyGard.Domain.Models.Locations.Location)[]>(this.config.apiBase + this.endpoint + '/deviceHistory', { params: params, headers: headers });
  }

  // NOTE: Overrides parent method
  // Reason: Endpoint does not require authorization
  postPairedDevice(request: MyBuddyGard.Api.Models.DeviceLocationAndToken): Observable<any> {
    let params = new HttpParams()
    ;
    let headers = new HttpHeaders()
      .set('x-intellis-client-tag', this.clientTag)
      .set('x-intellis-authorization', 'false')
      .set('x-intellis-authorization-policies', '')
    ;
    return this.http.post<any>(this.config.apiBase + this.endpoint + '/paired-device', <any>request, { params: params, headers: headers });
  }
}
