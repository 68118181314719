
/**************************************************************************************************************************************

             ▒▒                                                                                                                            
           ▒▒▒▒▒▒                                                                                                                          
         ▒▒▒▒▒▒▒▒▒▒                                                                                                                        
          ▒▒▒▒▒▒▒▒▒▒▒                                                                                                                      
            ▒▒▒▒▒▒▒                                                                                                                        
              ▒▒▒                     ██       ██    ██       ████████       ███████      ██            ██           ██        ███████     
 ▒▒▒▒▒               ▒▒▒▒▒▒           ██       ███   ██          ██          ██           ██            ██           ██       ██           
▒▒▒▒▒▒▒             ▒▒▒▒▒▒▒▒          ██       █████ ██          ██          ███████      ██            ██           ██       ███████      
▒▒▒▒▒▒▒              ▒▒▒▒▒▒▒          ██       ██  ████          ██          ██           ██            ██           ██             ██     
  ▒▒▒                 ▒▒▒▒            ██       ██    ██          ██          ███████      ███████       ██████       ██       ████████     
          ▒▒▒▒                                                                                                                             
        ▒▒▒▒▒▒▒▒                                                                                                                           
       ▒▒▒▒▒▒▒▒▒▒▒                                                                                                                         
         ▒▒▒▒▒▒▒▒▒                                                                                                                         
           ▒▒▒▒▒                                                                                                                           

***************************************************************************************************************************************
** Code generated by Intellis.Web.Http.ApplicationSchema.SchemaTypeScriptGenerator ** Changes may be overwritten ** http://intell.is **
**************************************************************************************************************************************/

/* Extend these definitions by inheritance to avoid code being overwritten */

export namespace Intellis {

    export namespace Domain {

        export namespace Validation {

            export interface IValidationState {
                members: {[key: string] : ((Intellis.Domain.Validation.IValidationStateMember)[])};
                isValid: boolean;
            }

            export interface IValidationStateMember {
                message: string;
            }
        }

        export namespace Security {

            export interface PermissionValueViewModel {
                discriminator: string;
                options: number;
            }

            export enum PermissionOptions {
                None = 0,
                Get = 1,
                Add = 2,
                Update = 4,
                Delete = 8,
                Search = 16,
                Query = 32,
                All = 255,
            }
        }

        export namespace Search {

            export interface IPagedSearchParameters {
                page: number;
                pageSize: number;
                sortBy: string;
                sortDescending: boolean;
            }
        }
    }

    export namespace Aws {

        export namespace Core {

            export namespace Cognito {

                export namespace Users {

                    export enum UserSearchProperty {
                        AllUsers = 0,
                        UserName = 1,
                        EmailAddress = 2,
                        PhoneNumber = 3,
                        FirstName = 4,
                        LastName = 5,
                    }
                }
            }
        }
    }
}
export namespace MyBuddyGard {

    export namespace Domain {

        export namespace Models {

            export interface TriggerActionNotification {
                type: string;
                message: string;
                seen: boolean;
                notificationDate: Date;
                accountId: string;
                id: string;
                deleted: boolean;
                version: number;
            }

            export interface DeviceLocation {
                id: number;
                deviceId: string;
                coordinates: MyBuddyGard.Domain.Models.Coordinates;
                batteryStatus: number | null;
                powerStatus: boolean;
                triggerPowerOnOffTrigger: boolean;
                locationUpdateDate: Date;
                sosPressed: boolean;
                nearbyWifis: (MyBuddyGard.Domain.Models.Trackers.Wifi)[];
                speed: number | null;
                direction: number | null;
                altitude: number | null;
                baseStations: (MyBuddyGard.Domain.Models.BaseStation)[];
                gpsSatelliteCount: number | null;
                rawMessage: string;
                settingsVersion: number;
                radioType: MyBuddyGard.Domain.Enumerations.RadioTypes | null;
                hostPowerStatus: boolean | null;
            }

            export interface Contact {
                name: string;
                phoneNumber: string;
            }

            export interface Wifi {
                name: string;
                macAddress: string;
                id: string;
                deleted: boolean;
                version: number;
            }

            export interface BaseStation {
                mcc: number;
                mnc: number;
                cellTower: string;
                areaCode: number;
                serialNumber: number;
                signalStrength: number;
            }

            export interface Coordinates {
                longitude: number;
                latitude: number;
                accuracy: number;
            }

            export interface UserAccount {
                userId: string;
                accountId: string;
                id: string;
                deleted: boolean;
                version: number;
            }

            export interface FeatureCapabilities {
                availableViaData: boolean;
                availableViaSms: boolean;
            }

            export interface DeviceCommandResponse {
                commandId: string;
                success: boolean;
                message: string;
                errorMessage: string;
                result: MyBuddyGard.Domain.Enumerations.DeviceCommandResponseResult;
            }

            export interface LocateCommand {
                commandId: string;
                discriminator: string;
                accountSlug: string;
                queueIdentifier: string;
                deviceId: string;
                deviceType: string;
                deviceTypeDiscriminator: string;
                trackerId: string;
            }

            export interface SosNumberSetupCommand {
                commandId: string;
                sosNumber: string;
                accountId: string;
                discriminator: string;
                accountSlug: string;
                queueIdentifier: string;
                deviceId: string;
                deviceType: string;
                deviceTypeDiscriminator: string;
                trackerId: string;
            }

            export interface ContactsCommand {
                commandId: string;
                contacts: (MyBuddyGard.Domain.Models.Contact)[];
                discriminator: string;
                accountSlug: string;
                queueIdentifier: string;
                deviceId: string;
                deviceType: string;
                deviceTypeDiscriminator: string;
                trackerId: string;
            }

            export interface MonitorCommand {
                commandId: string;
                monitorPhoneNumber: string;
                discriminator: string;
                accountSlug: string;
                queueIdentifier: string;
                deviceId: string;
                deviceType: string;
                deviceTypeDiscriminator: string;
                trackerId: string;
            }

            export interface FindCommand {
                commandId: string;
                discriminator: string;
                accountSlug: string;
                queueIdentifier: string;
                deviceId: string;
                deviceType: string;
                deviceTypeDiscriminator: string;
                trackerId: string;
            }

            export interface ImmobiliseCommand {
                commandId: string;
                immobilisationStatus: boolean;
                discriminator: string;
                accountSlug: string;
                queueIdentifier: string;
                deviceId: string;
                deviceType: string;
                deviceTypeDiscriminator: string;
                trackerId: string;
            }

            export interface PaymentAttemptWithStripeStatus {
                accountPaymentAttempt: MyBuddyGard.Domain.Models.AccountPaymentAttempt;
                errorType: string;
                errorCode: string;
                stripeStatus: string;
            }

            export interface AccountPaymentAttempt {
                account: MyBuddyGard.Domain.Models.Account.AccountInfo;
                date: Date;
                status: MyBuddyGard.Domain.Enumerations.PaymentStatus;
                lastFourDigits: string;
                id: string;
                deleted: boolean;
                version: number;
            }

            export interface DeviceCommand {
                commandId: string;
                discriminator: string;
                accountSlug: string;
                queueIdentifier: string;
                deviceId: string;
                deviceType: string;
                deviceTypeDiscriminator: string;
                trackerId: string;
            }

            export namespace Devices {

                export interface Device {
                    accountId: string;
                    deviceSettings: MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting;
                    pairCode: string;
                    paired: boolean;
                    datePaired: Date;
                    dateRegistered: Date;
                    registered: boolean;
                    deviceName: string;
                    deviceModel: string;
                    deviceType: number;
                    migrationCode: string;
                    sosNumber: string;
                    immobilised: boolean;
                    forceWakeup: boolean;
                    cellTowerPositioning: boolean;
                    lastLocation: MyBuddyGard.Domain.Models.Locations.Location;
                    lastWifiUpdate: MyBuddyGard.Domain.Models.Locations.Location;
                    lastUpdate: MyBuddyGard.Domain.Models.Locations.Location;
                    rules: (MyBuddyGard.Domain.Models.Rules.Rule)[];
                    subscription: MyBuddyGard.Domain.Models.Subscriptions.Subscription;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface SaveDevice {
                    deviceName: string;
                    sosNumber: string;
                    colour: string;
                    cellTowerPositioning: boolean;
                    deviceSettings: MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting;
                    subscriptionId: string;
                    commandOptions: (MyBuddyGard.Domain.Models.Devices.SaveDeviceCommandOption)[];
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface DeviceInfo {
                    accountId: string;
                    deviceSetting: MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting;
                    deviceName: string;
                    deviceModel: string;
                    sosNumber: string;
                    immobilised: boolean;
                    forceWakeup: boolean;
                    cellTowerPositioning: boolean;
                    subscriptionId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface SaveDeviceResponse {
                    device: MyBuddyGard.Domain.Models.Devices.Device;
                    setupSosResponse: MyBuddyGard.Domain.Models.DeviceCommandResponse;
                    updateContactsResponse: MyBuddyGard.Domain.Models.DeviceCommandResponse;
                }

                export interface SaveDeviceCommandOption {
                    deviceCommandId: string;
                    data: boolean;
                    sms: boolean;
                    queue: boolean;
                }

                export namespace Pairing {

                    export interface PlatformDevicePairingInfo {
                        deviceId: string;
                        deviceName: string;
                        accountSlug: string;
                        accountName: string;
                        accessToken: string;
                    }

                    export interface DevicePairingInfo {
                        deviceName: string;
                        platform: string;
                    }

                    export interface DeviceAuthorizationCode {
                        deviceCode: string;
                        userCode: string;
                        validity: number;
                        interval: number;
                        authorizationUrl: string;
                        authorizationUrlFlow: string;
                        qrCode: string;
                    }

                    export interface DeviceAuthorizationResponse {
                        success: boolean;
                        message: string;
                        accountName: string;
                    }

                    export interface DeviceAuthorizationRequest {
                        accountSlug: string;
                        userCode: string;
                        token: string;
                    }
                }
            }

            export namespace DeviceSettings {

                export interface DeviceSetting {
                    deviceType: string;
                    discriminator: string;
                    updateRate: number;
                    minUpdateRate: number;
                    maxUpdateRate: number;
                    offlineTimeout: number | null;
                    features: {[key: string] : (MyBuddyGard.Domain.Models.FeatureCapabilities)};
                    id: string;
                    deleted: boolean;
                    version: number;
                }
            }

            export namespace Geofences {

                export interface Geofence {
                    name: string;
                    geofenceShapes: (MyBuddyGard.Domain.Models.Geofences.GeofenceShape)[];
                    accountId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface GeofenceShape {
                    discriminator: string;
                    shape: string;
                    geofenceId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface GeofenceCircle {
                    center: MyBuddyGard.Domain.Models.Coordinates;
                    population: string;
                    discriminator: string;
                    shape: string;
                    geofenceId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface GeofencePolygon {
                    polygonCoordinates: (MyBuddyGard.Domain.Models.Geofences.PolygonCoordinates)[];
                    discriminator: string;
                    shape: string;
                    geofenceId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface GeofenceRectangle {
                    south: number;
                    west: number;
                    north: number;
                    east: number;
                    discriminator: string;
                    shape: string;
                    geofenceId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface PolygonCoordinates {
                    coordinates: MyBuddyGard.Domain.Models.Coordinates;
                    listOrder: number;
                    geofencePolygonId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }
            }

            export namespace Rules {

                export interface Rule {
                    name: string;
                    allDevices: boolean;
                    scheduleFrom: Date | null;
                    scheduleTo: Date | null;
                    allDay: boolean;
                    monday: boolean;
                    tuesday: boolean;
                    wednesday: boolean;
                    thursday: boolean;
                    friday: boolean;
                    saturday: boolean;
                    sunday: boolean;
                    trigger: MyBuddyGard.Domain.Models.Rules.Triggers.Trigger;
                    actions: (MyBuddyGard.Domain.Models.Rules.Actions.Action)[];
                    deviceIds: (string)[];
                    accountId: string;
                    pauseHours: number | null;
                    pauseHoursExpiryDate: Date | null;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface ActionTimerResult {
                    serverTime: Date;
                    actionTimers: (MyBuddyGard.Domain.Models.Rules.ActionTimerViewModel)[];
                }

                export interface ActionTimerViewModel {
                    id: string;
                    rule: MyBuddyGard.Domain.Models.Rules.Rule;
                    action: MyBuddyGard.Domain.Models.Rules.Actions.Action;
                    location: MyBuddyGard.Domain.Models.Locations.Location;
                    firingTime: Date;
                }

                export namespace Actions {

                    export interface Action {
                        discriminator: string;
                        name: string;
                        actionType: string;
                        iconHint: string;
                        delayMs: number | null;
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface EmailAction {
                        sendTo: string;
                        message: string;
                        discriminator: string;
                        name: string;
                        actionType: string;
                        iconHint: string;
                        delayMs: number | null;
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface ImmobiliseAction {
                        onOff: boolean;
                        discriminator: string;
                        name: string;
                        actionType: string;
                        iconHint: string;
                        delayMs: number | null;
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface LocateAction {
                        discriminator: string;
                        name: string;
                        actionType: string;
                        iconHint: string;
                        delayMs: number | null;
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface NotificationAction {
                        message: string;
                        discriminator: string;
                        name: string;
                        actionType: string;
                        iconHint: string;
                        delayMs: number | null;
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface SmsAction {
                        sendTo: string;
                        message: string;
                        discriminator: string;
                        name: string;
                        actionType: string;
                        iconHint: string;
                        delayMs: number | null;
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }
                }

                export namespace Triggers {

                    export interface Trigger {
                        discriminator: string;
                        name: string;
                        triggerType: string;
                        iconHint: string;
                        defaultMessage: string;
                        mergeFields: {[key: string] : (string)};
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface BatteryLevelTrigger {
                        isAbove: boolean;
                        level: number;
                        discriminator: string;
                        name: string;
                        triggerType: string;
                        iconHint: string;
                        defaultMessage: string;
                        mergeFields: {[key: string] : (string)};
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface GeofenceTrigger {
                        actionType: MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType;
                        dwellCondition: MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition;
                        dwellTime: number;
                        geofenceId: string | null;
                        dwellTimeUnit: MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit;
                        discriminator: string;
                        name: string;
                        triggerType: string;
                        iconHint: string;
                        defaultMessage: string;
                        mergeFields: {[key: string] : (string)};
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface PowerTrigger {
                        powerOn: boolean;
                        powerStatusType: MyBuddyGard.Domain.Enumerations.Trigger.PowerStatusType;
                        discriminator: string;
                        name: string;
                        triggerType: string;
                        iconHint: string;
                        defaultMessage: string;
                        mergeFields: {[key: string] : (string)};
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface SosTrigger {
                        discriminator: string;
                        name: string;
                        triggerType: string;
                        iconHint: string;
                        defaultMessage: string;
                        mergeFields: {[key: string] : (string)};
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface SpeedTrigger {
                        isAbove: boolean;
                        level: number;
                        discriminator: string;
                        name: string;
                        triggerType: string;
                        iconHint: string;
                        defaultMessage: string;
                        mergeFields: {[key: string] : (string)};
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }

                    export interface WifiTrigger {
                        wifiId: string | null;
                        dwellCondition: MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition;
                        dwellTime: number;
                        dwellTimeUnit: MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit;
                        actionType: MyBuddyGard.Domain.Enumerations.Trigger.WifiActionType;
                        discriminator: string;
                        name: string;
                        triggerType: string;
                        iconHint: string;
                        defaultMessage: string;
                        mergeFields: {[key: string] : (string)};
                        requiredFeatures: (MyBuddyGard.Domain.Enumerations.DeviceFeature)[];
                        id: string;
                        deleted: boolean;
                        version: number;
                    }
                }
            }

            export namespace Locations {

                export interface Location {
                    deviceId: string;
                    coordinates: MyBuddyGard.Domain.Models.Coordinates;
                    batteryStatus: number | null;
                    locationUpdateDate: Date;
                    powerStatus: boolean;
                    sosPressed: boolean;
                    speed: number | null;
                    direction: number | null;
                    locationWifis: (MyBuddyGard.Domain.Models.Locations.LocationWifi)[];
                    apiVersion: string;
                    id: number;
                    deleted: boolean;
                    version: number;
                }

                export interface LocationWifi {
                    locationId: number;
                    wifi: MyBuddyGard.Domain.Models.Wifi;
                    signalStrength: number;
                    id: number;
                    deleted: boolean;
                    version: number;
                }
            }

            export namespace Plans {

                export interface Plan {
                    slug: string;
                    name: string;
                    description: string;
                    periodLength: number;
                    periodType: string;
                    defaultDeviceSetting: (MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting)[];
                    planRenewals: (MyBuddyGard.Domain.Models.Plans.PlanRenewal)[];
                    defaultPlanRenewal: string | null;
                    deviceTypes: (MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction)[];
                    planRestriction: MyBuddyGard.Domain.Models.Plans.PlanRestriction;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface PlanSearch {
                    q: string;
                    page: number;
                    pageSize: number;
                    sortBy: string;
                    sortDescending: boolean;
                }

                export interface PlanExtract {
                    slug: string;
                    name: string;
                    description: string;
                    periodLength: number;
                    periodType: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface PlanDeviceDefaultSetting {
                    planId: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface PlanRestriction {
                    ruleLimit: number;
                    geofenceLimit: number;
                    actionsPerRuleLimit: number;
                    smsLimit: number;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface PlanRenewal {
                    name: string;
                    amount: number;
                    periodLength: number;
                    periodType: MyBuddyGard.Domain.Enumerations.PeriodType;
                    adminOnly: boolean;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface DeviceSettingsRestriction {
                    discriminator: string;
                    count: number;
                }
            }

            export namespace Subscriptions {

                export interface Subscription {
                    accountId: string;
                    plan: MyBuddyGard.Domain.Models.Plans.Plan;
                    devices: (MyBuddyGard.Domain.Models.Devices.DeviceInfo)[];
                    defaultPlanRenewalOptionId: string | null;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface SubscriptionWithCoverage {
                    subscription: MyBuddyGard.Domain.Models.Subscriptions.Subscription;
                    coverageToDate: Date | null;
                }
            }

            export namespace Trackers {

                export interface Wifi {
                    id: string;
                    name: string;
                    macAddress: string;
                    signalStrength: number;
                }
            }

            export namespace Account {

                export interface Account {
                    ownerId: string;
                    subscriptions: (MyBuddyGard.Domain.Models.Subscriptions.Subscription)[];
                    devices: (MyBuddyGard.Domain.Models.Devices.Device)[];
                    geofences: (MyBuddyGard.Domain.Models.Geofences.Geofence)[];
                    rules: (MyBuddyGard.Domain.Models.Rules.Rule)[];
                    slug: string;
                    accountName: string;
                    accountStatus: MyBuddyGard.Domain.Enumerations.AccountStatus;
                    userAccounts: (MyBuddyGard.Domain.Models.UserAccount)[];
                    reseller: boolean;
                    smsCredits: number;
                    timeZone: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface AccountInfo {
                    ownerId: string;
                    subscriptions: (MyBuddyGard.Domain.Models.Subscriptions.Subscription)[];
                    slug: string;
                    accountName: string;
                    accountStatus: MyBuddyGard.Domain.Enumerations.AccountStatus;
                    reseller: boolean;
                    timeZone: string;
                    stripeCustomerId: string;
                    userAccounts: (MyBuddyGard.Domain.Models.UserAccount)[];
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface AccountWithUsers {
                    account: MyBuddyGard.Domain.Models.Account.AccountInfo;
                    users: (MyBuddyGard.Domain.Models.Users.User)[];
                }

                export interface AccountExtract {
                    ownerId: string;
                    slug: string;
                    accountName: string;
                    accountStatus: MyBuddyGard.Domain.Enumerations.AccountStatus;
                    reseller: boolean;
                    timeZone: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface AccountSearch {
                    q: string;
                    page: number;
                    pageSize: number;
                    sortBy: string;
                    sortDescending: boolean;
                }

                export interface AccountInfoWithOwner {
                    account: MyBuddyGard.Domain.Models.Account.AccountInfo;
                    owner: MyBuddyGard.Domain.Models.Users.User;
                }
            }

            export namespace Charges {

                export interface Charge {
                    discriminator: string;
                    chargeInclusions: (MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion)[];
                    accountId: string;
                    amount: number;
                    number: number | null;
                    description: string;
                    chargeTime: Date;
                    paymentReference: string;
                    id: number;
                    deleted: boolean;
                    version: number;
                }

                export interface StripeCharge {
                    lastFourDigitsOfCard: string;
                    discriminator: string;
                    chargeInclusions: (MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion)[];
                    accountId: string;
                    amount: number;
                    number: number | null;
                    description: string;
                    chargeTime: Date;
                    paymentReference: string;
                    id: number;
                    deleted: boolean;
                    version: number;
                }

                export interface InvoiceCharge {
                    discriminator: string;
                    chargeInclusions: (MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion)[];
                    accountId: string;
                    amount: number;
                    number: number | null;
                    description: string;
                    chargeTime: Date;
                    paymentReference: string;
                    id: number;
                    deleted: boolean;
                    version: number;
                }

                export interface ChargeExtract {
                    discriminator: string;
                    accountId: string;
                    amount: number;
                    number: number;
                    description: string;
                    chargeTime: Date;
                    paymentReference: string;
                    id: number;
                    deleted: boolean;
                    version: number;
                }

                export interface ChargeSearch {
                    q: string;
                    accountSlug: string;
                    page: number;
                    pageSize: number;
                    sortBy: string;
                    sortDescending: boolean;
                }

                export interface ChargeExtended {
                    discriminator: string;
                    chargeInclusions: (MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion)[];
                    account: MyBuddyGard.Domain.Models.Account.AccountInfoWithOwner;
                    amount: number;
                    number: number | null;
                    description: string;
                    chargeTime: Date;
                    paymentReference: string;
                    id: number;
                    deleted: boolean;
                    version: number;
                }
            }

            export namespace ChargeInclusions {

                export interface ChargeInclusion {
                    discriminator: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface SubscriptionChargeInclusion {
                    subscription: MyBuddyGard.Domain.Models.Subscriptions.Subscription;
                    coverageFromDate: Date;
                    coverageToDate: Date;
                    planRenewalId: string;
                    discriminator: string;
                    id: string;
                    deleted: boolean;
                    version: number;
                }
            }

            export namespace Payments {

                export interface PaymentMethod {
                    discriminator: string;
                    id: string;
                    name: string;
                    isDefault: boolean;
                }

                export interface CardPaymentMethod {
                    lastFourDigits: string;
                    brand: string;
                    expiryMonth: string;
                    expiryYear: string;
                    nameOnCard: string;
                    discriminator: string;
                    id: string;
                    name: string;
                    isDefault: boolean;
                }

                export interface SetupResult {
                    id: string;
                    clientSecret: string;
                }
            }

            export namespace SignalR {

                export interface Notification {
                    identifier: string;
                    message: string;
                }
            }

            export namespace Notifications {

                export interface PushNotification {
                    title: string;
                    message: string;
                    data: {[key: string] : (string)};
                    dateDelivered: Date;
                }

                export interface INotification {
                    title: string;
                    message: string;
                    data: {[key: string] : (string)};
                    dateDelivered: Date;
                }

                export namespace NotificationGroup {

                    export const Account : string = 'account';

                    export const User : string = 'user';
                }
            }

            export namespace Users {

                export interface User {
                    userName: string;
                    firstName: string;
                    lastName: string;
                    phoneNumber: string;
                    emailAddress: string;
                    picture: string;
                    administrator: boolean;
                    external: boolean;
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface TokenedUserExtractCollection {
                    items: (MyBuddyGard.Domain.Models.Users.User)[];
                    previousPaginationToken: string;
                    currentPaginationToken: string;
                    nextPaginationToken: string;
                }

                export interface UserSearch {
                    q: string;
                    searchProperty: Intellis.Aws.Core.Cognito.Users.UserSearchProperty;
                    paginationToken: string;
                    currentPaginationToken: string;
                    nextPaginationToken: string;
                }

                export interface UserForm {
                    temporaryPassword: string;
                    userName: string;
                    firstName: string;
                    lastName: string;
                    phoneNumber: string;
                    emailAddress: string;
                    picture: string;
                    administrator: boolean;
                    external: boolean;
                    id: string;
                    deleted: boolean;
                    version: number;
                }
            }
        }

        export namespace Entities {

            export namespace DeviceSettings {

                export interface IContactsEnabled {
                    contacts: (MyBuddyGard.Domain.Models.Contact)[];
                    contactsSynced: boolean;
                    maximumContacts: number;
                }
            }
        }

        export namespace Enumerations {

            export enum DeviceFeature {
                Immobilise = 0,
                Find = 1,
                Reset = 2,
                Locate = 3,
                SetupSos = 4,
                Wifi = 5,
                Sos = 6,
                Contacts = 7,
                UpdateRate = 8,
                ForceWakeup = 9,
                Sleeptime = 10,
                Monitor = 11,
                HostPower = 12,
            }

            export enum RadioTypes {
                Gsm = 0,
                Lte = 1,
                Cdma = 2,
                Wcdma = 3,
            }

            export enum PaymentStatus {
                Unknown = 0,
                Success = 1,
                NoDefaultPaymentMethod = 2,
                CardDeclined = 3,
                CardExpired = 4,
                InvalidPaymentType = 5,
                InternalException = 6,
            }

            export enum PeriodType {
                Day = 0,
                Week = 1,
                Month = 2,
                Year = 3,
            }

            export enum AccountStatus {
                Disabled = 0,
                Activated = 1,
            }

            export enum DeviceCommandResponseResult {
                Success = 0,
                Queued = 1,
                NotConnected = 2,
                NoResponse = 3,
                FailedOnDevice = 4,
                ServiceUnavailable = 5,
                DeviceNotSupported = 6,
                CommandNotSupported = 7,
                InvalidPostRequest = 8,
                InvalidRequestType = 9,
                DeviceNotFound = 10,
                FeatureNotSupported = 11,
                UnknownError = 12,
                Unauthorized = 13,
                RestrictionExceeded = 14,
            }

            export namespace Trigger {

                export enum GeofenceActionType {
                    Enter = 0,
                    Exit = 1,
                    Dwell = 2,
                }

                export enum DwellCondition {
                    Inside = 0,
                    Outside = 1,
                }

                export enum DwellTimeUnit {
                    Seconds = 0,
                    Minutes = 1,
                }

                export enum PowerStatusType {
                    Device = 0,
                    Host = 1,
                }

                export enum WifiActionType {
                    Enter = 0,
                    Exit = 1,
                    Dwell = 2,
                }
            }
        }

        export namespace Constants {

            export namespace HubEvents {

                export const ReceiveDeviceLocation : string = 'ReceiveDeviceLocation';
            }

            export namespace CustomHttpHeaders {

                export const BrowserTimezone : string = 'x-mbg-browser-timezone';
            }

            export namespace Formats {

                export const Slug : string = '^[a-z0-9-]+$';

                export const CognitoPhoneNumberEscaped : string = '\\+[0-9]+';

                export const CognitoPasswordEscaped : string = '(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\\^$*.[\\]{}()?\"!@#%&/\\\\,><\':;|_~`=+\\- ])[A-Za-z0-9^$*.[\\]{}()?\"!@#%&/\\\\,><\':;|_~`=+\\- ]{6,99}';
            }

            export namespace DeviceCommandIds {

                export const Immobilise : string = 'immobilise';

                export const Reset : string = 'reset';

                export const Find : string = 'find';

                export const Activate : string = 'activate';

                export const Locate : string = 'locate';

                export const SetupSos : string = 'setupSos';

                export const Contacts : string = 'contacts';

                export const UpdateRate : string = 'updateRate';

                export const SleepTime : string = 'sleeptime';

                export const Monitor : string = 'monitor';
            }
        }
    }

    export namespace Devices {

        export namespace Shenzen {

            export namespace Models {

                export interface Pendant {
                    contacts: (MyBuddyGard.Domain.Models.Contact)[];
                    phoneNumber: string;
                    trackerId: string;
                    contactsSynced: boolean;
                    maximumContacts: number;
                    imei: string;
                    rolloverStartPeriod: string;
                    rolloverEndPeriod: string;
                    keepAlive: boolean;
                    monitorPhoneNumber: string;
                    deviceType: string;
                    discriminator: string;
                    updateRate: number;
                    minUpdateRate: number;
                    maxUpdateRate: number;
                    offlineTimeout: number | null;
                    features: {[key: string] : (MyBuddyGard.Domain.Models.FeatureCapabilities)};
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface ShenzenWatch {
                    contacts: (MyBuddyGard.Domain.Models.Contact)[];
                    phoneNumber: string;
                    trackerId: string;
                    contactsSynced: boolean;
                    maximumContacts: number;
                    imei: string;
                    rolloverStartPeriod: string;
                    rolloverEndPeriod: string;
                    keepAlive: boolean;
                    monitorPhoneNumber: string;
                    deviceType: string;
                    discriminator: string;
                    updateRate: number;
                    minUpdateRate: number;
                    maxUpdateRate: number;
                    offlineTimeout: number | null;
                    features: {[key: string] : (MyBuddyGard.Domain.Models.FeatureCapabilities)};
                    id: string;
                    deleted: boolean;
                    version: number;
                }
            }
        }

        export namespace Resiont {

            export namespace Models {

                export interface Resiont {
                    trackerId: string;
                    phoneNumber: string;
                    imei: string;
                    deviceType: string;
                    discriminator: string;
                    updateRate: number;
                    minUpdateRate: number;
                    maxUpdateRate: number;
                    offlineTimeout: number | null;
                    features: {[key: string] : (MyBuddyGard.Domain.Models.FeatureCapabilities)};
                    id: string;
                    deleted: boolean;
                    version: number;
                }
            }
        }

        export namespace Mobile {

            export namespace Models {

                export interface AndroidMobile {
                    deviceType: string;
                    discriminator: string;
                    updateRate: number;
                    minUpdateRate: number;
                    maxUpdateRate: number;
                    offlineTimeout: number | null;
                    features: {[key: string] : (MyBuddyGard.Domain.Models.FeatureCapabilities)};
                    id: string;
                    deleted: boolean;
                    version: number;
                }

                export interface IosApp {
                    deviceType: string;
                    discriminator: string;
                    updateRate: number;
                    minUpdateRate: number;
                    maxUpdateRate: number;
                    offlineTimeout: number | null;
                    features: {[key: string] : (MyBuddyGard.Domain.Models.FeatureCapabilities)};
                    id: string;
                    deleted: boolean;
                    version: number;
                }
            }
        }
    }

    export namespace Api {

        export namespace Config {

            export namespace Trackmap {

                export interface ITrackmapConfig {
                    stripe: MyBuddyGard.Api.Config.Trackmap.TrackmapStripeConfig;
                }

                export interface TrackmapStripeConfig {
                    apiKey: string;
                }
            }
        }

        export namespace Models {

            export interface CancelAccountPaymentsSetupRequest {
                id: string;
                reason: string;
            }

            export interface SendCommand {
                command: MyBuddyGard.Domain.Models.DeviceCommand;
                sms: boolean;
                queue: boolean;
            }

            export interface DeleteCommandRequest {
                deviceType: string;
                trackerId: string;
                identifiers: (string)[];
            }

            export interface DeviceLocationAndToken {
                deviceLocation: MyBuddyGard.Domain.Models.DeviceLocation;
                token: string;
            }

            export interface SavePlan {
                planRenewals: (MyBuddyGard.Domain.Models.Plans.PlanRenewal)[];
                slug: string;
                name: string;
                description: string;
                periodLength: number;
                periodType: string;
                defaultDeviceSetting: (MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting)[];
                defaultPlanRenewal: string | null;
                deviceTypes: (MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction)[];
                planRestriction: MyBuddyGard.Domain.Models.Plans.PlanRestriction;
                id: string;
                deleted: boolean;
                version: number;
            }

            export interface SavePlanRenewal {
                isDefault: boolean;
                name: string;
                amount: number;
                periodLength: number;
                periodType: MyBuddyGard.Domain.Enumerations.PeriodType;
                adminOnly: boolean;
                id: string;
                deleted: boolean;
                version: number;
            }

            export namespace Notification {

                export interface ConnectionRequest {
                    token: string;
                    groups: (MyBuddyGard.Api.Models.Notification.Groups.Group)[];
                }

                export namespace Groups {

                    export interface Account {
                        slug: string;
                        discriminator: string;
                    }

                    export interface User {
                        discriminator: string;
                    }

                    export interface Group {
                        discriminator: string;
                    }
                }
            }

            export namespace Pairing {

                export interface DeviceTokenValidationResponse {
                    result: (MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResult)[];
                }

                export interface DeviceTokenValidationResult {
                    token: string;
                    valid: boolean;
                }
            }

            export namespace ResetPassword {

                export interface ResetPasswordResponse {
                    request: MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest;
                    allowed: boolean;
                    message: string;
                }

                export interface ResetPasswordRequest {
                    email: string;
                }
            }
        }

        export namespace Constants {

            export namespace AuthPolicies {

                export const AllowCognitoIdToken : string = 'AllowCognitoIdToken';
            }
        }
    }
}
export namespace System {

    export namespace Net {

        export enum HttpStatusCode {
            Continue = 100,
            SwitchingProtocols = 101,
            Processing = 102,
            EarlyHints = 103,
            OK = 200,
            Created = 201,
            Accepted = 202,
            NonAuthoritativeInformation = 203,
            NoContent = 204,
            ResetContent = 205,
            PartialContent = 206,
            MultiStatus = 207,
            AlreadyReported = 208,
            IMUsed = 226,
            Ambiguous = 300,
            Moved = 301,
            Redirect = 302,
            RedirectMethod = 303,
            NotModified = 304,
            UseProxy = 305,
            Unused = 306,
            TemporaryRedirect = 307,
            PermanentRedirect = 308,
            BadRequest = 400,
            Unauthorized = 401,
            PaymentRequired = 402,
            Forbidden = 403,
            NotFound = 404,
            MethodNotAllowed = 405,
            NotAcceptable = 406,
            ProxyAuthenticationRequired = 407,
            RequestTimeout = 408,
            Conflict = 409,
            Gone = 410,
            LengthRequired = 411,
            PreconditionFailed = 412,
            RequestEntityTooLarge = 413,
            RequestUriTooLong = 414,
            UnsupportedMediaType = 415,
            RequestedRangeNotSatisfiable = 416,
            ExpectationFailed = 417,
            MisdirectedRequest = 421,
            UnprocessableEntity = 422,
            Locked = 423,
            FailedDependency = 424,
            UpgradeRequired = 426,
            PreconditionRequired = 428,
            TooManyRequests = 429,
            RequestHeaderFieldsTooLarge = 431,
            UnavailableForLegalReasons = 451,
            InternalServerError = 500,
            NotImplemented = 501,
            BadGateway = 502,
            ServiceUnavailable = 503,
            GatewayTimeout = 504,
            HttpVersionNotSupported = 505,
            VariantAlsoNegotiates = 506,
            InsufficientStorage = 507,
            LoopDetected = 508,
            NotExtended = 510,
            NetworkAuthenticationRequired = 511,
        }
    }
}
export namespace Microsoft {

    export namespace AspNetCore {

        export namespace Http {

            export namespace StatusCodes {

                export const Status100Continue : number = 100;

                export const Status101SwitchingProtocols : number = 101;

                export const Status102Processing : number = 102;

                export const Status200OK : number = 200;

                export const Status201Created : number = 201;

                export const Status202Accepted : number = 202;

                export const Status203NonAuthoritative : number = 203;

                export const Status204NoContent : number = 204;

                export const Status205ResetContent : number = 205;

                export const Status206PartialContent : number = 206;

                export const Status207MultiStatus : number = 207;

                export const Status208AlreadyReported : number = 208;

                export const Status226IMUsed : number = 226;

                export const Status300MultipleChoices : number = 300;

                export const Status301MovedPermanently : number = 301;

                export const Status302Found : number = 302;

                export const Status303SeeOther : number = 303;

                export const Status304NotModified : number = 304;

                export const Status305UseProxy : number = 305;

                export const Status306SwitchProxy : number = 306;

                export const Status307TemporaryRedirect : number = 307;

                export const Status308PermanentRedirect : number = 308;

                export const Status400BadRequest : number = 400;

                export const Status401Unauthorized : number = 401;

                export const Status402PaymentRequired : number = 402;

                export const Status403Forbidden : number = 403;

                export const Status404NotFound : number = 404;

                export const Status405MethodNotAllowed : number = 405;

                export const Status406NotAcceptable : number = 406;

                export const Status407ProxyAuthenticationRequired : number = 407;

                export const Status408RequestTimeout : number = 408;

                export const Status409Conflict : number = 409;

                export const Status410Gone : number = 410;

                export const Status411LengthRequired : number = 411;

                export const Status412PreconditionFailed : number = 412;

                export const Status413RequestEntityTooLarge : number = 413;

                export const Status413PayloadTooLarge : number = 413;

                export const Status414RequestUriTooLong : number = 414;

                export const Status414UriTooLong : number = 414;

                export const Status415UnsupportedMediaType : number = 415;

                export const Status416RequestedRangeNotSatisfiable : number = 416;

                export const Status416RangeNotSatisfiable : number = 416;

                export const Status417ExpectationFailed : number = 417;

                export const Status418ImATeapot : number = 418;

                export const Status419AuthenticationTimeout : number = 419;

                export const Status421MisdirectedRequest : number = 421;

                export const Status422UnprocessableEntity : number = 422;

                export const Status423Locked : number = 423;

                export const Status424FailedDependency : number = 424;

                export const Status426UpgradeRequired : number = 426;

                export const Status428PreconditionRequired : number = 428;

                export const Status429TooManyRequests : number = 429;

                export const Status431RequestHeaderFieldsTooLarge : number = 431;

                export const Status451UnavailableForLegalReasons : number = 451;

                export const Status500InternalServerError : number = 500;

                export const Status501NotImplemented : number = 501;

                export const Status502BadGateway : number = 502;

                export const Status503ServiceUnavailable : number = 503;

                export const Status504GatewayTimeout : number = 504;

                export const Status505HttpVersionNotsupported : number = 505;

                export const Status506VariantAlsoNegotiates : number = 506;

                export const Status507InsufficientStorage : number = 507;

                export const Status508LoopDetected : number = 508;

                export const Status510NotExtended : number = 510;

                export const Status511NetworkAuthenticationRequired : number = 511;
            }
        }
    }
}
