import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {FileModel} from '../models/file.model';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FileRepository {

    constructor(protected http: HttpClient) { }

    get(url: string, params: HttpParams, headers: HttpHeaders = null, contentType: string = 'application/pdf'): Observable<FileModel> {

        headers = (headers ?? new HttpHeaders()).set('Accept', contentType);

        return this.http.get(url, {
            responseType: 'blob',
            headers: headers,
            params: params,
            observe: 'response'
        }).pipe(
            map(r => {
                const contentDisposition = r.headers.get('content-disposition');
                const fileName = contentDisposition.split(';')[1].trim().split('=')[1].replace(/"/g, '');

                return {fileName: fileName, data: r.body};
            })
        );
    }
}
