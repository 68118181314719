import {Injectable} from "@angular/core";
import {BaseSchemaConfig} from "@intellis/schema";
import {Schema} from "../domain/schema.json";

@Injectable({
  providedIn: 'root',
})
export class SchemaConfig extends BaseSchemaConfig {
  constructor() {
    super();
  }
  schema = Schema;
}
