import { Injectable } from '@angular/core';
import * as tzLib from "countries-and-timezones";
import {Timezone} from "../models/timezone";
import {Country} from "../models/country";

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  constructor() { }

  getTimeZones(): Timezone[] {
    return Object.entries(tzLib.getAllTimezones())
      .map(([k,v ]) => {
        // Consider creating a timezone factory
        let tz = new Timezone();
        tz.iana = v.name;
        tz.name = v.name.replace("_", " ");
        tz.countries = v.countries.map(c => <Country> { code: c, name: tzLib.getCountry(c).name });
        return tz;
      });
  }

  getBrowserTimezone(): Timezone {
    // Intl.DateTimeFormat().resolvedOptions().timeZone returns the browser's IANA timezone
    let browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let tz = new Timezone();

    tz.iana = browserTz;
    tz.name = browserTz.replace("_", " ");

    return tz;
  }

  /**
   *
   * @param name IANA
   */
  getCountries(name: string): Country[] {
    let tz = tzLib.getTimezone(name);
    return tz.countries.map(c => <Country> { code: c, name: tzLib.getCountry(c).name });
  }
}
