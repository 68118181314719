<section [formGroup]="modelFormGroup">
  <div class="container-row rules-section">
    <h4>Send to</h4>
    <mat-form-field appearance="fill">
      <mat-label>Email address</mat-label>
      <input matInput type="email" formControlName="sendTo">
      <mat-error *ngIf="modelFormGroup.controls.sendTo.invalid">Email address required</mat-error>
    </mat-form-field>
  </div>
  <div class="container-row rules-section">
    <mat-form-field appearance="fill">
      <mat-label>Merge fields</mat-label>
      <mat-select [formControl]="mergeFieldSelector">
        <mat-option *ngFor="let field of data.mergeFields | keyvalue" [value]="field.key">{{field.value}}</mat-option>
      </mat-select>
      <mat-hint>Select items to automatically add them to the text field</mat-hint>
    </mat-form-field>
  </div>
  <div class="container-row rules-section">
    <h4>Message to send</h4>
    <mat-form-field appearance="fill">
      <textarea matInput formControlName="message" [placeholder]="data.defaultMessage" rows="10"></textarea>
    </mat-form-field>
  </div>
</section>