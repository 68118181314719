export const environment = {
  production: false,

  apiBaseUrl: "https://trackmap.intellis.dev/api",

  cookieDomain: 'staging.mybuddygard.com.au',

  firebase: {
    config: {
      apiKey: "AIzaSyAn1NT0qo1-yNx2TwUYt9wvMZ6137_uz-E",
      authDomain: "mybuddygard-3c3ed.firebaseapp.com",
      projectId: "mybuddygard-3c3ed",
      storageBucket: "mybuddygard-3c3ed.appspot.com",
      messagingSenderId: "387670797779",
      appId: "1:387670797779:web:2623149c1d7e75bb931326",
    }
  },

  cognito: {
    federatedSignIn: {
      google: '311064744961-24l2alhe2dvpv9tfscd20me1eic5r2ul.apps.googleusercontent.com',
      facebook: '818306128519083'
    }
  }
};
