import { RepositoryConfig } from '@intellis/entityrepository';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import {Capacitor} from "@capacitor/core";
import {environment} from "../../environments/environment";

@Injectable()
export class MbgApiRepositoryConfig extends RepositoryConfig {
  constructor(protected location: Location) {
    super();
  if (Capacitor.isNativePlatform()) {
      this.apiBase = environment.apiBaseUrl.endsWith("/")
        ? environment.apiBaseUrl
        : environment.apiBaseUrl + "/";
    }
    // If running as a web app (non-native) and not in local dev
    else {
      this.apiBase = location.prepareExternalUrl('/api') + '/';
    }
  }
}
