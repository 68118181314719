import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {ActivatedRoute, NavigationEnd, ParamMap, PRIMARY_OUTLET, Router, RouterEvent} from "@angular/router";
import {filter, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ParamMapService {

  paramMap: Observable<ParamMap>;

  constructor(private router: Router,
              private route: ActivatedRoute) {
    this.paramMap = this.getParamMapObservable();
  }

  private getParamMap(route: ActivatedRoute): ParamMap {
    const map: Map<string, string | string[]> = new Map();

    while (route) {
      route.snapshot.paramMap.keys.forEach((key) => {
        map.set(key, this.getParamMapValue(route.snapshot.paramMap, key));
      });
      route = route.firstChild;
    }

    return <ParamMap>{
      keys: this.getParamMapKeys(map),
      has: this.getParamMapMethodHas(map),
      get: this.getParamMapMethodGet(map),
      getAll: this.getParamMapMethodGetAll(map)
    };
  }

  private getParamMapMethodGet(map: Map<string, string | string[]>): (name: string) => string | null {
    return (name: string): string | null => {
      const value = map.get(name);
      if (typeof value === 'string') {
        return value;
      }
      if (Array.isArray(value) && value.length) {
        return value[0];
      }
      return null;
    };
  }

  private getParamMapMethodGetAll(map: Map<string, string | string[]>): (name: string) => string[] {
    return (name: string): string[] => {
      const value = map.get(name);
      if (typeof value === 'string') {
        return [value];
      }
      if (Array.isArray(value)) {
        return value;
      }
      return [];
    };
  }

  private getParamMapMethodHas(map: Map<string, string | string[]>): (name: string) => boolean {
    return (name: string): boolean => map.has(name);
  }

  private getParamMapKeys(map: Map<string, string | string[]>): string[] {
    return Array.from(map.keys());
  }

  private getParamMapObservable(): Observable<ParamMap> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.route),
      filter((route: ActivatedRoute) => route.outlet === PRIMARY_OUTLET),
      map((route: ActivatedRoute) => this.getParamMap(route))
    )

  }

  private getParamMapValue(paramMap: ParamMap, key: string): string | string[] {
    return (paramMap.getAll(key).length > 1 ? paramMap.getAll(key) : paramMap.get(key));
  }
}
