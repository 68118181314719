import {environment} from '../environments/environment';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { UserService } from './service/user.service';
import { SplashScreenService } from './service/splash-screen.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MyBuddyGard } from './domain/models';
import User = MyBuddyGard.Domain.Models.Users.User;
import {NotificationService} from "./service/notification.service";
import {fromEvent, Subject} from "rxjs";
import {filter, mergeMap, takeUntil} from "rxjs/operators";
import {Amplify} from "aws-amplify";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
import {DeviceTrackingService} from "./service/device-tracking.service";

Amplify.configure({
  Auth: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_DOX2j5P2I',
    userPoolWebClientId: '4km461mcnvg3l3m7lleufqurvm',
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    mandatorySignIn: true,
    cookieStorage: {
      domain: environment.cookieDomain,
      path: '/',
      expires: 365,
      sameSite: 'strict',
      secure: true,
    },
    oauth: {
      domain: 'auth.mybuddygard.com.au',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: 'code'
    }
  },
});

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  user: User | undefined;

  constructor(
    protected users: UserService,
    protected notifications: NotificationService,
    private _splashScreenService: SplashScreenService,
    protected matIconRegistry: MatIconRegistry,
    protected domSanitizer: DomSanitizer,
    protected trackingService: DeviceTrackingService
  ) {

    matIconRegistry.addSvgIcon('google', domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/google.svg'));
    matIconRegistry.addSvgIcon('facebook', domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/facebook.svg'));
    matIconRegistry.addSvgIcon('apple', domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/apple.svg'));
    matIconRegistry.addSvgIcon('mbg_pendant', domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/pendant.svg'));

    this.notifications.subscribeToFeed();

    // Connect to push notification HUB.
    // Needs to connect to hub in case device token changed without disconnecting (mostly applicable to web apps).
    // Hub ignores known tokens.
    // Fires on app start, app refresh (web), user login, user signup, user changed
    users.user.pipe(
      filter(u => u != null),
      // NOTE: Push notification disconnect happens in logout component
      mergeMap(() => this.notifications.connectUser())
    ).subscribe();
  }

  private destroy: Subject<void> = new Subject<void>();

  ngOnInit() {
    fromEvent(window, 'online').pipe(
      takeUntil(this.destroy)
    ).subscribe(s => this._splashScreenService.hide());

    fromEvent(window, 'offline').pipe(
      takeUntil(this.destroy)
    ).subscribe(s => this._splashScreenService.show());
  }

  ngOnDestroy() {
    this.destroy.next();
    this.destroy.complete();
  }
}
/** forcing a build with this comment **/
