<section [formGroup]="modelFormGroup" *ngIf="model != null">
  <div class="container-row rules-section">
    <div class="section-header">
      <h4>Trigger when power changes to</h4>
      <mat-button-toggle-group class="header-toggle-control" formControlName="powerOn">
        <mat-button-toggle [value]="true">On</mat-button-toggle>
        <mat-button-toggle [value]="false">Off</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="section-header" *ngIf="supportsHostPowerStatus">
      <h4>Power type</h4>
      <mat-button-toggle-group class="header-toggle-control" formControlName="powerStatusType">
        <mat-button-toggle [value]="0">Tracker</mat-button-toggle>
        <mat-button-toggle [value]="1">Accessories *</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <p>
      * Only available to vehicle trackers
    </p>

  </div>
</section>
