import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, from, Observable, of, ReplaySubject} from 'rxjs';
import { Hub } from 'aws-amplify';
import {AmplifyService} from 'aws-amplify-angular';
import {MyBuddyGard} from '../domain/models';
import {MeRepository} from '../domain/endpoints.repositories';
import User = MyBuddyGard.Domain.Models.Users.User;
import {TokenService} from "./token.service";
import {catchError, filter, map, mergeMap, take} from "rxjs/operators";
import {NotificationHub} from "../notification/notification-hub";

@Injectable({
  providedIn: 'root',
})
export class UserService {

  // NOTE: Original. Replace ReplaySubject if user object related issues arise
  // protected _userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  protected _userSubject: ReplaySubject<User> = new ReplaySubject<User>(1);
  get user(): Observable<User> {
    return this._userSubject;
  }

  protected _token: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  get token(): Observable<string> {
    // NOTE: Refresh token for new subscriptions (e.g. API) in case logged out in another tab
    return this._token.pipe(mergeMap(() => this.pushTokenAsObservable()));
  }

  private _user: User;

  constructor(
    protected amplify: AmplifyService,
    protected me: MeRepository,
    protected zone: NgZone,
    protected tokenService: TokenService
  ) {

    Hub.listen('auth', this.listener);
    this.pushToken();

    this.currentAuthenticatedUserSession().subscribe({
      next: u => this.zone.run(() => this.mapAndPushUser(u)),
      error: err => console.log(err)
    });

    // Subscribe to userSubject to keep track of current user
    this._userSubject.subscribe(u => this._user = u);
  }

  private mapUser(currentSessionResponse: any): User {
    let user: User = null;

    if (currentSessionResponse != null) {
      let groups: any[] = currentSessionResponse.getSignInUserSession()?.idToken?.payload['cognito:groups'] ?? [];

      if (currentSessionResponse.attributes.sub === this._user?.id) {
        user = this._user;
        user.emailAddress = currentSessionResponse.attributes.email;
        user.phoneNumber = currentSessionResponse.attributes.phone_number;
        user.firstName = currentSessionResponse.attributes.given_name;
        user.lastName = currentSessionResponse.attributes.family_name;
        user.id = currentSessionResponse.attributes.sub;
        user.picture = currentSessionResponse.attributes.picture;
        user.administrator = groups.includes('Administrators');
      } else {
        user = <User>{
          emailAddress: currentSessionResponse.attributes.email,
          phoneNumber: currentSessionResponse.attributes.phone_number,
          firstName: currentSessionResponse.attributes.given_name,
          lastName: currentSessionResponse.attributes.family_name,
          id: currentSessionResponse.attributes.sub,
          userName: currentSessionResponse.username, // ['cognito:preferred_username']  ,
          deleted: false,
          version: 0,
          picture: currentSessionResponse.attributes.picture,
          administrator: groups.includes('Administrators')
        };
      }
    }

    return user;
  }

  protected mapAndPushUser(u: any) {
    this._userSubject.next(this.mapUser(u));
  }

  protected getSessionToken(): Observable<string> {
    return this.currentSessionJwt().pipe(
      catchError(err_ => of(null))
    )
  }

  // Works the same way as the pushToken(): void but made as an observable
  private pushTokenAsObservable(): Observable<any> {
    return this.getSessionToken().pipe(
      map(newToken => {
        let token = this._token.value;
        if (newToken !== token) {
          token = newToken;
          this._token.next(token);
        }

        return token;
      }));
  }

  protected pushToken(): void {
    this.pushTokenAsObservable().subscribe();
  }

  logout(): void {
    this._token.next('');
    // TODO: Review. Capacitor might be using local storage for web
    localStorage.clear();
    this.amplify.auth().signOut();
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return from(this.amplify.auth().currentAuthenticatedUser()).pipe(
      filter(u => u != null),
      mergeMap(u => from(this.amplify.auth().changePassword(u, oldPassword, newPassword))),
      take(1)
    );
  }

  getLocalItem(key: string){
    return localStorage.getItem(key)
  }

  // TODO: Revisit
  // IMPORTANT:
  // Please use this sparingly or not at all.
  // Designed to be used by "subscribables" that must not be blocked (e.g. CanActivate from AdminGuard)
  // user(): Observable starts with null and cannot guarantee if there's an authenticated user depending on how early a subscriber is
  currentAuthenticatedUser(): Observable<User> {
    return this.currentAuthenticatedUserSession().pipe(
      map(res => this.mapUser(res))
    );
  }

  protected currentAuthenticatedUserSession(): Observable<any> {
    return from(this.amplify.auth().currentAuthenticatedUser({bypassCache: true}))
      .pipe(
        // currentAuthenticatedUser() throws an error if no user is authenticated
        catchError(() => of(null))
      );
  }

  protected currentSessionJwt(): Observable<string> {
    return from(this.amplify.auth().currentSession()).pipe(
      map((data: any) => data.getIdToken()?.getJwtToken())
    );
  }

  protected listener = (data: any) => {
    this.zone.run(() => {
      switch (data.payload.event) {
        case 'signIn':
          this.pushToken();
          this.mapAndPushUser(data.payload.data);
          break;
        case 'signUp':
          this.pushToken();
          this.mapAndPushUser(data.payload.data);
          break;
        case 'signOut':
          this.tokenService.clearTokens(this._user.id);
          this.pushToken();
          this.mapAndPushUser(null);
          break;
        case 'signIn_failure':
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          break;
        case 'configured':
          break;
        // TODO: Remove event catch if 'Invalid device key given.' on password reset issue is fixed or addressed properly.
        // WORKAROUND: This case is added to not break user experience.
        // See comment from github issue: https://github.com/aws-amplify/amplify-js/issues/8469#issuecomment-1135481591
        case 'completeNewPassword_failure':
          if (data.payload?.data?.message?.includes('Invalid device key given.')) {
            this.currentAuthenticatedUserSession().subscribe({
              next: u => {
                this.mapAndPushUser(u);
                this.pushToken();
              },
              error: err => console.log(err)
            });
          }

          break;
      }
    });
  };
}
