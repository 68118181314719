import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {Router, UrlTree} from "@angular/router";
import {finalize, map} from "rxjs/operators";
import {Capacitor} from "@capacitor/core";
import {DeviceTrackingService} from "../service/device-tracking.service";

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private tracking: DeviceTrackingService,
              private router: Router) { }

  private _canActivate: boolean = false;
  private _skipped: boolean = false;

  initialize(): void {
    // NOTE: angular router is not yet available on app initialization, where this method is invoked.

    if (Capacitor.isNativePlatform())
      this.tracking.start();
  }

  /**
   * Returns a URL tree if app needs to load a specific page during startup, otherwise `true`.
   * Will be used by StartupGuard.
   */
  canActivate(url: string): Observable<true | UrlTree> {
    if (this._canActivate)
      return of(true);

    let hasTokens = Capacitor.isNativePlatform()
      ? this.tracking.hasValidTokens()
      : of(false);

    // Can activate route on start check sequence
    return hasTokens.pipe(
      map(hasTokens => {

        //--------------------------------------------//
        //---  Tracker access token startup rules  ---//
        //--------------------------------------------//

        const skip = [
          '/logout',
          '/login',
          '/pair'
        ];

        if (skip.some(u => u.startsWith(url))) {
          this._skipped = true;
          return true;
        }

        // Important to set to false if we got past skip check so `this._canActivate` can be true
        this._skipped = false;

        if (url.startsWith('/paired') && hasTokens)
          return true;

        if (url.startsWith('/paired') && !hasTokens)
          return this.router.createUrlTree(['home']);

        return !hasTokens || this.router.createUrlTree(['paired'], { queryParams: { proceed: url } })
      }),
      finalize(() => this._canActivate = !this._skipped)
    );
  }
}
