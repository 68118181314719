import { Injectable } from '@angular/core';
import {combineLatest, Observable, of} from "rxjs";
import {MeRepository} from "../domain/endpoints.repositories";
import {UserService} from "../service/user.service";
import {filter, finalize, map, mergeMap, share, take, tap} from "rxjs/operators";
import {MyBuddyGard} from "../domain/models";
import User = MyBuddyGard.Domain.Models.Users.User;
import {TokenService} from "../service/token.service";

@Injectable({
  providedIn: 'root'
})
export class TokenFactory {

  private idToken : string = null;
  private currentUser : User = null;
  private accessTokenRequest: Observable<any>;

  constructor(
    protected me : MeRepository,
    protected users: UserService,
    protected tokenService: TokenService
  ) {
    users.token.subscribe(t => this.idToken = t);
    users.user.subscribe(u => this.currentUser = u);
  }

  getToken(slug: string) : Observable<string> {
    let token = this.currentUser ? localStorage.getItem(`token:${this.currentUser.id}:${slug}`) : null;

    return !(token == null || token == '')
      ? of(token)
      : this.users.token.pipe(
        filter(t => t != null),
        mergeMap(t => combineLatest([this.getAccessToken(slug, t).pipe(take(1)), this.users.user])),
        filter(([at, u]) => u != null),
        tap(([at, user]) => {
          // Only save if user exists
          if (user != null) {
            this.tokenService.setToken(user.id, slug, at);
          }
        }),

        map(([at, user]) => at)
      );
  }

  getNewToken(slug: string): Observable<string> {
    this.tokenService.clearToken(this.currentUser.id, slug);
    return this.getToken(slug);
  }

  private getAccessToken(slug: string, token: string): Observable<any> {
    if (this.accessTokenRequest == null) {
      this.accessTokenRequest = this.me.getAccessToken(slug, token).pipe(
        share(),
        finalize(() => this.accessTokenRequest = null)
      );
    }

    return this.accessTokenRequest;
  }
}
