
/**************************************************************************************************************************************

             ▒▒                                                                                                                            
           ▒▒▒▒▒▒                                                                                                                          
         ▒▒▒▒▒▒▒▒▒▒                                                                                                                        
          ▒▒▒▒▒▒▒▒▒▒▒                                                                                                                      
            ▒▒▒▒▒▒▒                                                                                                                        
              ▒▒▒                     ██       ██    ██       ████████       ███████      ██            ██           ██        ███████     
 ▒▒▒▒▒               ▒▒▒▒▒▒           ██       ███   ██          ██          ██           ██            ██           ██       ██           
▒▒▒▒▒▒▒             ▒▒▒▒▒▒▒▒          ██       █████ ██          ██          ███████      ██            ██           ██       ███████      
▒▒▒▒▒▒▒              ▒▒▒▒▒▒▒          ██       ██  ████          ██          ██           ██            ██           ██             ██     
  ▒▒▒                 ▒▒▒▒            ██       ██    ██          ██          ███████      ███████       ██████       ██       ████████     
          ▒▒▒▒                                                                                                                             
        ▒▒▒▒▒▒▒▒                                                                                                                           
       ▒▒▒▒▒▒▒▒▒▒▒                                                                                                                         
         ▒▒▒▒▒▒▒▒▒                                                                                                                         
           ▒▒▒▒▒                                                                                                                           

***************************************************************************************************************************************
** Code generated by Intellis.Web.Http.ApplicationSchema.SchemaTypeScriptGenerator ** Changes may be overwritten ** http://intell.is **
**************************************************************************************************************************************/

/* Extend these definitions by inheritance to avoid code being overwritten */



export const Schema =
{
  "apiEndpointSchemata": {
    "accountController": {
      "discriminator": "MyBuddyGard.Api.Controllers.AccountController",
      "name": "AccountController",
      "isEntityApi": true,
      "isSearchable": true,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": "MyBuddyGard.Domain.Models.Account.AccountSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchListModelType": "MyBuddyGard.Domain.Models.Account.AccountExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiIdType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "GetBySlug",
          "name": "GetBySlug",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "slug",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "getBySlug",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "CheckSlugAvailability",
          "name": "CheckSlugAvailability",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "slug",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "checkSlugAvailability",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetBySlugWithUsers",
          "name": "GetBySlugWithUsers",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Account.AccountWithUsers, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "slug",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "getBySlugWithUsers",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Save",
          "name": "Save",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "save",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Search",
          "name": "Search",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[Intellis.Domain.Reports.IReport`2[[MyBuddyGard.Domain.Models.Account.AccountExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.Account.AccountSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "parameters",
                  "type": "MyBuddyGard.Domain.Models.Account.AccountSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [
        "AllowCognitoIdToken"
      ],
      "audience": null
    },
    "accountPaymentsController": {
      "discriminator": "MyBuddyGard.Api.Controllers.AccountPaymentsController",
      "name": "AccountPaymentsController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "Setup",
          "name": "Setup",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Payments.SetupResult, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "type",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "setup",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "CancelSetup",
          "name": "CancelSetup",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Api.Models.CancelAccountPaymentsSetupRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "setup",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetPaymentMethods",
          "name": "GetPaymentMethods",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Payments.PaymentMethod, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "paymentMethods",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "SetDefaultPayment",
          "name": "SetDefaultPayment",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Payments.PaymentMethod, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Payments.PaymentMethod, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "default",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST",
                "DELETE"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "delete/{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "PostPaymentAttempt",
          "name": "PostPaymentAttempt",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.PaymentAttemptWithStripeStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "paymentAttempt",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "automationController": {
      "discriminator": "MyBuddyGard.Api.Controllers.AutomationController",
      "name": "AutomationController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "RenewSubscriptions",
          "name": "RenewSubscriptions",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "renewSubscriptions",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": false,
      "authorizationPolicies": [],
      "audience": null
    },
    "chargeController": {
      "discriminator": "MyBuddyGard.Api.Controllers.ChargeController",
      "name": "ChargeController",
      "isEntityApi": true,
      "isSearchable": true,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": "MyBuddyGard.Domain.Models.Charges.ChargeSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchListModelType": "MyBuddyGard.Domain.Models.Charges.ChargeExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiIdType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "GetByNumber",
          "name": "GetByNumber",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Charges.ChargeExtended, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "number",
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "number/{number}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Initialise",
          "name": "Initialise",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                },
                {
                  "name": "savePayment",
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "initialise",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Search",
          "name": "Search",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[Intellis.Domain.Reports.IReport`2[[MyBuddyGard.Domain.Models.Charges.ChargeExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.Charges.ChargeSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "parameters",
                  "type": "MyBuddyGard.Domain.Models.Charges.ChargeSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "commandController": {
      "discriminator": "MyBuddyGard.Api.Controllers.CommandController",
      "name": "CommandController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "GetQueued",
          "name": "GetQueued",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "deviceType",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                },
                {
                  "name": "trackerId",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "getQueued",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.DeviceCommandResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Api.Models.SendCommand, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST",
                "DELETE"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Api.Models.DeleteCommandRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "delete",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "configurationsController": {
      "discriminator": "MyBuddyGard.Api.Controllers.ConfigurationsController",
      "name": "ConfigurationsController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "GetTrackmap",
          "name": "GetTrackmap",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Api.Config.Trackmap.ITrackmapConfig, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "trackmap",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": false,
      "authorizationPolicies": [],
      "audience": null
    },
    "deployController": {
      "discriminator": "MyBuddyGard.Api.Controllers.DeployController",
      "name": "DeployController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "CreateSchema",
          "name": "CreateSchema",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult, Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "createschema",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "MigrateUserAccounts",
          "name": "MigrateUserAccounts",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult, Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "token",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "migrateUserAccounts",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": false,
      "authorizationPolicies": [],
      "audience": null
    },
    "deviceAuthorizationController": {
      "discriminator": "MyBuddyGard.Api.Controllers.DeviceAuthorizationController",
      "name": "DeviceAuthorizationController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "Pair",
          "name": "Pair",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.DevicePairingInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "pair",
              "authorization": true,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Unpair",
          "name": "Unpair",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "token",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "unpair",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetPairedDevice",
          "name": "GetPairedDevice",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "token",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "pairedDevice",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "PostAuthorizationRequest",
          "name": "PostAuthorizationRequest",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationCode, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.DevicePairingInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "authorizationRequest",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "DeleteAuthorizationRequest",
          "name": "DeleteAuthorizationRequest",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "deviceCode",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "authorizationRequest",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "PostAuthorize",
          "name": "PostAuthorize",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationRequest, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "authorize",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetToken",
          "name": "GetToken",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "deviceCode",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "token",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "PostValidateTokens",
          "name": "PostValidateTokens",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResponse, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "tokens",
                  "type": "System.Collections.Generic.IEnumerable`1[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "validateTokens",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": false,
      "authorizationPolicies": [],
      "audience": null
    },
    "deviceController": {
      "discriminator": "MyBuddyGard.Api.Controllers.DeviceController",
      "name": "DeviceController",
      "isEntityApi": true,
      "isSearchable": false,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "GetAccountDevices",
          "name": "GetAccountDevices",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.List`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "slug",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "accountDevices",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Save",
          "name": "Save",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.SaveDeviceResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Devices.SaveDevice, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "save",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "SetImmobilisation",
          "name": "SetImmobilisation",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "immobilisation",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "DeleteDevice",
          "name": "DeleteDevice",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST",
                "DELETE"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "delete/{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "geofenceController": {
      "discriminator": "MyBuddyGard.Api.Controllers.GeofenceController",
      "name": "GeofenceController",
      "isEntityApi": true,
      "isSearchable": false,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "AccountGeofences",
          "name": "AccountGeofences",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "accountGeofences",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Save",
          "name": "Save",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "save",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "DeleteGeofence",
          "name": "DeleteGeofence",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "POST",
                "DELETE"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "delete/{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "healthCheckController": {
      "discriminator": "MyBuddyGard.Api.Controllers.HealthCheckController",
      "name": "HealthCheckController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": false,
      "authorizationPolicies": [],
      "audience": null
    },
    "locationController": {
      "discriminator": "MyBuddyGard.Api.Controllers.LocationController",
      "name": "LocationController",
      "isEntityApi": true,
      "isSearchable": false,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "UpdateLocation",
          "name": "UpdateLocation",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "location",
                  "type": "MyBuddyGard.Domain.Models.DeviceLocation, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "updateLocation",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "PostPairedDevice",
          "name": "PostPairedDevice",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Api.Models.DeviceLocationAndToken, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "paired-device",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "DeviceHistory",
          "name": "DeviceHistory",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "deviceId",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                },
                {
                  "name": "start",
                  "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                },
                {
                  "name": "end",
                  "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "deviceHistory",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Debug",
          "name": "Debug",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "enable",
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                },
                {
                  "name": "deviceId",
                  "type": "System.Nullable`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "debug",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetDeviceLastLocation",
          "name": "GetDeviceLastLocation",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "deviceId",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "device-last",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "meController": {
      "discriminator": "MyBuddyGard.Api.Controllers.MeController",
      "name": "MeController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "MyAccounts",
          "name": "MyAccounts",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.List`1[[MyBuddyGard.Domain.Models.Account.AccountExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "token",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "myAccounts",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetAccessToken",
          "name": "GetAccessToken",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "slug",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                },
                {
                  "name": "token",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "getAccessToken",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "ResetPassword",
          "name": "ResetPassword",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Api.Models.ResetPassword.ResetPasswordResponse, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "resetPassword",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": false,
      "authorizationPolicies": [],
      "audience": null
    },
    "notificationController": {
      "discriminator": "MyBuddyGard.Api.Controllers.NotificationController",
      "name": "NotificationController",
      "isEntityApi": false,
      "isSearchable": false,
      "entityApiModelType": null,
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": null,
      "apiMethods": [
        {
          "discriminator": "Connect",
          "name": "Connect",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Api.Models.Notification.ConnectionRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "connect",
              "authorization": true,
              "authorizationPolicies": [
                "AllowCognitoIdToken"
              ],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Disconnect",
          "name": "Disconnect",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "request",
                  "type": "MyBuddyGard.Api.Models.Notification.ConnectionRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "disconnect",
              "authorization": true,
              "authorizationPolicies": [
                "AllowCognitoIdToken"
              ],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "SendTestNotification",
          "name": "SendTestNotification",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "target",
                  "type": "MyBuddyGard.Api.Models.Notification.Groups.Group, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "send-test",
              "authorization": true,
              "authorizationPolicies": [
                "AllowCognitoIdToken"
              ],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Unread",
          "name": "Unread",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Notifications.INotification, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "ascending",
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "unread",
              "authorization": true,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Seen",
          "name": "Seen",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "notifications",
                  "type": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Notifications.PushNotification, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "seen",
              "authorization": true,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": false,
      "authorizationPolicies": [],
      "audience": null
    },
    "planController": {
      "discriminator": "MyBuddyGard.Api.Controllers.PlanController",
      "name": "PlanController",
      "isEntityApi": true,
      "isSearchable": true,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": "MyBuddyGard.Domain.Models.Plans.PlanSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchListModelType": "MyBuddyGard.Domain.Models.Plans.PlanExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiIdType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "All",
          "name": "All",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "all",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Available",
          "name": "Available",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "available",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "CheckSlugAvailability",
          "name": "CheckSlugAvailability",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "slug",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "checkSlugAvailability",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Save",
          "name": "Save",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Api.Models.SavePlan, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "save",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "DeletePlan",
          "name": "DeletePlan",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST",
                "DELETE"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "delete/{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Search",
          "name": "Search",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[Intellis.Domain.Reports.IReport`2[[MyBuddyGard.Domain.Models.Plans.PlanExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.Plans.PlanSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "parameters",
                  "type": "MyBuddyGard.Domain.Models.Plans.PlanSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [
        "AllowCognitoIdToken"
      ],
      "audience": null
    },
    "rulesEngineController": {
      "discriminator": "MyBuddyGard.Api.Controllers.RulesEngineController",
      "name": "RulesEngineController",
      "isEntityApi": true,
      "isSearchable": false,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "GetByAccount",
          "name": "GetByAccount",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "TriggerModelDefault",
          "name": "TriggerModelDefault",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Rules.Triggers.Trigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.Trigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "triggerModelDefault",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Save",
          "name": "Save",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "save",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "DeleteRule",
          "name": "DeleteRule",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "POST",
                "DELETE"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "delete/{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetActiveActionTimers",
          "name": "GetActiveActionTimers",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Rules.ActionTimerResult, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "actionTimer",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "CancelActionTimer",
          "name": "CancelActionTimer",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "timerId",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "actionTimer/{timerId}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "subscriptionController": {
      "discriminator": "MyBuddyGard.Api.Controllers.SubscriptionController",
      "name": "SubscriptionController",
      "isEntityApi": true,
      "isSearchable": false,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "GetAccountSubscriptions",
          "name": "GetAccountSubscriptions",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.List`1[[MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "slug",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "accountSubscriptions",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetWithCoverage",
          "name": "GetWithCoverage",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "getWithCoverage",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Cancel",
          "name": "Cancel",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "cancel/{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "DefaultRenewalOption",
          "name": "DefaultRenewalOption",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.IActionResult, Microsoft.AspNetCore.Mvc.Abstractions, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "defaultRenewalOption",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    },
    "usersController": {
      "discriminator": "MyBuddyGard.Api.Controllers.UsersController",
      "name": "UsersController",
      "isEntityApi": true,
      "isSearchable": false,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "TokenedSearch",
          "name": "TokenedSearch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Users.TokenedUserExtractCollection, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "parameters",
                  "type": "MyBuddyGard.Domain.Models.Users.UserSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "tokenedsearch",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetByUserName",
          "name": "GetByUserName",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "userName",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "getByUserName",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Save",
          "name": "Save",
          "signatures": [
            {
              "returnType": "System.Threading.Tasks.Task`1[[Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "httpMethods": [
                "POST"
              ],
              "isDefault": false,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Users.UserForm, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "save",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [
        "AllowCognitoIdToken"
      ],
      "audience": null
    },
    "wifiController": {
      "discriminator": "MyBuddyGard.Api.Controllers.WifiController",
      "name": "WifiController",
      "isEntityApi": true,
      "isSearchable": false,
      "entityApiModelType": "MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
      "entityApiSearchParametersType": null,
      "entityApiSearchListModelType": null,
      "entityApiIdType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
      "apiMethods": [
        {
          "discriminator": "Recent",
          "name": "Recent",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": false,
              "parameters": [],
              "route": "recent",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "GetMany",
          "name": "GetMany",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "ids",
                  "type": "System.Collections.Generic.List`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": false
                }
              ],
              "route": "many",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Get",
          "name": "Get",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "GET"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Post",
          "name": "Post",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "POST"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": null,
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Put",
          "name": "Put",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PUT"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "model",
                  "type": "MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Patch",
          "name": "Patch",
          "signatures": [
            {
              "returnType": "Microsoft.AspNetCore.Mvc.ActionResult`1[[MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], Microsoft.AspNetCore.Mvc.Core, Version=6.0.0.0, Culture=neutral, PublicKeyToken=adb9793829ddae60",
              "httpMethods": [
                "PATCH"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                },
                {
                  "name": "patch",
                  "type": "Intellis.Web.Core.Patch`2[[MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], Intellis.Web.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "isBody": true,
                  "isFromRoute": false
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        },
        {
          "discriminator": "Delete",
          "name": "Delete",
          "signatures": [
            {
              "returnType": "MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
              "httpMethods": [
                "DELETE"
              ],
              "isDefault": true,
              "parameters": [
                {
                  "name": "id",
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "isBody": false,
                  "isFromRoute": true
                }
              ],
              "route": "{id}",
              "authorization": false,
              "authorizationPolicies": [],
              "audience": null
            }
          ]
        }
      ],
      "authorization": true,
      "authorizationPolicies": [],
      "audience": null
    }
  },
  "models": {
    "Intellis": {
      "discriminator": "Intellis",
      "name": "Intellis",
      "displayName": null,
      "description": null,
      "isModel": false,
      "type": null,
      "baseTypeData": null,
      "properties": {},
      "Domain": {
        "discriminator": "Intellis.Domain",
        "name": "Domain",
        "displayName": null,
        "description": null,
        "isModel": false,
        "type": null,
        "baseTypeData": null,
        "properties": {},
        "Validation": {
          "discriminator": "Intellis.Domain.Validation",
          "name": "Validation",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "IValidationState": {
            "discriminator": "Intellis.Domain.Validation.IValidationState",
            "name": "IValidationState",
            "displayName": "IValidationState",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "Intellis.Domain.Validation.IValidationState",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "Intellis.Domain.Validation.IValidationState, Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "members": {
                "name": "members",
                "displayName": "Members",
                "gridName": "Members",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.IDictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.Collections.Generic.IList`1[[Intellis.Domain.Validation.IValidationStateMember, Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "Intellis.Domain.Validation.IValidationStateMember",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": true,
                  "isOData": false,
                  "type": "Intellis.Domain.Validation.IValidationStateMember, Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "isValid": {
                "name": "isValid",
                "displayName": "IsValid",
                "gridName": "IsValid",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "IValidationStateMember": {
            "discriminator": "Intellis.Domain.Validation.IValidationStateMember",
            "name": "IValidationStateMember",
            "displayName": "IValidationStateMember",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "Intellis.Domain.Validation.IValidationStateMember",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "Intellis.Domain.Validation.IValidationStateMember, Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "message": {
                "name": "message",
                "displayName": "Message",
                "gridName": "Message",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          }
        },
        "Security": {
          "discriminator": "Intellis.Domain.Security",
          "name": "Security",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "PermissionValueViewModel": {
            "discriminator": "Intellis.Domain.Security.PermissionValueViewModel",
            "name": "PermissionValueViewModel",
            "displayName": "PermissionValueViewModel",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "Intellis.Domain.Security.PermissionValueViewModel",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "Intellis.Domain.Security.PermissionValueViewModel, Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "options": {
                "name": "options",
                "displayName": "Options",
                "gridName": "Options",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          }
        },
        "Search": {
          "discriminator": "Intellis.Domain.Search",
          "name": "Search",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "IPagedSearchParameters": {
            "discriminator": "Intellis.Domain.Search.IPagedSearchParameters",
            "name": "IPagedSearchParameters",
            "displayName": "IPagedSearchParameters",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "Intellis.Domain.Search.IPagedSearchParameters",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "Intellis.Domain.Search.IPagedSearchParameters, Intellis.Domain, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "page": {
                "name": "page",
                "displayName": "Page",
                "gridName": "Page",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "pageSize": {
                "name": "pageSize",
                "displayName": "PageSize",
                "gridName": "PageSize",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "sortBy": {
                "name": "sortBy",
                "displayName": "SortBy",
                "gridName": "SortBy",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "sortDescending": {
                "name": "sortDescending",
                "displayName": "SortDescending",
                "gridName": "SortDescending",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          }
        }
      }
    },
    "MyBuddyGard": {
      "discriminator": "MyBuddyGard",
      "name": "MyBuddyGard",
      "displayName": null,
      "description": null,
      "isModel": false,
      "type": null,
      "baseTypeData": null,
      "properties": {},
      "Domain": {
        "discriminator": "MyBuddyGard.Domain",
        "name": "Domain",
        "displayName": null,
        "description": null,
        "isModel": false,
        "type": null,
        "baseTypeData": null,
        "properties": {},
        "Models": {
          "discriminator": "MyBuddyGard.Domain.Models",
          "name": "Models",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "Devices": {
            "discriminator": "MyBuddyGard.Domain.Models.Devices",
            "name": "Devices",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Device": {
              "discriminator": "MyBuddyGard.Domain.Models.Devices.Device",
              "name": "Device",
              "displayName": "Device",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Devices.Device",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceSettings": {
                  "name": "deviceSettings",
                  "displayName": "DeviceSettings",
                  "gridName": "DeviceSettings",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "pairCode": {
                  "name": "pairCode",
                  "displayName": "PairCode",
                  "gridName": "PairCode",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "paired": {
                  "name": "paired",
                  "displayName": "Paired",
                  "gridName": "Paired",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "datePaired": {
                  "name": "datePaired",
                  "displayName": "DatePaired",
                  "gridName": "DatePaired",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "dateRegistered": {
                  "name": "dateRegistered",
                  "displayName": "DateRegistered",
                  "gridName": "DateRegistered",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "registered": {
                  "name": "registered",
                  "displayName": "Registered",
                  "gridName": "Registered",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceName": {
                  "name": "deviceName",
                  "displayName": "DeviceName",
                  "gridName": "DeviceName",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceModel": {
                  "name": "deviceModel",
                  "displayName": "DeviceModel",
                  "gridName": "DeviceModel",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceType": {
                  "name": "deviceType",
                  "displayName": "DeviceType",
                  "gridName": "DeviceType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "migrationCode": {
                  "name": "migrationCode",
                  "displayName": "MigrationCode",
                  "gridName": "MigrationCode",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sosNumber": {
                  "name": "sosNumber",
                  "displayName": "SosNumber",
                  "gridName": "SosNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "immobilised": {
                  "name": "immobilised",
                  "displayName": "Immobilised",
                  "gridName": "Immobilised",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "forceWakeup": {
                  "name": "forceWakeup",
                  "displayName": "ForceWakeup",
                  "gridName": "ForceWakeup",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "cellTowerPositioning": {
                  "name": "cellTowerPositioning",
                  "displayName": "CellTowerPositioning",
                  "gridName": "CellTowerPositioning",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "lastLocation": {
                  "name": "lastLocation",
                  "displayName": "LastLocation",
                  "gridName": "LastLocation",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Locations.Location",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "lastWifiUpdate": {
                  "name": "lastWifiUpdate",
                  "displayName": "LastWifiUpdate",
                  "gridName": "LastWifiUpdate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Locations.Location",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "lastUpdate": {
                  "name": "lastUpdate",
                  "displayName": "LastUpdate",
                  "gridName": "LastUpdate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Locations.Location",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "rules": {
                  "name": "rules",
                  "displayName": "Rules",
                  "gridName": "Rules",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Rules.Rule",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "subscription": {
                  "name": "subscription",
                  "displayName": "Subscription",
                  "gridName": "Subscription",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "SaveDevice": {
              "discriminator": "MyBuddyGard.Domain.Models.Devices.SaveDevice",
              "name": "SaveDevice",
              "displayName": "SaveDevice",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Devices.SaveDevice",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Devices.SaveDevice, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "deviceName": {
                  "name": "deviceName",
                  "displayName": "DeviceName",
                  "gridName": "DeviceName",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sosNumber": {
                  "name": "sosNumber",
                  "displayName": "SosNumber",
                  "gridName": "SosNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "colour": {
                  "name": "colour",
                  "displayName": "Colour",
                  "gridName": "Colour",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "cellTowerPositioning": {
                  "name": "cellTowerPositioning",
                  "displayName": "CellTowerPositioning",
                  "gridName": "CellTowerPositioning",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceSettings": {
                  "name": "deviceSettings",
                  "displayName": "DeviceSettings",
                  "gridName": "DeviceSettings",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "subscriptionId": {
                  "name": "subscriptionId",
                  "displayName": "SubscriptionId",
                  "gridName": "SubscriptionId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "commandOptions": {
                  "name": "commandOptions",
                  "displayName": "CommandOptions",
                  "gridName": "CommandOptions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Devices.SaveDeviceCommandOption, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Devices.SaveDeviceCommandOption",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Devices.SaveDeviceCommandOption, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "DeviceInfo": {
              "discriminator": "MyBuddyGard.Domain.Models.Devices.DeviceInfo",
              "name": "DeviceInfo",
              "displayName": "DeviceInfo",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Devices.DeviceInfo",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Devices.DeviceInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceSetting": {
                  "name": "deviceSetting",
                  "displayName": "DeviceSetting",
                  "gridName": "DeviceSetting",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceName": {
                  "name": "deviceName",
                  "displayName": "DeviceName",
                  "gridName": "DeviceName",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceModel": {
                  "name": "deviceModel",
                  "displayName": "DeviceModel",
                  "gridName": "DeviceModel",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sosNumber": {
                  "name": "sosNumber",
                  "displayName": "SosNumber",
                  "gridName": "SosNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "immobilised": {
                  "name": "immobilised",
                  "displayName": "Immobilised",
                  "gridName": "Immobilised",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "forceWakeup": {
                  "name": "forceWakeup",
                  "displayName": "ForceWakeup",
                  "gridName": "ForceWakeup",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "cellTowerPositioning": {
                  "name": "cellTowerPositioning",
                  "displayName": "CellTowerPositioning",
                  "gridName": "CellTowerPositioning",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "subscriptionId": {
                  "name": "subscriptionId",
                  "displayName": "SubscriptionId",
                  "gridName": "SubscriptionId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "SaveDeviceResponse": {
              "discriminator": "MyBuddyGard.Domain.Models.Devices.SaveDeviceResponse",
              "name": "SaveDeviceResponse",
              "displayName": "SaveDeviceResponse",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Devices.SaveDeviceResponse",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Devices.SaveDeviceResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "device": {
                  "name": "device",
                  "displayName": "Device",
                  "gridName": "Device",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Devices.Device",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "setupSosResponse": {
                  "name": "setupSosResponse",
                  "displayName": "SetupSosResponse",
                  "gridName": "SetupSosResponse",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.DeviceCommandResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.DeviceCommandResponse",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.DeviceCommandResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "updateContactsResponse": {
                  "name": "updateContactsResponse",
                  "displayName": "UpdateContactsResponse",
                  "gridName": "UpdateContactsResponse",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.DeviceCommandResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.DeviceCommandResponse",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.DeviceCommandResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "SaveDeviceCommandOption": {
              "discriminator": "MyBuddyGard.Domain.Models.Devices.SaveDeviceCommandOption",
              "name": "SaveDeviceCommandOption",
              "displayName": "SaveDeviceCommandOption",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Devices.SaveDeviceCommandOption",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Devices.SaveDeviceCommandOption, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "deviceCommandId": {
                  "name": "deviceCommandId",
                  "displayName": "DeviceCommandId",
                  "gridName": "DeviceCommandId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "data": {
                  "name": "data",
                  "displayName": "Data",
                  "gridName": "Data",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sms": {
                  "name": "sms",
                  "displayName": "Sms",
                  "gridName": "Sms",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "queue": {
                  "name": "queue",
                  "displayName": "Queue",
                  "gridName": "Queue",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "Pairing": {
              "discriminator": "MyBuddyGard.Domain.Models.Devices.Pairing",
              "name": "Pairing",
              "displayName": null,
              "description": null,
              "isModel": false,
              "type": null,
              "baseTypeData": null,
              "properties": {},
              "PlatformDevicePairingInfo": {
                "discriminator": "MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo",
                "name": "PlatformDevicePairingInfo",
                "displayName": "PlatformDevicePairingInfo",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "deviceId": {
                    "name": "deviceId",
                    "displayName": "DeviceId",
                    "gridName": "DeviceId",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deviceName": {
                    "name": "deviceName",
                    "displayName": "DeviceName",
                    "gridName": "DeviceName",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "accountSlug": {
                    "name": "accountSlug",
                    "displayName": "AccountSlug",
                    "gridName": "AccountSlug",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "accountName": {
                    "name": "accountName",
                    "displayName": "AccountName",
                    "gridName": "AccountName",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "accessToken": {
                    "name": "accessToken",
                    "displayName": "AccessToken",
                    "gridName": "AccessToken",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "DevicePairingInfo": {
                "discriminator": "MyBuddyGard.Domain.Models.Devices.Pairing.DevicePairingInfo",
                "name": "DevicePairingInfo",
                "displayName": "DevicePairingInfo",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Devices.Pairing.DevicePairingInfo",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.DevicePairingInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "deviceName": {
                    "name": "deviceName",
                    "displayName": "DeviceName",
                    "gridName": "DeviceName",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "platform": {
                    "name": "platform",
                    "displayName": "Platform",
                    "gridName": "Platform",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "DeviceAuthorizationCode": {
                "discriminator": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationCode",
                "name": "DeviceAuthorizationCode",
                "displayName": "DeviceAuthorizationCode",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationCode",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationCode, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "deviceCode": {
                    "name": "deviceCode",
                    "displayName": "DeviceCode",
                    "gridName": "DeviceCode",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "userCode": {
                    "name": "userCode",
                    "displayName": "UserCode",
                    "gridName": "UserCode",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "validity": {
                    "name": "validity",
                    "displayName": "Validity",
                    "gridName": "Validity",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "interval": {
                    "name": "interval",
                    "displayName": "Interval",
                    "gridName": "Interval",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "authorizationUrl": {
                    "name": "authorizationUrl",
                    "displayName": "AuthorizationUrl",
                    "gridName": "AuthorizationUrl",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "authorizationUrlFlow": {
                    "name": "authorizationUrlFlow",
                    "displayName": "AuthorizationUrlFlow",
                    "gridName": "AuthorizationUrlFlow",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "qrCode": {
                    "name": "qrCode",
                    "displayName": "QrCode",
                    "gridName": "QrCode",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "DeviceAuthorizationResponse": {
                "discriminator": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationResponse",
                "name": "DeviceAuthorizationResponse",
                "displayName": "DeviceAuthorizationResponse",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationResponse",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "success": {
                    "name": "success",
                    "displayName": "Success",
                    "gridName": "Success",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "message": {
                    "name": "message",
                    "displayName": "Message",
                    "gridName": "Message",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "accountName": {
                    "name": "accountName",
                    "displayName": "AccountName",
                    "gridName": "AccountName",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "DeviceAuthorizationRequest": {
                "discriminator": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationRequest",
                "name": "DeviceAuthorizationRequest",
                "displayName": "DeviceAuthorizationRequest",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationRequest",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationRequest, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "accountSlug": {
                    "name": "accountSlug",
                    "displayName": "AccountSlug",
                    "gridName": "AccountSlug",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "userCode": {
                    "name": "userCode",
                    "displayName": "UserCode",
                    "gridName": "UserCode",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "token": {
                    "name": "token",
                    "displayName": "Token",
                    "gridName": "Token",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              }
            }
          },
          "DeviceSettings": {
            "discriminator": "MyBuddyGard.Domain.Models.DeviceSettings",
            "name": "DeviceSettings",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "DeviceSetting": {
              "discriminator": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting",
              "name": "DeviceSetting",
              "displayName": "DeviceSetting",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.DeviceSettings.DeviceSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "deviceType": {
                  "name": "deviceType",
                  "displayName": "DeviceType",
                  "gridName": "DeviceType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "updateRate": {
                  "name": "updateRate",
                  "displayName": "UpdateRate",
                  "gridName": "UpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "minUpdateRate": {
                  "name": "minUpdateRate",
                  "displayName": "MinUpdateRate",
                  "gridName": "MinUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maxUpdateRate": {
                  "name": "maxUpdateRate",
                  "displayName": "MaxUpdateRate",
                  "gridName": "MaxUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "offlineTimeout": {
                  "name": "offlineTimeout",
                  "displayName": "OfflineTimeout",
                  "gridName": "OfflineTimeout",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "features": {
                  "name": "features",
                  "displayName": "Features",
                  "gridName": "Features",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IDictionary`2[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.FeatureCapabilities",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "Geofences": {
            "discriminator": "MyBuddyGard.Domain.Models.Geofences",
            "name": "Geofences",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Geofence": {
              "discriminator": "MyBuddyGard.Domain.Models.Geofences.Geofence",
              "name": "Geofence",
              "displayName": "Geofence",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Geofences.Geofence",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofenceShapes": {
                  "name": "geofenceShapes",
                  "displayName": "GeofenceShapes",
                  "gridName": "GeofenceShapes",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Geofences.GeofenceShape, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Geofences.GeofenceShape",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Geofences.GeofenceShape, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "GeofenceShape": {
              "discriminator": "MyBuddyGard.Domain.Models.Geofences.GeofenceShape",
              "name": "GeofenceShape",
              "displayName": "GeofenceShape",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Geofences.GeofenceShape",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Geofences.GeofenceShape, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "shape": {
                  "name": "shape",
                  "displayName": "Shape",
                  "gridName": "Shape",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofenceId": {
                  "name": "geofenceId",
                  "displayName": "GeofenceId",
                  "gridName": "GeofenceId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "GeofenceCircle": {
              "discriminator": "MyBuddyGard.Domain.Models.Geofences.GeofenceCircle",
              "name": "GeofenceCircle",
              "displayName": "GeofenceCircle",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Geofences.GeofenceCircle",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Geofences.GeofenceCircle, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "center": {
                  "name": "center",
                  "displayName": "Center",
                  "gridName": "Center",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Coordinates",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "population": {
                  "name": "population",
                  "displayName": "Population",
                  "gridName": "Population",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "shape": {
                  "name": "shape",
                  "displayName": "Shape",
                  "gridName": "Shape",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofenceId": {
                  "name": "geofenceId",
                  "displayName": "GeofenceId",
                  "gridName": "GeofenceId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "GeofencePolygon": {
              "discriminator": "MyBuddyGard.Domain.Models.Geofences.GeofencePolygon",
              "name": "GeofencePolygon",
              "displayName": "GeofencePolygon",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Geofences.GeofencePolygon",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Geofences.GeofencePolygon, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "polygonCoordinates": {
                  "name": "polygonCoordinates",
                  "displayName": "PolygonCoordinates",
                  "gridName": "PolygonCoordinates",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Geofences.PolygonCoordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Geofences.PolygonCoordinates",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Geofences.PolygonCoordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "shape": {
                  "name": "shape",
                  "displayName": "Shape",
                  "gridName": "Shape",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofenceId": {
                  "name": "geofenceId",
                  "displayName": "GeofenceId",
                  "gridName": "GeofenceId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "GeofenceRectangle": {
              "discriminator": "MyBuddyGard.Domain.Models.Geofences.GeofenceRectangle",
              "name": "GeofenceRectangle",
              "displayName": "GeofenceRectangle",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Geofences.GeofenceRectangle",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Geofences.GeofenceRectangle, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "south": {
                  "name": "south",
                  "displayName": "South",
                  "gridName": "South",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Double",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "west": {
                  "name": "west",
                  "displayName": "West",
                  "gridName": "West",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Double",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "north": {
                  "name": "north",
                  "displayName": "North",
                  "gridName": "North",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Double",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "east": {
                  "name": "east",
                  "displayName": "East",
                  "gridName": "East",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Double",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "shape": {
                  "name": "shape",
                  "displayName": "Shape",
                  "gridName": "Shape",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofenceId": {
                  "name": "geofenceId",
                  "displayName": "GeofenceId",
                  "gridName": "GeofenceId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "PolygonCoordinates": {
              "discriminator": "MyBuddyGard.Domain.Models.Geofences.PolygonCoordinates",
              "name": "PolygonCoordinates",
              "displayName": "PolygonCoordinates",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Geofences.PolygonCoordinates",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Geofences.PolygonCoordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "coordinates": {
                  "name": "coordinates",
                  "displayName": "Coordinates",
                  "gridName": "Coordinates",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Coordinates",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "listOrder": {
                  "name": "listOrder",
                  "displayName": "ListOrder",
                  "gridName": "ListOrder",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofencePolygonId": {
                  "name": "geofencePolygonId",
                  "displayName": "GeofencePolygonId",
                  "gridName": "GeofencePolygonId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "Rules": {
            "discriminator": "MyBuddyGard.Domain.Models.Rules",
            "name": "Rules",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Rule": {
              "discriminator": "MyBuddyGard.Domain.Models.Rules.Rule",
              "name": "Rule",
              "displayName": "Rule",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Rules.Rule",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "allDevices": {
                  "name": "allDevices",
                  "displayName": "AllDevices",
                  "gridName": "AllDevices",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "scheduleFrom": {
                  "name": "scheduleFrom",
                  "displayName": "ScheduleFrom",
                  "gridName": "ScheduleFrom",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.DateTime",
                  "systemType": "System.Nullable`1[[System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "scheduleTo": {
                  "name": "scheduleTo",
                  "displayName": "ScheduleTo",
                  "gridName": "ScheduleTo",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.DateTime",
                  "systemType": "System.Nullable`1[[System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "allDay": {
                  "name": "allDay",
                  "displayName": "AllDay",
                  "gridName": "AllDay",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "monday": {
                  "name": "monday",
                  "displayName": "Monday",
                  "gridName": "Monday",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "tuesday": {
                  "name": "tuesday",
                  "displayName": "Tuesday",
                  "gridName": "Tuesday",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "wednesday": {
                  "name": "wednesday",
                  "displayName": "Wednesday",
                  "gridName": "Wednesday",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "thursday": {
                  "name": "thursday",
                  "displayName": "Thursday",
                  "gridName": "Thursday",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "friday": {
                  "name": "friday",
                  "displayName": "Friday",
                  "gridName": "Friday",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "saturday": {
                  "name": "saturday",
                  "displayName": "Saturday",
                  "gridName": "Saturday",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sunday": {
                  "name": "sunday",
                  "displayName": "Sunday",
                  "gridName": "Sunday",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "trigger": {
                  "name": "trigger",
                  "displayName": "Trigger",
                  "gridName": "Trigger",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Rules.Triggers.Trigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.Trigger",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Rules.Triggers.Trigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "actions": {
                  "name": "actions",
                  "displayName": "Actions",
                  "gridName": "Actions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Rules.Actions.Action, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.Action",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Rules.Actions.Action, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceIds": {
                  "name": "deviceIds",
                  "displayName": "DeviceIds",
                  "gridName": "DeviceIds",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "pauseHours": {
                  "name": "pauseHours",
                  "displayName": "PauseHours",
                  "gridName": "PauseHours",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "pauseHoursExpiryDate": {
                  "name": "pauseHoursExpiryDate",
                  "displayName": "PauseHoursExpiryDate",
                  "gridName": "PauseHoursExpiryDate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.DateTime",
                  "systemType": "System.Nullable`1[[System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "Actions": {
              "discriminator": "MyBuddyGard.Domain.Models.Rules.Actions",
              "name": "Actions",
              "displayName": null,
              "description": null,
              "isModel": false,
              "type": null,
              "baseTypeData": null,
              "properties": {},
              "Action": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Actions.Action",
                "name": "Action",
                "displayName": "Action",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.Action",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Actions.Action, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "delayMs": {
                    "name": "delayMs",
                    "displayName": "DelayMs",
                    "gridName": "DelayMs",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Int32",
                    "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "EmailAction": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Actions.EmailAction",
                "name": "EmailAction",
                "displayName": "EmailAction",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.EmailAction",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Actions.EmailAction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "sendTo": {
                    "name": "sendTo",
                    "displayName": "SendTo",
                    "gridName": "SendTo",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "message": {
                    "name": "message",
                    "displayName": "Message",
                    "gridName": "Message",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "delayMs": {
                    "name": "delayMs",
                    "displayName": "DelayMs",
                    "gridName": "DelayMs",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Int32",
                    "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "ImmobiliseAction": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Actions.ImmobiliseAction",
                "name": "ImmobiliseAction",
                "displayName": "ImmobiliseAction",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.ImmobiliseAction",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Actions.ImmobiliseAction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "onOff": {
                    "name": "onOff",
                    "displayName": "OnOff",
                    "gridName": "OnOff",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "delayMs": {
                    "name": "delayMs",
                    "displayName": "DelayMs",
                    "gridName": "DelayMs",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Int32",
                    "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "LocateAction": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Actions.LocateAction",
                "name": "LocateAction",
                "displayName": "LocateAction",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.LocateAction",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Actions.LocateAction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "delayMs": {
                    "name": "delayMs",
                    "displayName": "DelayMs",
                    "gridName": "DelayMs",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Int32",
                    "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "NotificationAction": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Actions.NotificationAction",
                "name": "NotificationAction",
                "displayName": "NotificationAction",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.NotificationAction",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Actions.NotificationAction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "message": {
                    "name": "message",
                    "displayName": "Message",
                    "gridName": "Message",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "delayMs": {
                    "name": "delayMs",
                    "displayName": "DelayMs",
                    "gridName": "DelayMs",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Int32",
                    "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "SmsAction": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Actions.SmsAction",
                "name": "SmsAction",
                "displayName": "SmsAction",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.SmsAction",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Actions.SmsAction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "sendTo": {
                    "name": "sendTo",
                    "displayName": "SendTo",
                    "gridName": "SendTo",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "message": {
                    "name": "message",
                    "displayName": "Message",
                    "gridName": "Message",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "delayMs": {
                    "name": "delayMs",
                    "displayName": "DelayMs",
                    "gridName": "DelayMs",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Int32",
                    "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              }
            },
            "Triggers": {
              "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers",
              "name": "Triggers",
              "displayName": null,
              "description": null,
              "isModel": false,
              "type": null,
              "baseTypeData": null,
              "properties": {},
              "Trigger": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers.Trigger",
                "name": "Trigger",
                "displayName": "Trigger",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.Trigger",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.Trigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "triggerType": {
                    "name": "triggerType",
                    "displayName": "TriggerType",
                    "gridName": "TriggerType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "defaultMessage": {
                    "name": "defaultMessage",
                    "displayName": "DefaultMessage",
                    "gridName": "DefaultMessage",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "mergeFields": {
                    "name": "mergeFields",
                    "displayName": "MergeFields",
                    "gridName": "MergeFields",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": true,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "BatteryLevelTrigger": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers.BatteryLevelTrigger",
                "name": "BatteryLevelTrigger",
                "displayName": "BatteryLevelTrigger",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.BatteryLevelTrigger",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.BatteryLevelTrigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "isAbove": {
                    "name": "isAbove",
                    "displayName": "IsAbove",
                    "gridName": "IsAbove",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "level": {
                    "name": "level",
                    "displayName": "Level",
                    "gridName": "Level",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "triggerType": {
                    "name": "triggerType",
                    "displayName": "TriggerType",
                    "gridName": "TriggerType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "defaultMessage": {
                    "name": "defaultMessage",
                    "displayName": "DefaultMessage",
                    "gridName": "DefaultMessage",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "mergeFields": {
                    "name": "mergeFields",
                    "displayName": "MergeFields",
                    "gridName": "MergeFields",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": true,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "GeofenceTrigger": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers.GeofenceTrigger",
                "name": "GeofenceTrigger",
                "displayName": "GeofenceTrigger",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.GeofenceTrigger",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.GeofenceTrigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType",
                    "systemType": "MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "dwellCondition": {
                    "name": "dwellCondition",
                    "displayName": "DwellCondition",
                    "gridName": "DwellCondition",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition",
                    "systemType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "dwellTime": {
                    "name": "dwellTime",
                    "displayName": "DwellTime",
                    "gridName": "DwellTime",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "geofenceId": {
                    "name": "geofenceId",
                    "displayName": "GeofenceId",
                    "gridName": "GeofenceId",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Guid",
                    "systemType": "System.Nullable`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "dwellTimeUnit": {
                    "name": "dwellTimeUnit",
                    "displayName": "DwellTimeUnit",
                    "gridName": "DwellTimeUnit",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit",
                    "systemType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "triggerType": {
                    "name": "triggerType",
                    "displayName": "TriggerType",
                    "gridName": "TriggerType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "defaultMessage": {
                    "name": "defaultMessage",
                    "displayName": "DefaultMessage",
                    "gridName": "DefaultMessage",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "mergeFields": {
                    "name": "mergeFields",
                    "displayName": "MergeFields",
                    "gridName": "MergeFields",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": true,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "PowerTrigger": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers.PowerTrigger",
                "name": "PowerTrigger",
                "displayName": "PowerTrigger",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.PowerTrigger",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.PowerTrigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "powerOn": {
                    "name": "powerOn",
                    "displayName": "PowerOn",
                    "gridName": "PowerOn",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "powerStatusType": {
                    "name": "powerStatusType",
                    "displayName": "PowerStatusType",
                    "gridName": "PowerStatusType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "MyBuddyGard.Domain.Enumerations.Trigger.PowerStatusType",
                    "systemType": "MyBuddyGard.Domain.Enumerations.Trigger.PowerStatusType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.Trigger.PowerStatusType",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.Trigger.PowerStatusType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "triggerType": {
                    "name": "triggerType",
                    "displayName": "TriggerType",
                    "gridName": "TriggerType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "defaultMessage": {
                    "name": "defaultMessage",
                    "displayName": "DefaultMessage",
                    "gridName": "DefaultMessage",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "mergeFields": {
                    "name": "mergeFields",
                    "displayName": "MergeFields",
                    "gridName": "MergeFields",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": true,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "SosTrigger": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers.SosTrigger",
                "name": "SosTrigger",
                "displayName": "SosTrigger",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.SosTrigger",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.SosTrigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "triggerType": {
                    "name": "triggerType",
                    "displayName": "TriggerType",
                    "gridName": "TriggerType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "defaultMessage": {
                    "name": "defaultMessage",
                    "displayName": "DefaultMessage",
                    "gridName": "DefaultMessage",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "mergeFields": {
                    "name": "mergeFields",
                    "displayName": "MergeFields",
                    "gridName": "MergeFields",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": true,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "SpeedTrigger": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers.SpeedTrigger",
                "name": "SpeedTrigger",
                "displayName": "SpeedTrigger",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.SpeedTrigger",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.SpeedTrigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "isAbove": {
                    "name": "isAbove",
                    "displayName": "IsAbove",
                    "gridName": "IsAbove",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "level": {
                    "name": "level",
                    "displayName": "Level",
                    "gridName": "Level",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "triggerType": {
                    "name": "triggerType",
                    "displayName": "TriggerType",
                    "gridName": "TriggerType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "defaultMessage": {
                    "name": "defaultMessage",
                    "displayName": "DefaultMessage",
                    "gridName": "DefaultMessage",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "mergeFields": {
                    "name": "mergeFields",
                    "displayName": "MergeFields",
                    "gridName": "MergeFields",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": true,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "WifiTrigger": {
                "discriminator": "MyBuddyGard.Domain.Models.Rules.Triggers.WifiTrigger",
                "name": "WifiTrigger",
                "displayName": "WifiTrigger",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Rules.Triggers.WifiTrigger",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Rules.Triggers.WifiTrigger, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "wifiId": {
                    "name": "wifiId",
                    "displayName": "WifiId",
                    "gridName": "WifiId",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "System.Guid",
                    "systemType": "System.Nullable`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": true,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "dwellCondition": {
                    "name": "dwellCondition",
                    "displayName": "DwellCondition",
                    "gridName": "DwellCondition",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition",
                    "systemType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "dwellTime": {
                    "name": "dwellTime",
                    "displayName": "DwellTime",
                    "gridName": "DwellTime",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int32",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "dwellTimeUnit": {
                    "name": "dwellTimeUnit",
                    "displayName": "DwellTimeUnit",
                    "gridName": "DwellTimeUnit",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit",
                    "systemType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "actionType": {
                    "name": "actionType",
                    "displayName": "ActionType",
                    "gridName": "ActionType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": "MyBuddyGard.Domain.Enumerations.Trigger.WifiActionType",
                    "systemType": "MyBuddyGard.Domain.Enumerations.Trigger.WifiActionType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.Trigger.WifiActionType",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.Trigger.WifiActionType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "name": {
                    "name": "name",
                    "displayName": "Name",
                    "gridName": "Name",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "triggerType": {
                    "name": "triggerType",
                    "displayName": "TriggerType",
                    "gridName": "TriggerType",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "iconHint": {
                    "name": "iconHint",
                    "displayName": "IconHint",
                    "gridName": "IconHint",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "defaultMessage": {
                    "name": "defaultMessage",
                    "displayName": "DefaultMessage",
                    "gridName": "DefaultMessage",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "mergeFields": {
                    "name": "mergeFields",
                    "displayName": "MergeFields",
                    "gridName": "MergeFields",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": true,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "requiredFeatures": {
                    "name": "requiredFeatures",
                    "displayName": "RequiredFeatures",
                    "gridName": "RequiredFeatures",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
                      "isNative": false,
                      "isArray": true,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "id": {
                    "name": "id",
                    "displayName": "Id",
                    "gridName": "Id",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Guid",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "deleted": {
                    "name": "deleted",
                    "displayName": "Deleted",
                    "gridName": "Deleted",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Boolean",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "version": {
                    "name": "version",
                    "displayName": "Version",
                    "gridName": "Version",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": true,
                    "hideInGrid": true,
                    "hideInForm": true,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.Int64",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              }
            },
            "ActionTimerResult": {
              "discriminator": "MyBuddyGard.Domain.Models.Rules.ActionTimerResult",
              "name": "ActionTimerResult",
              "displayName": "ActionTimerResult",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Rules.ActionTimerResult",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Rules.ActionTimerResult, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "serverTime": {
                  "name": "serverTime",
                  "displayName": "ServerTime",
                  "gridName": "ServerTime",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "actionTimers": {
                  "name": "actionTimers",
                  "displayName": "ActionTimers",
                  "gridName": "ActionTimers",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Rules.ActionTimerViewModel, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Rules.ActionTimerViewModel",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Rules.ActionTimerViewModel, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "ActionTimerViewModel": {
              "discriminator": "MyBuddyGard.Domain.Models.Rules.ActionTimerViewModel",
              "name": "ActionTimerViewModel",
              "displayName": "ActionTimerViewModel",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Rules.ActionTimerViewModel",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Rules.ActionTimerViewModel, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "rule": {
                  "name": "rule",
                  "displayName": "Rule",
                  "gridName": "Rule",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Rules.Rule",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "action": {
                  "name": "action",
                  "displayName": "Action",
                  "gridName": "Action",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Rules.Actions.Action, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Rules.Actions.Action",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Rules.Actions.Action, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "location": {
                  "name": "location",
                  "displayName": "Location",
                  "gridName": "Location",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Locations.Location",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "firingTime": {
                  "name": "firingTime",
                  "displayName": "FiringTime",
                  "gridName": "FiringTime",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "TriggerActionNotification": {
            "discriminator": "MyBuddyGard.Domain.Models.TriggerActionNotification",
            "name": "TriggerActionNotification",
            "displayName": "TriggerActionNotification",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.TriggerActionNotification",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.TriggerActionNotification, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "type": {
                "name": "type",
                "displayName": "Type",
                "gridName": "Type",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "message": {
                "name": "message",
                "displayName": "Message",
                "gridName": "Message",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "seen": {
                "name": "seen",
                "displayName": "Seen",
                "gridName": "Seen",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "notificationDate": {
                "name": "notificationDate",
                "displayName": "NotificationDate",
                "gridName": "NotificationDate",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.DateTime",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountId": {
                "name": "accountId",
                "displayName": "AccountId",
                "gridName": "AccountId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deleted": {
                "name": "deleted",
                "displayName": "Deleted",
                "gridName": "Deleted",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "version": {
                "name": "version",
                "displayName": "Version",
                "gridName": "Version",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int64",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Locations": {
            "discriminator": "MyBuddyGard.Domain.Models.Locations",
            "name": "Locations",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Location": {
              "discriminator": "MyBuddyGard.Domain.Models.Locations.Location",
              "name": "Location",
              "displayName": "Location",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Locations.Location",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Locations.Location, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "deviceId": {
                  "name": "deviceId",
                  "displayName": "DeviceId",
                  "gridName": "DeviceId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "coordinates": {
                  "name": "coordinates",
                  "displayName": "Coordinates",
                  "gridName": "Coordinates",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Coordinates",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "batteryStatus": {
                  "name": "batteryStatus",
                  "displayName": "BatteryStatus",
                  "gridName": "BatteryStatus",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "locationUpdateDate": {
                  "name": "locationUpdateDate",
                  "displayName": "LocationUpdateDate",
                  "gridName": "LocationUpdateDate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "powerStatus": {
                  "name": "powerStatus",
                  "displayName": "PowerStatus",
                  "gridName": "PowerStatus",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sosPressed": {
                  "name": "sosPressed",
                  "displayName": "SosPressed",
                  "gridName": "SosPressed",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "speed": {
                  "name": "speed",
                  "displayName": "Speed",
                  "gridName": "Speed",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Double",
                  "systemType": "System.Nullable`1[[System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Double",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "direction": {
                  "name": "direction",
                  "displayName": "Direction",
                  "gridName": "Direction",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Double",
                  "systemType": "System.Nullable`1[[System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Double",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "locationWifis": {
                  "name": "locationWifis",
                  "displayName": "LocationWifis",
                  "gridName": "LocationWifis",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Locations.LocationWifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Locations.LocationWifi",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Locations.LocationWifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "apiVersion": {
                  "name": "apiVersion",
                  "displayName": "ApiVersion",
                  "gridName": "ApiVersion",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "LocationWifi": {
              "discriminator": "MyBuddyGard.Domain.Models.Locations.LocationWifi",
              "name": "LocationWifi",
              "displayName": "LocationWifi",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Locations.LocationWifi",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Locations.LocationWifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "locationId": {
                  "name": "locationId",
                  "displayName": "LocationId",
                  "gridName": "LocationId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "wifi": {
                  "name": "wifi",
                  "displayName": "Wifi",
                  "gridName": "Wifi",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Wifi",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "signalStrength": {
                  "name": "signalStrength",
                  "displayName": "SignalStrength",
                  "gridName": "SignalStrength",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "DeviceLocation": {
            "discriminator": "MyBuddyGard.Domain.Models.DeviceLocation",
            "name": "DeviceLocation",
            "displayName": "DeviceLocation",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.DeviceLocation",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.DeviceLocation, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int64",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "coordinates": {
                "name": "coordinates",
                "displayName": "Coordinates",
                "gridName": "Coordinates",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Coordinates",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "batteryStatus": {
                "name": "batteryStatus",
                "displayName": "BatteryStatus",
                "gridName": "BatteryStatus",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "System.Int32",
                "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "powerStatus": {
                "name": "powerStatus",
                "displayName": "PowerStatus",
                "gridName": "PowerStatus",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "triggerPowerOnOffTrigger": {
                "name": "triggerPowerOnOffTrigger",
                "displayName": "TriggerPowerOnOffTrigger",
                "gridName": "TriggerPowerOnOffTrigger",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "locationUpdateDate": {
                "name": "locationUpdateDate",
                "displayName": "LocationUpdateDate",
                "gridName": "LocationUpdateDate",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.DateTime",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "sosPressed": {
                "name": "sosPressed",
                "displayName": "SosPressed",
                "gridName": "SosPressed",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "nearbyWifis": {
                "name": "nearbyWifis",
                "displayName": "NearbyWifis",
                "gridName": "NearbyWifis",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Models.Trackers.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Trackers.Wifi",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Trackers.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "speed": {
                "name": "speed",
                "displayName": "Speed",
                "gridName": "Speed",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "System.Double",
                "systemType": "System.Nullable`1[[System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Double",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "direction": {
                "name": "direction",
                "displayName": "Direction",
                "gridName": "Direction",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "System.Double",
                "systemType": "System.Nullable`1[[System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Double",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "altitude": {
                "name": "altitude",
                "displayName": "Altitude",
                "gridName": "Altitude",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "System.Int32",
                "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "baseStations": {
                "name": "baseStations",
                "displayName": "BaseStations",
                "gridName": "BaseStations",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Models.BaseStation, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.BaseStation",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.BaseStation, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "gpsSatelliteCount": {
                "name": "gpsSatelliteCount",
                "displayName": "GpsSatelliteCount",
                "gridName": "GpsSatelliteCount",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "System.Int32",
                "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "rawMessage": {
                "name": "rawMessage",
                "displayName": "RawMessage",
                "gridName": "RawMessage",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "settingsVersion": {
                "name": "settingsVersion",
                "displayName": "SettingsVersion",
                "gridName": "SettingsVersion",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "radioType": {
                "name": "radioType",
                "displayName": "RadioType",
                "gridName": "RadioType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "MyBuddyGard.Domain.Enumerations.RadioTypes",
                "systemType": "System.Nullable`1[[MyBuddyGard.Domain.Enumerations.RadioTypes, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Enumerations.RadioTypes",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Enumerations.RadioTypes, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "hostPowerStatus": {
                "name": "hostPowerStatus",
                "displayName": "HostPowerStatus",
                "gridName": "HostPowerStatus",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "System.Boolean",
                "systemType": "System.Nullable`1[[System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Contact": {
            "discriminator": "MyBuddyGard.Domain.Models.Contact",
            "name": "Contact",
            "displayName": "Contact",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.Contact",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "name": {
                "name": "name",
                "displayName": "Name",
                "gridName": "Name",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "phoneNumber": {
                "name": "phoneNumber",
                "displayName": "PhoneNumber",
                "gridName": "PhoneNumber",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Wifi": {
            "discriminator": "MyBuddyGard.Domain.Models.Wifi",
            "name": "Wifi",
            "displayName": "Wifi",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.Wifi",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "name": {
                "name": "name",
                "displayName": "Name",
                "gridName": "Name",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "macAddress": {
                "name": "macAddress",
                "displayName": "MacAddress",
                "gridName": "MacAddress",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deleted": {
                "name": "deleted",
                "displayName": "Deleted",
                "gridName": "Deleted",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "version": {
                "name": "version",
                "displayName": "Version",
                "gridName": "Version",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int64",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Plans": {
            "discriminator": "MyBuddyGard.Domain.Models.Plans",
            "name": "Plans",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Plan": {
              "discriminator": "MyBuddyGard.Domain.Models.Plans.Plan",
              "name": "Plan",
              "displayName": "Plan",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Plans.Plan",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "slug": {
                  "name": "slug",
                  "displayName": "Slug",
                  "gridName": "Slug",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "description": {
                  "name": "description",
                  "displayName": "Description",
                  "gridName": "Description",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "periodLength": {
                  "name": "periodLength",
                  "displayName": "PeriodLength",
                  "gridName": "PeriodLength",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "periodType": {
                  "name": "periodType",
                  "displayName": "PeriodType",
                  "gridName": "PeriodType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "defaultDeviceSetting": {
                  "name": "defaultDeviceSetting",
                  "displayName": "DefaultDeviceSetting",
                  "gridName": "DefaultDeviceSetting",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "planRenewals": {
                  "name": "planRenewals",
                  "displayName": "PlanRenewals",
                  "gridName": "PlanRenewals",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.PlanRenewal, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Plans.PlanRenewal",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Plans.PlanRenewal, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "defaultPlanRenewal": {
                  "name": "defaultPlanRenewal",
                  "displayName": "DefaultPlanRenewal",
                  "gridName": "DefaultPlanRenewal",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Guid",
                  "systemType": "System.Nullable`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceTypes": {
                  "name": "deviceTypes",
                  "displayName": "DeviceTypes",
                  "gridName": "DeviceTypes",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "planRestriction": {
                  "name": "planRestriction",
                  "displayName": "PlanRestriction",
                  "gridName": "PlanRestriction",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Plans.PlanRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Plans.PlanRestriction",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Plans.PlanRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "PlanSearch": {
              "discriminator": "MyBuddyGard.Domain.Models.Plans.PlanSearch",
              "name": "PlanSearch",
              "displayName": "PlanSearch",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Plans.PlanSearch",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Plans.PlanSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "q": {
                  "name": "q",
                  "displayName": "Search",
                  "gridName": "Search",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": 1,
                  "gridOrder": 1,
                  "formOrder": 1,
                  "description": null,
                  "helpText": null,
                  "prompt": "Search by name or slug",
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "page": {
                  "name": "page",
                  "displayName": "Page",
                  "gridName": "Page",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "pageSize": {
                  "name": "pageSize",
                  "displayName": "PageSize",
                  "gridName": "PageSize",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sortBy": {
                  "name": "sortBy",
                  "displayName": "SortBy",
                  "gridName": "SortBy",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sortDescending": {
                  "name": "sortDescending",
                  "displayName": "SortDescending",
                  "gridName": "SortDescending",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "PlanExtract": {
              "discriminator": "MyBuddyGard.Domain.Models.Plans.PlanExtract",
              "name": "PlanExtract",
              "displayName": "PlanExtract",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Plans.PlanExtract",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Plans.PlanExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "slug": {
                  "name": "slug",
                  "displayName": "Slug",
                  "gridName": "Slug",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "description": {
                  "name": "description",
                  "displayName": "Description",
                  "gridName": "Description",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "periodLength": {
                  "name": "periodLength",
                  "displayName": "PeriodLength",
                  "gridName": "PeriodLength",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "periodType": {
                  "name": "periodType",
                  "displayName": "PeriodType",
                  "gridName": "PeriodType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "PlanDeviceDefaultSetting": {
              "discriminator": "MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting",
              "name": "PlanDeviceDefaultSetting",
              "displayName": "PlanDeviceDefaultSetting",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "planId": {
                  "name": "planId",
                  "displayName": "PlanId",
                  "gridName": "PlanId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "PlanRestriction": {
              "discriminator": "MyBuddyGard.Domain.Models.Plans.PlanRestriction",
              "name": "PlanRestriction",
              "displayName": "PlanRestriction",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Plans.PlanRestriction",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Plans.PlanRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "ruleLimit": {
                  "name": "ruleLimit",
                  "displayName": "RuleLimit",
                  "gridName": "RuleLimit",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofenceLimit": {
                  "name": "geofenceLimit",
                  "displayName": "GeofenceLimit",
                  "gridName": "GeofenceLimit",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "actionsPerRuleLimit": {
                  "name": "actionsPerRuleLimit",
                  "displayName": "ActionsPerRuleLimit",
                  "gridName": "ActionsPerRuleLimit",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "smsLimit": {
                  "name": "smsLimit",
                  "displayName": "SmsLimit",
                  "gridName": "SmsLimit",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "PlanRenewal": {
              "discriminator": "MyBuddyGard.Domain.Models.Plans.PlanRenewal",
              "name": "PlanRenewal",
              "displayName": "PlanRenewal",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Plans.PlanRenewal",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Plans.PlanRenewal, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "amount": {
                  "name": "amount",
                  "displayName": "Amount",
                  "gridName": "Amount",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Decimal",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "periodLength": {
                  "name": "periodLength",
                  "displayName": "PeriodLength",
                  "gridName": "PeriodLength",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "periodType": {
                  "name": "periodType",
                  "displayName": "PeriodType",
                  "gridName": "PeriodType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "MyBuddyGard.Domain.Enumerations.PeriodType",
                  "systemType": "MyBuddyGard.Domain.Enumerations.PeriodType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Enumerations.PeriodType",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Enumerations.PeriodType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "adminOnly": {
                  "name": "adminOnly",
                  "displayName": "AdminOnly",
                  "gridName": "AdminOnly",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "DeviceSettingsRestriction": {
              "discriminator": "MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction",
              "name": "DeviceSettingsRestriction",
              "displayName": "DeviceSettingsRestriction",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "count": {
                  "name": "count",
                  "displayName": "Count",
                  "gridName": "Count",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "Subscriptions": {
            "discriminator": "MyBuddyGard.Domain.Models.Subscriptions",
            "name": "Subscriptions",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Subscription": {
              "discriminator": "MyBuddyGard.Domain.Models.Subscriptions.Subscription",
              "name": "Subscription",
              "displayName": "Subscription",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "plan": {
                  "name": "plan",
                  "displayName": "Plan",
                  "gridName": "Plan",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Plans.Plan",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "devices": {
                  "name": "devices",
                  "displayName": "Devices",
                  "gridName": "Devices",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Devices.DeviceInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Devices.DeviceInfo",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Devices.DeviceInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "defaultPlanRenewalOptionId": {
                  "name": "defaultPlanRenewalOptionId",
                  "displayName": "DefaultPlanRenewalOptionId",
                  "gridName": "DefaultPlanRenewalOptionId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Guid",
                  "systemType": "System.Nullable`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "SubscriptionWithCoverage": {
              "discriminator": "MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage",
              "name": "SubscriptionWithCoverage",
              "displayName": "SubscriptionWithCoverage",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "subscription": {
                  "name": "subscription",
                  "displayName": "Subscription",
                  "gridName": "Subscription",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "coverageToDate": {
                  "name": "coverageToDate",
                  "displayName": "CoverageToDate",
                  "gridName": "CoverageToDate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.DateTime",
                  "systemType": "System.Nullable`1[[System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "Trackers": {
            "discriminator": "MyBuddyGard.Domain.Models.Trackers",
            "name": "Trackers",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Wifi": {
              "discriminator": "MyBuddyGard.Domain.Models.Trackers.Wifi",
              "name": "Wifi",
              "displayName": "Wifi",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Trackers.Wifi",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Trackers.Wifi, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "macAddress": {
                  "name": "macAddress",
                  "displayName": "MacAddress",
                  "gridName": "MacAddress",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "signalStrength": {
                  "name": "signalStrength",
                  "displayName": "SignalStrength",
                  "gridName": "SignalStrength",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "BaseStation": {
            "discriminator": "MyBuddyGard.Domain.Models.BaseStation",
            "name": "BaseStation",
            "displayName": "BaseStation",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.BaseStation",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.BaseStation, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "mcc": {
                "name": "mcc",
                "displayName": "Mcc",
                "gridName": "Mcc",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "mnc": {
                "name": "mnc",
                "displayName": "Mnc",
                "gridName": "Mnc",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "cellTower": {
                "name": "cellTower",
                "displayName": "CellTower",
                "gridName": "CellTower",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "areaCode": {
                "name": "areaCode",
                "displayName": "AreaCode",
                "gridName": "AreaCode",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "serialNumber": {
                "name": "serialNumber",
                "displayName": "SerialNumber",
                "gridName": "SerialNumber",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "signalStrength": {
                "name": "signalStrength",
                "displayName": "SignalStrength",
                "gridName": "SignalStrength",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Coordinates": {
            "discriminator": "MyBuddyGard.Domain.Models.Coordinates",
            "name": "Coordinates",
            "displayName": "Coordinates",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.Coordinates",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.Coordinates, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "longitude": {
                "name": "longitude",
                "displayName": "Longitude",
                "gridName": "Longitude",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Double",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "latitude": {
                "name": "latitude",
                "displayName": "Latitude",
                "gridName": "Latitude",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Double",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Double, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accuracy": {
                "name": "accuracy",
                "displayName": "Accuracy",
                "gridName": "Accuracy",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Decimal",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Account": {
            "discriminator": "MyBuddyGard.Domain.Models.Account",
            "name": "Account",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Account": {
              "discriminator": "MyBuddyGard.Domain.Models.Account.Account",
              "name": "Account",
              "displayName": "Account",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Account.Account",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "ownerId": {
                  "name": "ownerId",
                  "displayName": "OwnerId",
                  "gridName": "OwnerId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "subscriptions": {
                  "name": "subscriptions",
                  "displayName": "Subscriptions",
                  "gridName": "Subscriptions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "devices": {
                  "name": "devices",
                  "displayName": "Devices",
                  "gridName": "Devices",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Devices.Device",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Devices.Device, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "geofences": {
                  "name": "geofences",
                  "displayName": "Geofences",
                  "gridName": "Geofences",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Geofences.Geofence",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Geofences.Geofence, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "rules": {
                  "name": "rules",
                  "displayName": "Rules",
                  "gridName": "Rules",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Rules.Rule",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Rules.Rule, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "slug": {
                  "name": "slug",
                  "displayName": "Slug",
                  "gridName": "Slug",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountName": {
                  "name": "accountName",
                  "displayName": "AccountName",
                  "gridName": "AccountName",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountStatus": {
                  "name": "accountStatus",
                  "displayName": "AccountStatus",
                  "gridName": "AccountStatus",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "MyBuddyGard.Domain.Enumerations.AccountStatus",
                  "systemType": "MyBuddyGard.Domain.Enumerations.AccountStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Enumerations.AccountStatus",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Enumerations.AccountStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "userAccounts": {
                  "name": "userAccounts",
                  "displayName": "UserAccounts",
                  "gridName": "UserAccounts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.UserAccount, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.UserAccount",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.UserAccount, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "reseller": {
                  "name": "reseller",
                  "displayName": "Reseller",
                  "gridName": "Reseller",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "smsCredits": {
                  "name": "smsCredits",
                  "displayName": "SmsCredits",
                  "gridName": "SmsCredits",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "timeZone": {
                  "name": "timeZone",
                  "displayName": "TimeZone",
                  "gridName": "TimeZone",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "AccountInfo": {
              "discriminator": "MyBuddyGard.Domain.Models.Account.AccountInfo",
              "name": "AccountInfo",
              "displayName": "AccountInfo",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Account.AccountInfo",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "ownerId": {
                  "name": "ownerId",
                  "displayName": "OwnerId",
                  "gridName": "OwnerId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "subscriptions": {
                  "name": "subscriptions",
                  "displayName": "Subscriptions",
                  "gridName": "Subscriptions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "slug": {
                  "name": "slug",
                  "displayName": "Slug",
                  "gridName": "Slug",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "regularExpression",
                      "pattern": "^[a-z0-9-]+$",
                      "errorMessage": null
                    },
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountName": {
                  "name": "accountName",
                  "displayName": "AccountName",
                  "gridName": "AccountName",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountStatus": {
                  "name": "accountStatus",
                  "displayName": "AccountStatus",
                  "gridName": "AccountStatus",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "MyBuddyGard.Domain.Enumerations.AccountStatus",
                  "systemType": "MyBuddyGard.Domain.Enumerations.AccountStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Enumerations.AccountStatus",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Enumerations.AccountStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "reseller": {
                  "name": "reseller",
                  "displayName": "Reseller",
                  "gridName": "Reseller",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "timeZone": {
                  "name": "timeZone",
                  "displayName": "TimeZone",
                  "gridName": "TimeZone",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "stripeCustomerId": {
                  "name": "stripeCustomerId",
                  "displayName": "StripeCustomerId",
                  "gridName": "StripeCustomerId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "userAccounts": {
                  "name": "userAccounts",
                  "displayName": "UserAccounts",
                  "gridName": "UserAccounts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.UserAccount, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.UserAccount",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.UserAccount, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "AccountWithUsers": {
              "discriminator": "MyBuddyGard.Domain.Models.Account.AccountWithUsers",
              "name": "AccountWithUsers",
              "displayName": "AccountWithUsers",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Account.AccountWithUsers",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Account.AccountWithUsers, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "account": {
                  "name": "account",
                  "displayName": "Account",
                  "gridName": "Account",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Account.AccountInfo",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "users": {
                  "name": "users",
                  "displayName": "Users",
                  "gridName": "Users",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.List`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Users.User",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "AccountExtract": {
              "discriminator": "MyBuddyGard.Domain.Models.Account.AccountExtract",
              "name": "AccountExtract",
              "displayName": "AccountExtract",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Account.AccountExtract",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Account.AccountExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "ownerId": {
                  "name": "ownerId",
                  "displayName": "OwnerId",
                  "gridName": "OwnerId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "slug": {
                  "name": "slug",
                  "displayName": "Slug",
                  "gridName": "Slug",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountName": {
                  "name": "accountName",
                  "displayName": "AccountName",
                  "gridName": "AccountName",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountStatus": {
                  "name": "accountStatus",
                  "displayName": "AccountStatus",
                  "gridName": "AccountStatus",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "MyBuddyGard.Domain.Enumerations.AccountStatus",
                  "systemType": "MyBuddyGard.Domain.Enumerations.AccountStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Enumerations.AccountStatus",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Enumerations.AccountStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "reseller": {
                  "name": "reseller",
                  "displayName": "Reseller",
                  "gridName": "Reseller",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "timeZone": {
                  "name": "timeZone",
                  "displayName": "TimeZone",
                  "gridName": "TimeZone",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "AccountSearch": {
              "discriminator": "MyBuddyGard.Domain.Models.Account.AccountSearch",
              "name": "AccountSearch",
              "displayName": "AccountSearch",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Account.AccountSearch",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Account.AccountSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "q": {
                  "name": "q",
                  "displayName": "Search",
                  "gridName": "Search",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": 1,
                  "gridOrder": 1,
                  "formOrder": 1,
                  "description": null,
                  "helpText": null,
                  "prompt": "Search by account name or slug.",
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "page": {
                  "name": "page",
                  "displayName": "Page",
                  "gridName": "Page",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "pageSize": {
                  "name": "pageSize",
                  "displayName": "PageSize",
                  "gridName": "PageSize",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sortBy": {
                  "name": "sortBy",
                  "displayName": "SortBy",
                  "gridName": "SortBy",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sortDescending": {
                  "name": "sortDescending",
                  "displayName": "SortDescending",
                  "gridName": "SortDescending",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "AccountInfoWithOwner": {
              "discriminator": "MyBuddyGard.Domain.Models.Account.AccountInfoWithOwner",
              "name": "AccountInfoWithOwner",
              "displayName": "AccountInfoWithOwner",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Account.AccountInfoWithOwner",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Account.AccountInfoWithOwner, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "account": {
                  "name": "account",
                  "displayName": "Account",
                  "gridName": "Account",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Account.AccountInfo",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "owner": {
                  "name": "owner",
                  "displayName": "Owner",
                  "gridName": "Owner",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Users.User",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "UserAccount": {
            "discriminator": "MyBuddyGard.Domain.Models.UserAccount",
            "name": "UserAccount",
            "displayName": "UserAccount",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.UserAccount",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.UserAccount, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "userId": {
                "name": "userId",
                "displayName": "UserId",
                "gridName": "UserId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountId": {
                "name": "accountId",
                "displayName": "AccountId",
                "gridName": "AccountId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deleted": {
                "name": "deleted",
                "displayName": "Deleted",
                "gridName": "Deleted",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "version": {
                "name": "version",
                "displayName": "Version",
                "gridName": "Version",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int64",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Charges": {
            "discriminator": "MyBuddyGard.Domain.Models.Charges",
            "name": "Charges",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Charge": {
              "discriminator": "MyBuddyGard.Domain.Models.Charges.Charge",
              "name": "Charge",
              "displayName": "Charge",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Charges.Charge",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeInclusions": {
                  "name": "chargeInclusions",
                  "displayName": "ChargeInclusions",
                  "gridName": "ChargeInclusions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "amount": {
                  "name": "amount",
                  "displayName": "Amount",
                  "gridName": "Amount",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Decimal",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "number": {
                  "name": "number",
                  "displayName": "Number",
                  "gridName": "Number",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "description": {
                  "name": "description",
                  "displayName": "Description",
                  "gridName": "Description",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeTime": {
                  "name": "chargeTime",
                  "displayName": "ChargeTime",
                  "gridName": "ChargeTime",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "paymentReference": {
                  "name": "paymentReference",
                  "displayName": "PaymentReference",
                  "gridName": "PaymentReference",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "StripeCharge": {
              "discriminator": "MyBuddyGard.Domain.Models.Charges.StripeCharge",
              "name": "StripeCharge",
              "displayName": "StripeCharge",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Charges.StripeCharge",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Charges.StripeCharge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "lastFourDigitsOfCard": {
                  "name": "lastFourDigitsOfCard",
                  "displayName": "LastFourDigitsOfCard",
                  "gridName": "LastFourDigitsOfCard",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeInclusions": {
                  "name": "chargeInclusions",
                  "displayName": "ChargeInclusions",
                  "gridName": "ChargeInclusions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "amount": {
                  "name": "amount",
                  "displayName": "Amount",
                  "gridName": "Amount",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Decimal",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "number": {
                  "name": "number",
                  "displayName": "Number",
                  "gridName": "Number",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "description": {
                  "name": "description",
                  "displayName": "Description",
                  "gridName": "Description",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeTime": {
                  "name": "chargeTime",
                  "displayName": "ChargeTime",
                  "gridName": "ChargeTime",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "paymentReference": {
                  "name": "paymentReference",
                  "displayName": "PaymentReference",
                  "gridName": "PaymentReference",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "InvoiceCharge": {
              "discriminator": "MyBuddyGard.Domain.Models.Charges.InvoiceCharge",
              "name": "InvoiceCharge",
              "displayName": "InvoiceCharge",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Charges.InvoiceCharge",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Charges.InvoiceCharge, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeInclusions": {
                  "name": "chargeInclusions",
                  "displayName": "ChargeInclusions",
                  "gridName": "ChargeInclusions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "amount": {
                  "name": "amount",
                  "displayName": "Amount",
                  "gridName": "Amount",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Decimal",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "number": {
                  "name": "number",
                  "displayName": "Number",
                  "gridName": "Number",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "description": {
                  "name": "description",
                  "displayName": "Description",
                  "gridName": "Description",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeTime": {
                  "name": "chargeTime",
                  "displayName": "ChargeTime",
                  "gridName": "ChargeTime",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "paymentReference": {
                  "name": "paymentReference",
                  "displayName": "PaymentReference",
                  "gridName": "PaymentReference",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "ChargeExtract": {
              "discriminator": "MyBuddyGard.Domain.Models.Charges.ChargeExtract",
              "name": "ChargeExtract",
              "displayName": "ChargeExtract",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Charges.ChargeExtract",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Charges.ChargeExtract, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountId": {
                  "name": "accountId",
                  "displayName": "AccountId",
                  "gridName": "AccountId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "amount": {
                  "name": "amount",
                  "displayName": "Amount",
                  "gridName": "Amount",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Decimal",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "number": {
                  "name": "number",
                  "displayName": "Number",
                  "gridName": "Number",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "description": {
                  "name": "description",
                  "displayName": "Description",
                  "gridName": "Description",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeTime": {
                  "name": "chargeTime",
                  "displayName": "ChargeTime",
                  "gridName": "ChargeTime",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "paymentReference": {
                  "name": "paymentReference",
                  "displayName": "PaymentReference",
                  "gridName": "PaymentReference",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "ChargeSearch": {
              "discriminator": "MyBuddyGard.Domain.Models.Charges.ChargeSearch",
              "name": "ChargeSearch",
              "displayName": "ChargeSearch",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Charges.ChargeSearch",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Charges.ChargeSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "q": {
                  "name": "q",
                  "displayName": "Search",
                  "gridName": "Search",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": 1,
                  "gridOrder": 1,
                  "formOrder": 1,
                  "description": null,
                  "helpText": null,
                  "prompt": "Search",
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "accountSlug": {
                  "name": "accountSlug",
                  "displayName": "AccountSlug",
                  "gridName": "AccountSlug",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "page": {
                  "name": "page",
                  "displayName": "Page",
                  "gridName": "Page",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "pageSize": {
                  "name": "pageSize",
                  "displayName": "PageSize",
                  "gridName": "PageSize",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sortBy": {
                  "name": "sortBy",
                  "displayName": "SortBy",
                  "gridName": "SortBy",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "sortDescending": {
                  "name": "sortDescending",
                  "displayName": "SortDescending",
                  "gridName": "SortDescending",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "ChargeExtended": {
              "discriminator": "MyBuddyGard.Domain.Models.Charges.ChargeExtended",
              "name": "ChargeExtended",
              "displayName": "ChargeExtended",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Charges.ChargeExtended",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Charges.ChargeExtended, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeInclusions": {
                  "name": "chargeInclusions",
                  "displayName": "ChargeInclusions",
                  "gridName": "ChargeInclusions",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "account": {
                  "name": "account",
                  "displayName": "Account",
                  "gridName": "Account",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Account.AccountInfoWithOwner, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Account.AccountInfoWithOwner",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Account.AccountInfoWithOwner, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "amount": {
                  "name": "amount",
                  "displayName": "Amount",
                  "gridName": "Amount",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Decimal",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "number": {
                  "name": "number",
                  "displayName": "Number",
                  "gridName": "Number",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "description": {
                  "name": "description",
                  "displayName": "Description",
                  "gridName": "Description",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "chargeTime": {
                  "name": "chargeTime",
                  "displayName": "ChargeTime",
                  "gridName": "ChargeTime",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "paymentReference": {
                  "name": "paymentReference",
                  "displayName": "PaymentReference",
                  "gridName": "PaymentReference",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "ChargeInclusions": {
            "discriminator": "MyBuddyGard.Domain.Models.ChargeInclusions",
            "name": "ChargeInclusions",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "ChargeInclusion": {
              "discriminator": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion",
              "name": "ChargeInclusion",
              "displayName": "ChargeInclusion",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.ChargeInclusions.ChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "SubscriptionChargeInclusion": {
              "discriminator": "MyBuddyGard.Domain.Models.ChargeInclusions.SubscriptionChargeInclusion",
              "name": "SubscriptionChargeInclusion",
              "displayName": "SubscriptionChargeInclusion",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.ChargeInclusions.SubscriptionChargeInclusion",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.ChargeInclusions.SubscriptionChargeInclusion, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "subscription": {
                  "name": "subscription",
                  "displayName": "Subscription",
                  "gridName": "Subscription",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Subscriptions.Subscription",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Subscriptions.Subscription, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "coverageFromDate": {
                  "name": "coverageFromDate",
                  "displayName": "CoverageFromDate",
                  "gridName": "CoverageFromDate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "coverageToDate": {
                  "name": "coverageToDate",
                  "displayName": "CoverageToDate",
                  "gridName": "CoverageToDate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "planRenewalId": {
                  "name": "planRenewalId",
                  "displayName": "PlanRenewalId",
                  "gridName": "PlanRenewalId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "Payments": {
            "discriminator": "MyBuddyGard.Domain.Models.Payments",
            "name": "Payments",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "PaymentMethod": {
              "discriminator": "MyBuddyGard.Domain.Models.Payments.PaymentMethod",
              "name": "PaymentMethod",
              "displayName": "PaymentMethod",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Payments.PaymentMethod",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Payments.PaymentMethod, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "isDefault": {
                  "name": "isDefault",
                  "displayName": "IsDefault",
                  "gridName": "IsDefault",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "CardPaymentMethod": {
              "discriminator": "MyBuddyGard.Domain.Models.Payments.CardPaymentMethod",
              "name": "CardPaymentMethod",
              "displayName": "CardPaymentMethod",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Payments.CardPaymentMethod",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Payments.CardPaymentMethod, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "lastFourDigits": {
                  "name": "lastFourDigits",
                  "displayName": "LastFourDigits",
                  "gridName": "LastFourDigits",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "brand": {
                  "name": "brand",
                  "displayName": "Brand",
                  "gridName": "Brand",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "expiryMonth": {
                  "name": "expiryMonth",
                  "displayName": "ExpiryMonth",
                  "gridName": "ExpiryMonth",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "expiryYear": {
                  "name": "expiryYear",
                  "displayName": "ExpiryYear",
                  "gridName": "ExpiryYear",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "nameOnCard": {
                  "name": "nameOnCard",
                  "displayName": "NameOnCard",
                  "gridName": "NameOnCard",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "name": {
                  "name": "name",
                  "displayName": "Name",
                  "gridName": "Name",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "isDefault": {
                  "name": "isDefault",
                  "displayName": "IsDefault",
                  "gridName": "IsDefault",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "SetupResult": {
              "discriminator": "MyBuddyGard.Domain.Models.Payments.SetupResult",
              "name": "SetupResult",
              "displayName": "SetupResult",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Payments.SetupResult",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Payments.SetupResult, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "clientSecret": {
                  "name": "clientSecret",
                  "displayName": "ClientSecret",
                  "gridName": "ClientSecret",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "SignalR": {
            "discriminator": "MyBuddyGard.Domain.Models.SignalR",
            "name": "SignalR",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Notification": {
              "discriminator": "MyBuddyGard.Domain.Models.SignalR.Notification",
              "name": "Notification",
              "displayName": "Notification",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.SignalR.Notification",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.SignalR.Notification, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "identifier": {
                  "name": "identifier",
                  "displayName": "Identifier",
                  "gridName": "Identifier",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "message": {
                  "name": "message",
                  "displayName": "Message",
                  "gridName": "Message",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "FeatureCapabilities": {
            "discriminator": "MyBuddyGard.Domain.Models.FeatureCapabilities",
            "name": "FeatureCapabilities",
            "displayName": "FeatureCapabilities",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.FeatureCapabilities",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "availableViaData": {
                "name": "availableViaData",
                "displayName": "AvailableViaData",
                "gridName": "AvailableViaData",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "availableViaSms": {
                "name": "availableViaSms",
                "displayName": "AvailableViaSms",
                "gridName": "AvailableViaSms",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Notifications": {
            "discriminator": "MyBuddyGard.Domain.Models.Notifications",
            "name": "Notifications",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "PushNotification": {
              "discriminator": "MyBuddyGard.Domain.Models.Notifications.PushNotification",
              "name": "PushNotification",
              "displayName": "PushNotification",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Notifications.PushNotification",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Notifications.PushNotification, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "title": {
                  "name": "title",
                  "displayName": "Title",
                  "gridName": "Title",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "message": {
                  "name": "message",
                  "displayName": "Message",
                  "gridName": "Message",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "data": {
                  "name": "data",
                  "displayName": "Data",
                  "gridName": "Data",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "dateDelivered": {
                  "name": "dateDelivered",
                  "displayName": "DateDelivered",
                  "gridName": "DateDelivered",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "INotification": {
              "discriminator": "MyBuddyGard.Domain.Models.Notifications.INotification",
              "name": "INotification",
              "displayName": "INotification",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Notifications.INotification",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Notifications.INotification, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "title": {
                  "name": "title",
                  "displayName": "Title",
                  "gridName": "Title",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "message": {
                  "name": "message",
                  "displayName": "Message",
                  "gridName": "Message",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "data": {
                  "name": "data",
                  "displayName": "Data",
                  "gridName": "Data",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.Dictionary`2[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e],[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "dateDelivered": {
                  "name": "dateDelivered",
                  "displayName": "DateDelivered",
                  "gridName": "DateDelivered",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.DateTime",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "DeviceCommandResponse": {
            "discriminator": "MyBuddyGard.Domain.Models.DeviceCommandResponse",
            "name": "DeviceCommandResponse",
            "displayName": "DeviceCommandResponse",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.DeviceCommandResponse",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.DeviceCommandResponse, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "success": {
                "name": "success",
                "displayName": "Success",
                "gridName": "Success",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "message": {
                "name": "message",
                "displayName": "Message",
                "gridName": "Message",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "errorMessage": {
                "name": "errorMessage",
                "displayName": "ErrorMessage",
                "gridName": "ErrorMessage",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "result": {
                "name": "result",
                "displayName": "Result",
                "gridName": "Result",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "MyBuddyGard.Domain.Enumerations.DeviceCommandResponseResult",
                "systemType": "MyBuddyGard.Domain.Enumerations.DeviceCommandResponseResult, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Enumerations.DeviceCommandResponseResult",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Enumerations.DeviceCommandResponseResult, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "LocateCommand": {
            "discriminator": "MyBuddyGard.Domain.Models.LocateCommand",
            "name": "LocateCommand",
            "displayName": "LocateCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.LocateCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.LocateCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountSlug": {
                "name": "accountSlug",
                "displayName": "AccountSlug",
                "gridName": "AccountSlug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queueIdentifier": {
                "name": "queueIdentifier",
                "displayName": "QueueIdentifier",
                "gridName": "QueueIdentifier",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypeDiscriminator": {
                "name": "deviceTypeDiscriminator",
                "displayName": "DeviceTypeDiscriminator",
                "gridName": "DeviceTypeDiscriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "SosNumberSetupCommand": {
            "discriminator": "MyBuddyGard.Domain.Models.SosNumberSetupCommand",
            "name": "SosNumberSetupCommand",
            "displayName": "SosNumberSetupCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.SosNumberSetupCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.SosNumberSetupCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "sosNumber": {
                "name": "sosNumber",
                "displayName": "SosNumber",
                "gridName": "SosNumber",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountId": {
                "name": "accountId",
                "displayName": "AccountId",
                "gridName": "AccountId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountSlug": {
                "name": "accountSlug",
                "displayName": "AccountSlug",
                "gridName": "AccountSlug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queueIdentifier": {
                "name": "queueIdentifier",
                "displayName": "QueueIdentifier",
                "gridName": "QueueIdentifier",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypeDiscriminator": {
                "name": "deviceTypeDiscriminator",
                "displayName": "DeviceTypeDiscriminator",
                "gridName": "DeviceTypeDiscriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "ContactsCommand": {
            "discriminator": "MyBuddyGard.Domain.Models.ContactsCommand",
            "name": "ContactsCommand",
            "displayName": "ContactsCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.ContactsCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.ContactsCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "contacts": {
                "name": "contacts",
                "displayName": "Contacts",
                "gridName": "Contacts",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.IEnumerable`1[[MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Contact",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountSlug": {
                "name": "accountSlug",
                "displayName": "AccountSlug",
                "gridName": "AccountSlug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queueIdentifier": {
                "name": "queueIdentifier",
                "displayName": "QueueIdentifier",
                "gridName": "QueueIdentifier",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypeDiscriminator": {
                "name": "deviceTypeDiscriminator",
                "displayName": "DeviceTypeDiscriminator",
                "gridName": "DeviceTypeDiscriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "MonitorCommand": {
            "discriminator": "MyBuddyGard.Domain.Models.MonitorCommand",
            "name": "MonitorCommand",
            "displayName": "MonitorCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.MonitorCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.MonitorCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "monitorPhoneNumber": {
                "name": "monitorPhoneNumber",
                "displayName": "MonitorPhoneNumber",
                "gridName": "MonitorPhoneNumber",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountSlug": {
                "name": "accountSlug",
                "displayName": "AccountSlug",
                "gridName": "AccountSlug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queueIdentifier": {
                "name": "queueIdentifier",
                "displayName": "QueueIdentifier",
                "gridName": "QueueIdentifier",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypeDiscriminator": {
                "name": "deviceTypeDiscriminator",
                "displayName": "DeviceTypeDiscriminator",
                "gridName": "DeviceTypeDiscriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "FindCommand": {
            "discriminator": "MyBuddyGard.Domain.Models.FindCommand",
            "name": "FindCommand",
            "displayName": "FindCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.FindCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.FindCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountSlug": {
                "name": "accountSlug",
                "displayName": "AccountSlug",
                "gridName": "AccountSlug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queueIdentifier": {
                "name": "queueIdentifier",
                "displayName": "QueueIdentifier",
                "gridName": "QueueIdentifier",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypeDiscriminator": {
                "name": "deviceTypeDiscriminator",
                "displayName": "DeviceTypeDiscriminator",
                "gridName": "DeviceTypeDiscriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "ImmobiliseCommand": {
            "discriminator": "MyBuddyGard.Domain.Models.ImmobiliseCommand",
            "name": "ImmobiliseCommand",
            "displayName": "ImmobiliseCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.ImmobiliseCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.ImmobiliseCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "immobilisationStatus": {
                "name": "immobilisationStatus",
                "displayName": "ImmobilisationStatus",
                "gridName": "ImmobilisationStatus",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountSlug": {
                "name": "accountSlug",
                "displayName": "AccountSlug",
                "gridName": "AccountSlug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queueIdentifier": {
                "name": "queueIdentifier",
                "displayName": "QueueIdentifier",
                "gridName": "QueueIdentifier",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypeDiscriminator": {
                "name": "deviceTypeDiscriminator",
                "displayName": "DeviceTypeDiscriminator",
                "gridName": "DeviceTypeDiscriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "PaymentAttemptWithStripeStatus": {
            "discriminator": "MyBuddyGard.Domain.Models.PaymentAttemptWithStripeStatus",
            "name": "PaymentAttemptWithStripeStatus",
            "displayName": "PaymentAttemptWithStripeStatus",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.PaymentAttemptWithStripeStatus",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.PaymentAttemptWithStripeStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "accountPaymentAttempt": {
                "name": "accountPaymentAttempt",
                "displayName": "AccountPaymentAttempt",
                "gridName": "AccountPaymentAttempt",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "MyBuddyGard.Domain.Models.AccountPaymentAttempt, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.AccountPaymentAttempt",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.AccountPaymentAttempt, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "errorType": {
                "name": "errorType",
                "displayName": "ErrorType",
                "gridName": "ErrorType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "errorCode": {
                "name": "errorCode",
                "displayName": "ErrorCode",
                "gridName": "ErrorCode",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "stripeStatus": {
                "name": "stripeStatus",
                "displayName": "StripeStatus",
                "gridName": "StripeStatus",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Users": {
            "discriminator": "MyBuddyGard.Domain.Models.Users",
            "name": "Users",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "User": {
              "discriminator": "MyBuddyGard.Domain.Models.Users.User",
              "name": "User",
              "displayName": "User",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Users.User",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "userName": {
                  "name": "userName",
                  "displayName": "User name",
                  "gridName": "User name",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "firstName": {
                  "name": "firstName",
                  "displayName": "First name",
                  "gridName": "First name",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "lastName": {
                  "name": "lastName",
                  "displayName": "Last name",
                  "gridName": "Last name",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "phoneNumber": {
                  "name": "phoneNumber",
                  "displayName": "Phone number",
                  "gridName": "Phone number",
                  "editorHint": "phoneNumberAustralia",
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": 5,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "emailAddress": {
                  "name": "emailAddress",
                  "displayName": "Email",
                  "gridName": "Email",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": 10,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "picture": {
                  "name": "picture",
                  "displayName": "Avatar",
                  "gridName": "Avatar",
                  "editorHint": "avatar",
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": "A photo to be displayed within the site.",
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "administrator": {
                  "name": "administrator",
                  "displayName": "Administrator",
                  "gridName": "Administrator",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "external": {
                  "name": "external",
                  "displayName": "External",
                  "gridName": "External",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "TokenedUserExtractCollection": {
              "discriminator": "MyBuddyGard.Domain.Models.Users.TokenedUserExtractCollection",
              "name": "TokenedUserExtractCollection",
              "displayName": "TokenedUserExtractCollection",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Users.TokenedUserExtractCollection",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Users.TokenedUserExtractCollection, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "items": {
                  "name": "items",
                  "displayName": "Items",
                  "gridName": "Items",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Users.User",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Users.User, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "previousPaginationToken": {
                  "name": "previousPaginationToken",
                  "displayName": "PreviousPaginationToken",
                  "gridName": "PreviousPaginationToken",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "currentPaginationToken": {
                  "name": "currentPaginationToken",
                  "displayName": "CurrentPaginationToken",
                  "gridName": "CurrentPaginationToken",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "nextPaginationToken": {
                  "name": "nextPaginationToken",
                  "displayName": "NextPaginationToken",
                  "gridName": "NextPaginationToken",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "UserSearch": {
              "discriminator": "MyBuddyGard.Domain.Models.Users.UserSearch",
              "name": "UserSearch",
              "displayName": "UserSearch",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Users.UserSearch",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Users.UserSearch, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "q": {
                  "name": "q",
                  "displayName": "Search",
                  "gridName": "Search",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": 2,
                  "gridOrder": 2,
                  "formOrder": 2,
                  "description": null,
                  "helpText": null,
                  "prompt": "Search text.",
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "searchProperty": {
                  "name": "searchProperty",
                  "displayName": "Search type",
                  "gridName": "Search type",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": 1,
                  "gridOrder": 1,
                  "formOrder": 1,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "Intellis.Aws.Core.Cognito.Users.UserSearchProperty",
                  "systemType": "Intellis.Aws.Core.Cognito.Users.UserSearchProperty, Intellis.Aws.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "Intellis.Aws.Core.Cognito.Users.UserSearchProperty",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "Intellis.Aws.Core.Cognito.Users.UserSearchProperty, Intellis.Aws.Core, Version=1.1.386.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "paginationToken": {
                  "name": "paginationToken",
                  "displayName": "PaginationToken",
                  "gridName": "PaginationToken",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "currentPaginationToken": {
                  "name": "currentPaginationToken",
                  "displayName": "CurrentPaginationToken",
                  "gridName": "CurrentPaginationToken",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "nextPaginationToken": {
                  "name": "nextPaginationToken",
                  "displayName": "NextPaginationToken",
                  "gridName": "NextPaginationToken",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "UserForm": {
              "discriminator": "MyBuddyGard.Domain.Models.Users.UserForm",
              "name": "UserForm",
              "displayName": "UserForm",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Models.Users.UserForm",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Models.Users.UserForm, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "temporaryPassword": {
                  "name": "temporaryPassword",
                  "displayName": "TemporaryPassword",
                  "gridName": "TemporaryPassword",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "userName": {
                  "name": "userName",
                  "displayName": "User name",
                  "gridName": "User name",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "firstName": {
                  "name": "firstName",
                  "displayName": "First name",
                  "gridName": "First name",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "lastName": {
                  "name": "lastName",
                  "displayName": "Last name",
                  "gridName": "Last name",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "phoneNumber": {
                  "name": "phoneNumber",
                  "displayName": "Phone number",
                  "gridName": "Phone number",
                  "editorHint": "phoneNumberAustralia",
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": 5,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "emailAddress": {
                  "name": "emailAddress",
                  "displayName": "Email",
                  "gridName": "Email",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": 10,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "picture": {
                  "name": "picture",
                  "displayName": "Avatar",
                  "gridName": "Avatar",
                  "editorHint": "avatar",
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": "A photo to be displayed within the site.",
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "administrator": {
                  "name": "administrator",
                  "displayName": "Administrator",
                  "gridName": "Administrator",
                  "editorHint": null,
                  "validators": [
                    {
                      "discriminator": "required",
                      "errorMessage": null
                    }
                  ],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "external": {
                  "name": "external",
                  "displayName": "External",
                  "gridName": "External",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "AccountPaymentAttempt": {
            "discriminator": "MyBuddyGard.Domain.Models.AccountPaymentAttempt",
            "name": "AccountPaymentAttempt",
            "displayName": "AccountPaymentAttempt",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.AccountPaymentAttempt",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.AccountPaymentAttempt, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "account": {
                "name": "account",
                "displayName": "Account",
                "gridName": "Account",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Account.AccountInfo",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Account.AccountInfo, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "date": {
                "name": "date",
                "displayName": "Date",
                "gridName": "Date",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.DateTime",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.DateTime, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "status": {
                "name": "status",
                "displayName": "Status",
                "gridName": "Status",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "MyBuddyGard.Domain.Enumerations.PaymentStatus",
                "systemType": "MyBuddyGard.Domain.Enumerations.PaymentStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Enumerations.PaymentStatus",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Enumerations.PaymentStatus, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "lastFourDigits": {
                "name": "lastFourDigits",
                "displayName": "LastFourDigits",
                "gridName": "LastFourDigits",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deleted": {
                "name": "deleted",
                "displayName": "Deleted",
                "gridName": "Deleted",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "version": {
                "name": "version",
                "displayName": "Version",
                "gridName": "Version",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int64",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "DeviceCommand": {
            "discriminator": "MyBuddyGard.Domain.Models.DeviceCommand",
            "name": "DeviceCommand",
            "displayName": "DeviceCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Domain.Models.DeviceCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Domain.Models.DeviceCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "commandId": {
                "name": "commandId",
                "displayName": "CommandId",
                "gridName": "CommandId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "discriminator": {
                "name": "discriminator",
                "displayName": "Discriminator",
                "gridName": "Discriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "accountSlug": {
                "name": "accountSlug",
                "displayName": "AccountSlug",
                "gridName": "AccountSlug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queueIdentifier": {
                "name": "queueIdentifier",
                "displayName": "QueueIdentifier",
                "gridName": "QueueIdentifier",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceId": {
                "name": "deviceId",
                "displayName": "DeviceId",
                "gridName": "DeviceId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypeDiscriminator": {
                "name": "deviceTypeDiscriminator",
                "displayName": "DeviceTypeDiscriminator",
                "gridName": "DeviceTypeDiscriminator",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          }
        },
        "Entities": {
          "discriminator": "MyBuddyGard.Domain.Entities",
          "name": "Entities",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "DeviceSettings": {
            "discriminator": "MyBuddyGard.Domain.Entities.DeviceSettings",
            "name": "DeviceSettings",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "IContactsEnabled": {
              "discriminator": "MyBuddyGard.Domain.Entities.DeviceSettings.IContactsEnabled",
              "name": "IContactsEnabled",
              "displayName": "IContactsEnabled",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Domain.Entities.DeviceSettings.IContactsEnabled",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Domain.Entities.DeviceSettings.IContactsEnabled, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "contacts": {
                  "name": "contacts",
                  "displayName": "Contacts",
                  "gridName": "Contacts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Contact",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "contactsSynced": {
                  "name": "contactsSynced",
                  "displayName": "ContactsSynced",
                  "gridName": "ContactsSynced",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maximumContacts": {
                  "name": "maximumContacts",
                  "displayName": "MaximumContacts",
                  "gridName": "MaximumContacts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          }
        }
      },
      "Devices": {
        "discriminator": "MyBuddyGard.Devices",
        "name": "Devices",
        "displayName": null,
        "description": null,
        "isModel": false,
        "type": null,
        "baseTypeData": null,
        "properties": {},
        "Shenzen": {
          "discriminator": "MyBuddyGard.Devices.Shenzen",
          "name": "Shenzen",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "Models": {
            "discriminator": "MyBuddyGard.Devices.Shenzen.Models",
            "name": "Models",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Pendant": {
              "discriminator": "MyBuddyGard.Devices.Shenzen.Models.Pendant",
              "name": "Pendant",
              "displayName": "Pendant",
              "description": "",
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Devices.Shenzen.Models.Pendant",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Devices.Shenzen.Models.Pendant, MyBuddyGard.Devices.Shenzen, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "contacts": {
                  "name": "contacts",
                  "displayName": "Contacts",
                  "gridName": "Contacts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Contact",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "phoneNumber": {
                  "name": "phoneNumber",
                  "displayName": "PhoneNumber",
                  "gridName": "PhoneNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "trackerId": {
                  "name": "trackerId",
                  "displayName": "TrackerId",
                  "gridName": "TrackerId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "contactsSynced": {
                  "name": "contactsSynced",
                  "displayName": "ContactsSynced",
                  "gridName": "ContactsSynced",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maximumContacts": {
                  "name": "maximumContacts",
                  "displayName": "MaximumContacts",
                  "gridName": "MaximumContacts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "imei": {
                  "name": "imei",
                  "displayName": "Imei",
                  "gridName": "Imei",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "rolloverStartPeriod": {
                  "name": "rolloverStartPeriod",
                  "displayName": "RolloverStartPeriod",
                  "gridName": "RolloverStartPeriod",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "rolloverEndPeriod": {
                  "name": "rolloverEndPeriod",
                  "displayName": "RolloverEndPeriod",
                  "gridName": "RolloverEndPeriod",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "keepAlive": {
                  "name": "keepAlive",
                  "displayName": "KeepAlive",
                  "gridName": "KeepAlive",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "monitorPhoneNumber": {
                  "name": "monitorPhoneNumber",
                  "displayName": "MonitorPhoneNumber",
                  "gridName": "MonitorPhoneNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceType": {
                  "name": "deviceType",
                  "displayName": "DeviceType",
                  "gridName": "DeviceType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "updateRate": {
                  "name": "updateRate",
                  "displayName": "UpdateRate",
                  "gridName": "UpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "minUpdateRate": {
                  "name": "minUpdateRate",
                  "displayName": "MinUpdateRate",
                  "gridName": "MinUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maxUpdateRate": {
                  "name": "maxUpdateRate",
                  "displayName": "MaxUpdateRate",
                  "gridName": "MaxUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "offlineTimeout": {
                  "name": "offlineTimeout",
                  "displayName": "OfflineTimeout",
                  "gridName": "OfflineTimeout",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "features": {
                  "name": "features",
                  "displayName": "Features",
                  "gridName": "Features",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IDictionary`2[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.FeatureCapabilities",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "ShenzenWatch": {
              "discriminator": "MyBuddyGard.Devices.Shenzen.Models.ShenzenWatch",
              "name": "ShenzenWatch",
              "displayName": "Watch",
              "description": "",
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Devices.Shenzen.Models.ShenzenWatch",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Devices.Shenzen.Models.ShenzenWatch, MyBuddyGard.Devices.Shenzen, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "contacts": {
                  "name": "contacts",
                  "displayName": "Contacts",
                  "gridName": "Contacts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.Contact",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.Contact, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "phoneNumber": {
                  "name": "phoneNumber",
                  "displayName": "PhoneNumber",
                  "gridName": "PhoneNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "trackerId": {
                  "name": "trackerId",
                  "displayName": "TrackerId",
                  "gridName": "TrackerId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "contactsSynced": {
                  "name": "contactsSynced",
                  "displayName": "ContactsSynced",
                  "gridName": "ContactsSynced",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maximumContacts": {
                  "name": "maximumContacts",
                  "displayName": "MaximumContacts",
                  "gridName": "MaximumContacts",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "imei": {
                  "name": "imei",
                  "displayName": "Imei",
                  "gridName": "Imei",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "rolloverStartPeriod": {
                  "name": "rolloverStartPeriod",
                  "displayName": "RolloverStartPeriod",
                  "gridName": "RolloverStartPeriod",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "rolloverEndPeriod": {
                  "name": "rolloverEndPeriod",
                  "displayName": "RolloverEndPeriod",
                  "gridName": "RolloverEndPeriod",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "keepAlive": {
                  "name": "keepAlive",
                  "displayName": "KeepAlive",
                  "gridName": "KeepAlive",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "monitorPhoneNumber": {
                  "name": "monitorPhoneNumber",
                  "displayName": "MonitorPhoneNumber",
                  "gridName": "MonitorPhoneNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceType": {
                  "name": "deviceType",
                  "displayName": "DeviceType",
                  "gridName": "DeviceType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "updateRate": {
                  "name": "updateRate",
                  "displayName": "UpdateRate",
                  "gridName": "UpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "minUpdateRate": {
                  "name": "minUpdateRate",
                  "displayName": "MinUpdateRate",
                  "gridName": "MinUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maxUpdateRate": {
                  "name": "maxUpdateRate",
                  "displayName": "MaxUpdateRate",
                  "gridName": "MaxUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "offlineTimeout": {
                  "name": "offlineTimeout",
                  "displayName": "OfflineTimeout",
                  "gridName": "OfflineTimeout",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "features": {
                  "name": "features",
                  "displayName": "Features",
                  "gridName": "Features",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IDictionary`2[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.FeatureCapabilities",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          }
        },
        "Resiont": {
          "discriminator": "MyBuddyGard.Devices.Resiont",
          "name": "Resiont",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "Models": {
            "discriminator": "MyBuddyGard.Devices.Resiont.Models",
            "name": "Models",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Resiont": {
              "discriminator": "MyBuddyGard.Devices.Resiont.Models.Resiont",
              "name": "Resiont",
              "displayName": "Vehicle tracker",
              "description": "",
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Devices.Resiont.Models.Resiont",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Devices.Resiont.Models.Resiont, MyBuddyGard.Devices.Resiont, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "trackerId": {
                  "name": "trackerId",
                  "displayName": "TrackerId",
                  "gridName": "TrackerId",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "phoneNumber": {
                  "name": "phoneNumber",
                  "displayName": "PhoneNumber",
                  "gridName": "PhoneNumber",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "imei": {
                  "name": "imei",
                  "displayName": "Imei",
                  "gridName": "Imei",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deviceType": {
                  "name": "deviceType",
                  "displayName": "DeviceType",
                  "gridName": "DeviceType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "updateRate": {
                  "name": "updateRate",
                  "displayName": "UpdateRate",
                  "gridName": "UpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "minUpdateRate": {
                  "name": "minUpdateRate",
                  "displayName": "MinUpdateRate",
                  "gridName": "MinUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maxUpdateRate": {
                  "name": "maxUpdateRate",
                  "displayName": "MaxUpdateRate",
                  "gridName": "MaxUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "offlineTimeout": {
                  "name": "offlineTimeout",
                  "displayName": "OfflineTimeout",
                  "gridName": "OfflineTimeout",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "features": {
                  "name": "features",
                  "displayName": "Features",
                  "gridName": "Features",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IDictionary`2[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.FeatureCapabilities",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          }
        },
        "Mobile": {
          "discriminator": "MyBuddyGard.Devices.Mobile",
          "name": "Mobile",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "Models": {
            "discriminator": "MyBuddyGard.Devices.Mobile.Models",
            "name": "Models",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "AndroidMobile": {
              "discriminator": "MyBuddyGard.Devices.Mobile.Models.AndroidMobile",
              "name": "AndroidMobile",
              "displayName": "AndroidMobile",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Devices.Mobile.Models.AndroidMobile",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Devices.Mobile.Models.AndroidMobile, MyBuddyGard.Devices.Mobile, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "deviceType": {
                  "name": "deviceType",
                  "displayName": "DeviceType",
                  "gridName": "DeviceType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "updateRate": {
                  "name": "updateRate",
                  "displayName": "UpdateRate",
                  "gridName": "UpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "minUpdateRate": {
                  "name": "minUpdateRate",
                  "displayName": "MinUpdateRate",
                  "gridName": "MinUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maxUpdateRate": {
                  "name": "maxUpdateRate",
                  "displayName": "MaxUpdateRate",
                  "gridName": "MaxUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "offlineTimeout": {
                  "name": "offlineTimeout",
                  "displayName": "OfflineTimeout",
                  "gridName": "OfflineTimeout",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "features": {
                  "name": "features",
                  "displayName": "Features",
                  "gridName": "Features",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IDictionary`2[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.FeatureCapabilities",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "IosApp": {
              "discriminator": "MyBuddyGard.Devices.Mobile.Models.IosApp",
              "name": "IosApp",
              "displayName": "IosApp",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Devices.Mobile.Models.IosApp",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Devices.Mobile.Models.IosApp, MyBuddyGard.Devices.Mobile, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "deviceType": {
                  "name": "deviceType",
                  "displayName": "DeviceType",
                  "gridName": "DeviceType",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "discriminator": {
                  "name": "discriminator",
                  "displayName": "Discriminator",
                  "gridName": "Discriminator",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "updateRate": {
                  "name": "updateRate",
                  "displayName": "UpdateRate",
                  "gridName": "UpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "minUpdateRate": {
                  "name": "minUpdateRate",
                  "displayName": "MinUpdateRate",
                  "gridName": "MinUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "maxUpdateRate": {
                  "name": "maxUpdateRate",
                  "displayName": "MaxUpdateRate",
                  "gridName": "MaxUpdateRate",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "offlineTimeout": {
                  "name": "offlineTimeout",
                  "displayName": "OfflineTimeout",
                  "gridName": "OfflineTimeout",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": "System.Int32",
                  "systemType": "System.Nullable`1[[System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int32",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": true,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "features": {
                  "name": "features",
                  "displayName": "Features",
                  "gridName": "Features",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IDictionary`2[[MyBuddyGard.Domain.Enumerations.DeviceFeature, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null],[MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Domain.Models.FeatureCapabilities",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": true,
                    "isOData": false,
                    "type": "MyBuddyGard.Domain.Models.FeatureCapabilities, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "id": {
                  "name": "id",
                  "displayName": "Id",
                  "gridName": "Id",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Guid",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "deleted": {
                  "name": "deleted",
                  "displayName": "Deleted",
                  "gridName": "Deleted",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "version": {
                  "name": "version",
                  "displayName": "Version",
                  "gridName": "Version",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": true,
                  "hideInGrid": true,
                  "hideInForm": true,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Int64",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          }
        }
      },
      "Api": {
        "discriminator": "MyBuddyGard.Api",
        "name": "Api",
        "displayName": null,
        "description": null,
        "isModel": false,
        "type": null,
        "baseTypeData": null,
        "properties": {},
        "Config": {
          "discriminator": "MyBuddyGard.Api.Config",
          "name": "Config",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "Trackmap": {
            "discriminator": "MyBuddyGard.Api.Config.Trackmap",
            "name": "Trackmap",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "ITrackmapConfig": {
              "discriminator": "MyBuddyGard.Api.Config.Trackmap.ITrackmapConfig",
              "name": "ITrackmapConfig",
              "displayName": "ITrackmapConfig",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Api.Config.Trackmap.ITrackmapConfig",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Api.Config.Trackmap.ITrackmapConfig, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "stripe": {
                  "name": "stripe",
                  "displayName": "Stripe",
                  "gridName": "Stripe",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Api.Config.Trackmap.TrackmapStripeConfig, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Api.Config.Trackmap.TrackmapStripeConfig",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Api.Config.Trackmap.TrackmapStripeConfig, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "TrackmapStripeConfig": {
              "discriminator": "MyBuddyGard.Api.Config.Trackmap.TrackmapStripeConfig",
              "name": "TrackmapStripeConfig",
              "displayName": "TrackmapStripeConfig",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Api.Config.Trackmap.TrackmapStripeConfig",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Api.Config.Trackmap.TrackmapStripeConfig, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "apiKey": {
                  "name": "apiKey",
                  "displayName": "ApiKey",
                  "gridName": "ApiKey",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          }
        },
        "Models": {
          "discriminator": "MyBuddyGard.Api.Models",
          "name": "Models",
          "displayName": null,
          "description": null,
          "isModel": false,
          "type": null,
          "baseTypeData": null,
          "properties": {},
          "Notification": {
            "discriminator": "MyBuddyGard.Api.Models.Notification",
            "name": "Notification",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "Groups": {
              "discriminator": "MyBuddyGard.Api.Models.Notification.Groups",
              "name": "Groups",
              "displayName": null,
              "description": null,
              "isModel": false,
              "type": null,
              "baseTypeData": null,
              "properties": {},
              "Account": {
                "discriminator": "MyBuddyGard.Api.Models.Notification.Groups.Account",
                "name": "Account",
                "displayName": "Account",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Api.Models.Notification.Groups.Account",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Api.Models.Notification.Groups.Account, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "slug": {
                    "name": "slug",
                    "displayName": "Slug",
                    "gridName": "Slug",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  },
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "User": {
                "discriminator": "MyBuddyGard.Api.Models.Notification.Groups.User",
                "name": "User",
                "displayName": "User",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Api.Models.Notification.Groups.User",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Api.Models.Notification.Groups.User, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              },
              "Group": {
                "discriminator": "MyBuddyGard.Api.Models.Notification.Groups.Group",
                "name": "Group",
                "displayName": "Group",
                "description": null,
                "isModel": true,
                "type": null,
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Api.Models.Notification.Groups.Group",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Api.Models.Notification.Groups.Group, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "properties": {
                  "discriminator": {
                    "name": "discriminator",
                    "displayName": "Discriminator",
                    "gridName": "Discriminator",
                    "editorHint": null,
                    "validators": [],
                    "hideInList": false,
                    "hideInGrid": false,
                    "hideInForm": false,
                    "listOrder": null,
                    "gridOrder": null,
                    "formOrder": null,
                    "description": null,
                    "helpText": null,
                    "prompt": null,
                    "formatter": null,
                    "dataType": null,
                    "enum": null,
                    "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                    "baseTypeData": {
                      "baseType": "System.String",
                      "isNative": false,
                      "isArray": false,
                      "isNullable": false,
                      "isIndexed": false,
                      "isOData": false,
                      "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                    },
                    "hyperlinkUrlTemplate": null,
                    "aggregationHint": null,
                    "import": null,
                    "readOnly": false,
                    "sortable": false,
                    "optional": false
                  }
                }
              }
            },
            "ConnectionRequest": {
              "discriminator": "MyBuddyGard.Api.Models.Notification.ConnectionRequest",
              "name": "ConnectionRequest",
              "displayName": "ConnectionRequest",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Api.Models.Notification.ConnectionRequest",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Api.Models.Notification.ConnectionRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "token": {
                  "name": "token",
                  "displayName": "Token",
                  "gridName": "Token",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "groups": {
                  "name": "groups",
                  "displayName": "Groups",
                  "gridName": "Groups",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IEnumerable`1[[MyBuddyGard.Api.Models.Notification.Groups.Group, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Api.Models.Notification.Groups.Group",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Api.Models.Notification.Groups.Group, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "CancelAccountPaymentsSetupRequest": {
            "discriminator": "MyBuddyGard.Api.Models.CancelAccountPaymentsSetupRequest",
            "name": "CancelAccountPaymentsSetupRequest",
            "displayName": "CancelAccountPaymentsSetupRequest",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Api.Models.CancelAccountPaymentsSetupRequest",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Api.Models.CancelAccountPaymentsSetupRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "reason": {
                "name": "reason",
                "displayName": "Reason",
                "gridName": "Reason",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "SendCommand": {
            "discriminator": "MyBuddyGard.Api.Models.SendCommand",
            "name": "SendCommand",
            "displayName": "SendCommand",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Api.Models.SendCommand",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Api.Models.SendCommand, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "command": {
                "name": "command",
                "displayName": "Command",
                "gridName": "Command",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "MyBuddyGard.Domain.Models.DeviceCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.DeviceCommand",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.DeviceCommand, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "sms": {
                "name": "sms",
                "displayName": "Sms",
                "gridName": "Sms",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "queue": {
                "name": "queue",
                "displayName": "Queue",
                "gridName": "Queue",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "DeleteCommandRequest": {
            "discriminator": "MyBuddyGard.Api.Models.DeleteCommandRequest",
            "name": "DeleteCommandRequest",
            "displayName": "DeleteCommandRequest",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Api.Models.DeleteCommandRequest",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Api.Models.DeleteCommandRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "deviceType": {
                "name": "deviceType",
                "displayName": "DeviceType",
                "gridName": "DeviceType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "trackerId": {
                "name": "trackerId",
                "displayName": "TrackerId",
                "gridName": "TrackerId",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "identifiers": {
                "name": "identifiers",
                "displayName": "Identifiers",
                "gridName": "Identifiers",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.IList`1[[System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "Pairing": {
            "discriminator": "MyBuddyGard.Api.Models.Pairing",
            "name": "Pairing",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "DeviceTokenValidationResponse": {
              "discriminator": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResponse",
              "name": "DeviceTokenValidationResponse",
              "displayName": "DeviceTokenValidationResponse",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResponse",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResponse, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "result": {
                  "name": "result",
                  "displayName": "Result",
                  "gridName": "Result",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResult, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResult",
                    "isNative": false,
                    "isArray": true,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResult, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "DeviceTokenValidationResult": {
              "discriminator": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResult",
              "name": "DeviceTokenValidationResult",
              "displayName": "DeviceTokenValidationResult",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResult",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResult, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "token": {
                  "name": "token",
                  "displayName": "Token",
                  "gridName": "Token",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "valid": {
                  "name": "valid",
                  "displayName": "Valid",
                  "gridName": "Valid",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "DeviceLocationAndToken": {
            "discriminator": "MyBuddyGard.Api.Models.DeviceLocationAndToken",
            "name": "DeviceLocationAndToken",
            "displayName": "DeviceLocationAndToken",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Api.Models.DeviceLocationAndToken",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Api.Models.DeviceLocationAndToken, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "deviceLocation": {
                "name": "deviceLocation",
                "displayName": "DeviceLocation",
                "gridName": "DeviceLocation",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "MyBuddyGard.Domain.Models.DeviceLocation, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.DeviceLocation",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.DeviceLocation, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "token": {
                "name": "token",
                "displayName": "Token",
                "gridName": "Token",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "ResetPassword": {
            "discriminator": "MyBuddyGard.Api.Models.ResetPassword",
            "name": "ResetPassword",
            "displayName": null,
            "description": null,
            "isModel": false,
            "type": null,
            "baseTypeData": null,
            "properties": {},
            "ResetPasswordResponse": {
              "discriminator": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordResponse",
              "name": "ResetPasswordResponse",
              "displayName": "ResetPasswordResponse",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordResponse",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordResponse, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "request": {
                  "name": "request",
                  "displayName": "Request",
                  "gridName": "Request",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                  "baseTypeData": {
                    "baseType": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "allowed": {
                  "name": "allowed",
                  "displayName": "Allowed",
                  "gridName": "Allowed",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.Boolean",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                },
                "message": {
                  "name": "message",
                  "displayName": "Message",
                  "gridName": "Message",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            },
            "ResetPasswordRequest": {
              "discriminator": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest",
              "name": "ResetPasswordRequest",
              "displayName": "ResetPasswordRequest",
              "description": null,
              "isModel": true,
              "type": null,
              "baseTypeData": {
                "baseType": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest",
                "isNative": false,
                "isArray": false,
                "isNullable": false,
                "isIndexed": false,
                "isOData": false,
                "type": "MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
              },
              "properties": {
                "email": {
                  "name": "email",
                  "displayName": "Email",
                  "gridName": "Email",
                  "editorHint": null,
                  "validators": [],
                  "hideInList": false,
                  "hideInGrid": false,
                  "hideInForm": false,
                  "listOrder": null,
                  "gridOrder": null,
                  "formOrder": null,
                  "description": null,
                  "helpText": null,
                  "prompt": null,
                  "formatter": null,
                  "dataType": null,
                  "enum": null,
                  "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                  "baseTypeData": {
                    "baseType": "System.String",
                    "isNative": false,
                    "isArray": false,
                    "isNullable": false,
                    "isIndexed": false,
                    "isOData": false,
                    "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                  },
                  "hyperlinkUrlTemplate": null,
                  "aggregationHint": null,
                  "import": null,
                  "readOnly": false,
                  "sortable": false,
                  "optional": false
                }
              }
            }
          },
          "SavePlan": {
            "discriminator": "MyBuddyGard.Api.Models.SavePlan",
            "name": "SavePlan",
            "displayName": "SavePlan",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Api.Models.SavePlan",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Api.Models.SavePlan, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "planRenewals": {
                "name": "planRenewals",
                "displayName": "PlanRenewals",
                "gridName": "PlanRenewals",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.PlanRenewal, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Plans.PlanRenewal",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Plans.PlanRenewal, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "slug": {
                "name": "slug",
                "displayName": "Slug",
                "gridName": "Slug",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "name": {
                "name": "name",
                "displayName": "Name",
                "gridName": "Name",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "description": {
                "name": "description",
                "displayName": "Description",
                "gridName": "Description",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "periodLength": {
                "name": "periodLength",
                "displayName": "PeriodLength",
                "gridName": "PeriodLength",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "periodType": {
                "name": "periodType",
                "displayName": "PeriodType",
                "gridName": "PeriodType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "defaultDeviceSetting": {
                "name": "defaultDeviceSetting",
                "displayName": "DefaultDeviceSetting",
                "gridName": "DefaultDeviceSetting",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Plans.PlanDeviceDefaultSetting, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "defaultPlanRenewal": {
                "name": "defaultPlanRenewal",
                "displayName": "DefaultPlanRenewal",
                "gridName": "DefaultPlanRenewal",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "System.Guid",
                "systemType": "System.Nullable`1[[System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": true,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deviceTypes": {
                "name": "deviceTypes",
                "displayName": "DeviceTypes",
                "gridName": "DeviceTypes",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Collections.Generic.IList`1[[MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null]], System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction",
                  "isNative": false,
                  "isArray": true,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Plans.DeviceSettingsRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "planRestriction": {
                "name": "planRestriction",
                "displayName": "PlanRestriction",
                "gridName": "PlanRestriction",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "MyBuddyGard.Domain.Models.Plans.PlanRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Models.Plans.PlanRestriction",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Models.Plans.PlanRestriction, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deleted": {
                "name": "deleted",
                "displayName": "Deleted",
                "gridName": "Deleted",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "version": {
                "name": "version",
                "displayName": "Version",
                "gridName": "Version",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int64",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          },
          "SavePlanRenewal": {
            "discriminator": "MyBuddyGard.Api.Models.SavePlanRenewal",
            "name": "SavePlanRenewal",
            "displayName": "SavePlanRenewal",
            "description": null,
            "isModel": true,
            "type": null,
            "baseTypeData": {
              "baseType": "MyBuddyGard.Api.Models.SavePlanRenewal",
              "isNative": false,
              "isArray": false,
              "isNullable": false,
              "isIndexed": false,
              "isOData": false,
              "type": "MyBuddyGard.Api.Models.SavePlanRenewal, MyBuddyGard.Api, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
            },
            "properties": {
              "isDefault": {
                "name": "isDefault",
                "displayName": "IsDefault",
                "gridName": "IsDefault",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "name": {
                "name": "name",
                "displayName": "Name",
                "gridName": "Name",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.String",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "amount": {
                "name": "amount",
                "displayName": "Amount",
                "gridName": "Amount",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Decimal",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Decimal, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "periodLength": {
                "name": "periodLength",
                "displayName": "PeriodLength",
                "gridName": "PeriodLength",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int32",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "periodType": {
                "name": "periodType",
                "displayName": "PeriodType",
                "gridName": "PeriodType",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": "MyBuddyGard.Domain.Enumerations.PeriodType",
                "systemType": "MyBuddyGard.Domain.Enumerations.PeriodType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null",
                "baseTypeData": {
                  "baseType": "MyBuddyGard.Domain.Enumerations.PeriodType",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "MyBuddyGard.Domain.Enumerations.PeriodType, MyBuddyGard.Domain, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "adminOnly": {
                "name": "adminOnly",
                "displayName": "AdminOnly",
                "gridName": "AdminOnly",
                "editorHint": null,
                "validators": [],
                "hideInList": false,
                "hideInGrid": false,
                "hideInForm": false,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "id": {
                "name": "id",
                "displayName": "Id",
                "gridName": "Id",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Guid",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Guid, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "deleted": {
                "name": "deleted",
                "displayName": "Deleted",
                "gridName": "Deleted",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Boolean",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Boolean, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              },
              "version": {
                "name": "version",
                "displayName": "Version",
                "gridName": "Version",
                "editorHint": null,
                "validators": [],
                "hideInList": true,
                "hideInGrid": true,
                "hideInForm": true,
                "listOrder": null,
                "gridOrder": null,
                "formOrder": null,
                "description": null,
                "helpText": null,
                "prompt": null,
                "formatter": null,
                "dataType": null,
                "enum": null,
                "systemType": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "baseTypeData": {
                  "baseType": "System.Int64",
                  "isNative": false,
                  "isArray": false,
                  "isNullable": false,
                  "isIndexed": false,
                  "isOData": false,
                  "type": "System.Int64, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e"
                },
                "hyperlinkUrlTemplate": null,
                "aggregationHint": null,
                "import": null,
                "readOnly": false,
                "sortable": false,
                "optional": false
              }
            }
          }
        }
      }
    }
  },
  "enums": {
    "Intellis": {
      "discriminator": "Intellis",
      "name": "Intellis",
      "Domain": {
        "discriminator": "Intellis.Domain",
        "name": "Domain",
        "Security": {
          "discriminator": "Intellis.Domain.Security",
          "name": "Security",
          "PermissionOptions": {
            "discriminator": "Intellis.Domain.Security.PermissionOptions",
            "name": "PermissionOptions",
            "displayName": null,
            "selectorText": null,
            "editorHint": null,
            "values": {
              "none": {
                "discriminator": null,
                "name": "None",
                "value": 0,
                "displayName": "None"
              },
              "get": {
                "discriminator": null,
                "name": "Get",
                "value": 1,
                "displayName": "Get"
              },
              "add": {
                "discriminator": null,
                "name": "Add",
                "value": 2,
                "displayName": "Add"
              },
              "update": {
                "discriminator": null,
                "name": "Update",
                "value": 4,
                "displayName": "Update"
              },
              "delete": {
                "discriminator": null,
                "name": "Delete",
                "value": 8,
                "displayName": "Delete"
              },
              "search": {
                "discriminator": null,
                "name": "Search",
                "value": 16,
                "displayName": "Search"
              },
              "query": {
                "discriminator": null,
                "name": "Query",
                "value": 32,
                "displayName": "Query"
              },
              "all": {
                "discriminator": null,
                "name": "All",
                "value": 255,
                "displayName": "All"
              }
            }
          }
        }
      },
      "Aws": {
        "discriminator": "Intellis.Aws",
        "name": "Aws",
        "Core": {
          "discriminator": "Intellis.Aws.Core",
          "name": "Core",
          "Cognito": {
            "discriminator": "Intellis.Aws.Core.Cognito",
            "name": "Cognito",
            "Users": {
              "discriminator": "Intellis.Aws.Core.Cognito.Users",
              "name": "Users",
              "UserSearchProperty": {
                "discriminator": "Intellis.Aws.Core.Cognito.Users.UserSearchProperty",
                "name": "UserSearchProperty",
                "displayName": null,
                "selectorText": null,
                "editorHint": null,
                "values": {
                  "allUsers": {
                    "discriminator": null,
                    "name": "AllUsers",
                    "value": 0,
                    "displayName": "All users"
                  },
                  "userName": {
                    "discriminator": null,
                    "name": "UserName",
                    "value": 1,
                    "displayName": "User name"
                  },
                  "emailAddress": {
                    "discriminator": null,
                    "name": "EmailAddress",
                    "value": 2,
                    "displayName": "Email address"
                  },
                  "phoneNumber": {
                    "discriminator": null,
                    "name": "PhoneNumber",
                    "value": 3,
                    "displayName": "Phone number"
                  },
                  "firstName": {
                    "discriminator": null,
                    "name": "FirstName",
                    "value": 4,
                    "displayName": "First name"
                  },
                  "lastName": {
                    "discriminator": null,
                    "name": "LastName",
                    "value": 5,
                    "displayName": "Last name"
                  }
                }
              }
            }
          }
        }
      }
    },
    "System": {
      "discriminator": "System",
      "name": "System",
      "Net": {
        "discriminator": "System.Net",
        "name": "Net",
        "HttpStatusCode": {
          "discriminator": "System.Net.HttpStatusCode",
          "name": "HttpStatusCode",
          "displayName": null,
          "selectorText": null,
          "editorHint": null,
          "values": {
            "continue": {
              "discriminator": null,
              "name": "Continue",
              "value": 100,
              "displayName": "Continue"
            },
            "switchingProtocols": {
              "discriminator": null,
              "name": "SwitchingProtocols",
              "value": 101,
              "displayName": "SwitchingProtocols"
            },
            "processing": {
              "discriminator": null,
              "name": "Processing",
              "value": 102,
              "displayName": "Processing"
            },
            "earlyHints": {
              "discriminator": null,
              "name": "EarlyHints",
              "value": 103,
              "displayName": "EarlyHints"
            },
            "oK": {
              "discriminator": null,
              "name": "OK",
              "value": 200,
              "displayName": "OK"
            },
            "created": {
              "discriminator": null,
              "name": "Created",
              "value": 201,
              "displayName": "Created"
            },
            "accepted": {
              "discriminator": null,
              "name": "Accepted",
              "value": 202,
              "displayName": "Accepted"
            },
            "nonAuthoritativeInformation": {
              "discriminator": null,
              "name": "NonAuthoritativeInformation",
              "value": 203,
              "displayName": "NonAuthoritativeInformation"
            },
            "noContent": {
              "discriminator": null,
              "name": "NoContent",
              "value": 204,
              "displayName": "NoContent"
            },
            "resetContent": {
              "discriminator": null,
              "name": "ResetContent",
              "value": 205,
              "displayName": "ResetContent"
            },
            "partialContent": {
              "discriminator": null,
              "name": "PartialContent",
              "value": 206,
              "displayName": "PartialContent"
            },
            "multiStatus": {
              "discriminator": null,
              "name": "MultiStatus",
              "value": 207,
              "displayName": "MultiStatus"
            },
            "alreadyReported": {
              "discriminator": null,
              "name": "AlreadyReported",
              "value": 208,
              "displayName": "AlreadyReported"
            },
            "iMUsed": {
              "discriminator": null,
              "name": "IMUsed",
              "value": 226,
              "displayName": "IMUsed"
            },
            "ambiguous": {
              "discriminator": null,
              "name": "Ambiguous",
              "value": 300,
              "displayName": "Ambiguous"
            },
            "moved": {
              "discriminator": null,
              "name": "Moved",
              "value": 301,
              "displayName": "Moved"
            },
            "redirect": {
              "discriminator": null,
              "name": "Redirect",
              "value": 302,
              "displayName": "Redirect"
            },
            "redirectMethod": {
              "discriminator": null,
              "name": "RedirectMethod",
              "value": 303,
              "displayName": "RedirectMethod"
            },
            "notModified": {
              "discriminator": null,
              "name": "NotModified",
              "value": 304,
              "displayName": "NotModified"
            },
            "useProxy": {
              "discriminator": null,
              "name": "UseProxy",
              "value": 305,
              "displayName": "UseProxy"
            },
            "unused": {
              "discriminator": null,
              "name": "Unused",
              "value": 306,
              "displayName": "Unused"
            },
            "temporaryRedirect": {
              "discriminator": null,
              "name": "TemporaryRedirect",
              "value": 307,
              "displayName": "TemporaryRedirect"
            },
            "permanentRedirect": {
              "discriminator": null,
              "name": "PermanentRedirect",
              "value": 308,
              "displayName": "PermanentRedirect"
            },
            "badRequest": {
              "discriminator": null,
              "name": "BadRequest",
              "value": 400,
              "displayName": "BadRequest"
            },
            "unauthorized": {
              "discriminator": null,
              "name": "Unauthorized",
              "value": 401,
              "displayName": "Unauthorized"
            },
            "paymentRequired": {
              "discriminator": null,
              "name": "PaymentRequired",
              "value": 402,
              "displayName": "PaymentRequired"
            },
            "forbidden": {
              "discriminator": null,
              "name": "Forbidden",
              "value": 403,
              "displayName": "Forbidden"
            },
            "notFound": {
              "discriminator": null,
              "name": "NotFound",
              "value": 404,
              "displayName": "NotFound"
            },
            "methodNotAllowed": {
              "discriminator": null,
              "name": "MethodNotAllowed",
              "value": 405,
              "displayName": "MethodNotAllowed"
            },
            "notAcceptable": {
              "discriminator": null,
              "name": "NotAcceptable",
              "value": 406,
              "displayName": "NotAcceptable"
            },
            "proxyAuthenticationRequired": {
              "discriminator": null,
              "name": "ProxyAuthenticationRequired",
              "value": 407,
              "displayName": "ProxyAuthenticationRequired"
            },
            "requestTimeout": {
              "discriminator": null,
              "name": "RequestTimeout",
              "value": 408,
              "displayName": "RequestTimeout"
            },
            "conflict": {
              "discriminator": null,
              "name": "Conflict",
              "value": 409,
              "displayName": "Conflict"
            },
            "gone": {
              "discriminator": null,
              "name": "Gone",
              "value": 410,
              "displayName": "Gone"
            },
            "lengthRequired": {
              "discriminator": null,
              "name": "LengthRequired",
              "value": 411,
              "displayName": "LengthRequired"
            },
            "preconditionFailed": {
              "discriminator": null,
              "name": "PreconditionFailed",
              "value": 412,
              "displayName": "PreconditionFailed"
            },
            "requestEntityTooLarge": {
              "discriminator": null,
              "name": "RequestEntityTooLarge",
              "value": 413,
              "displayName": "RequestEntityTooLarge"
            },
            "requestUriTooLong": {
              "discriminator": null,
              "name": "RequestUriTooLong",
              "value": 414,
              "displayName": "RequestUriTooLong"
            },
            "unsupportedMediaType": {
              "discriminator": null,
              "name": "UnsupportedMediaType",
              "value": 415,
              "displayName": "UnsupportedMediaType"
            },
            "requestedRangeNotSatisfiable": {
              "discriminator": null,
              "name": "RequestedRangeNotSatisfiable",
              "value": 416,
              "displayName": "RequestedRangeNotSatisfiable"
            },
            "expectationFailed": {
              "discriminator": null,
              "name": "ExpectationFailed",
              "value": 417,
              "displayName": "ExpectationFailed"
            },
            "misdirectedRequest": {
              "discriminator": null,
              "name": "MisdirectedRequest",
              "value": 421,
              "displayName": "MisdirectedRequest"
            },
            "unprocessableEntity": {
              "discriminator": null,
              "name": "UnprocessableEntity",
              "value": 422,
              "displayName": "UnprocessableEntity"
            },
            "locked": {
              "discriminator": null,
              "name": "Locked",
              "value": 423,
              "displayName": "Locked"
            },
            "failedDependency": {
              "discriminator": null,
              "name": "FailedDependency",
              "value": 424,
              "displayName": "FailedDependency"
            },
            "upgradeRequired": {
              "discriminator": null,
              "name": "UpgradeRequired",
              "value": 426,
              "displayName": "UpgradeRequired"
            },
            "preconditionRequired": {
              "discriminator": null,
              "name": "PreconditionRequired",
              "value": 428,
              "displayName": "PreconditionRequired"
            },
            "tooManyRequests": {
              "discriminator": null,
              "name": "TooManyRequests",
              "value": 429,
              "displayName": "TooManyRequests"
            },
            "requestHeaderFieldsTooLarge": {
              "discriminator": null,
              "name": "RequestHeaderFieldsTooLarge",
              "value": 431,
              "displayName": "RequestHeaderFieldsTooLarge"
            },
            "unavailableForLegalReasons": {
              "discriminator": null,
              "name": "UnavailableForLegalReasons",
              "value": 451,
              "displayName": "UnavailableForLegalReasons"
            },
            "internalServerError": {
              "discriminator": null,
              "name": "InternalServerError",
              "value": 500,
              "displayName": "InternalServerError"
            },
            "notImplemented": {
              "discriminator": null,
              "name": "NotImplemented",
              "value": 501,
              "displayName": "NotImplemented"
            },
            "badGateway": {
              "discriminator": null,
              "name": "BadGateway",
              "value": 502,
              "displayName": "BadGateway"
            },
            "serviceUnavailable": {
              "discriminator": null,
              "name": "ServiceUnavailable",
              "value": 503,
              "displayName": "ServiceUnavailable"
            },
            "gatewayTimeout": {
              "discriminator": null,
              "name": "GatewayTimeout",
              "value": 504,
              "displayName": "GatewayTimeout"
            },
            "httpVersionNotSupported": {
              "discriminator": null,
              "name": "HttpVersionNotSupported",
              "value": 505,
              "displayName": "HttpVersionNotSupported"
            },
            "variantAlsoNegotiates": {
              "discriminator": null,
              "name": "VariantAlsoNegotiates",
              "value": 506,
              "displayName": "VariantAlsoNegotiates"
            },
            "insufficientStorage": {
              "discriminator": null,
              "name": "InsufficientStorage",
              "value": 507,
              "displayName": "InsufficientStorage"
            },
            "loopDetected": {
              "discriminator": null,
              "name": "LoopDetected",
              "value": 508,
              "displayName": "LoopDetected"
            },
            "notExtended": {
              "discriminator": null,
              "name": "NotExtended",
              "value": 510,
              "displayName": "NotExtended"
            },
            "networkAuthenticationRequired": {
              "discriminator": null,
              "name": "NetworkAuthenticationRequired",
              "value": 511,
              "displayName": "NetworkAuthenticationRequired"
            }
          }
        }
      }
    },
    "MyBuddyGard": {
      "discriminator": "MyBuddyGard",
      "name": "MyBuddyGard",
      "Domain": {
        "discriminator": "MyBuddyGard.Domain",
        "name": "Domain",
        "Enumerations": {
          "discriminator": "MyBuddyGard.Domain.Enumerations",
          "name": "Enumerations",
          "DeviceFeature": {
            "discriminator": "MyBuddyGard.Domain.Enumerations.DeviceFeature",
            "name": "DeviceFeature",
            "displayName": null,
            "selectorText": null,
            "editorHint": null,
            "values": {
              "immobilise": {
                "discriminator": null,
                "name": "Immobilise",
                "value": 0,
                "displayName": "Immobilise"
              },
              "find": {
                "discriminator": null,
                "name": "Find",
                "value": 1,
                "displayName": "Find"
              },
              "reset": {
                "discriminator": null,
                "name": "Reset",
                "value": 2,
                "displayName": "Reset"
              },
              "locate": {
                "discriminator": null,
                "name": "Locate",
                "value": 3,
                "displayName": "Locate"
              },
              "setupSos": {
                "discriminator": null,
                "name": "SetupSos",
                "value": 4,
                "displayName": "SetupSos"
              },
              "wifi": {
                "discriminator": null,
                "name": "Wifi",
                "value": 5,
                "displayName": "Wifi"
              },
              "sos": {
                "discriminator": null,
                "name": "Sos",
                "value": 6,
                "displayName": "Sos"
              },
              "contacts": {
                "discriminator": null,
                "name": "Contacts",
                "value": 7,
                "displayName": "Contacts"
              },
              "updateRate": {
                "discriminator": null,
                "name": "UpdateRate",
                "value": 8,
                "displayName": "UpdateRate"
              },
              "forceWakeup": {
                "discriminator": null,
                "name": "ForceWakeup",
                "value": 9,
                "displayName": "ForceWakeup"
              },
              "sleeptime": {
                "discriminator": null,
                "name": "Sleeptime",
                "value": 10,
                "displayName": "Sleeptime"
              },
              "monitor": {
                "discriminator": null,
                "name": "Monitor",
                "value": 11,
                "displayName": "Monitor"
              },
              "hostPower": {
                "discriminator": null,
                "name": "HostPower",
                "value": 12,
                "displayName": "HostPower"
              }
            }
          },
          "RadioTypes": {
            "discriminator": "MyBuddyGard.Domain.Enumerations.RadioTypes",
            "name": "RadioTypes",
            "displayName": null,
            "selectorText": null,
            "editorHint": null,
            "values": {
              "gsm": {
                "discriminator": null,
                "name": "Gsm",
                "value": 0,
                "displayName": "Gsm"
              },
              "lte": {
                "discriminator": null,
                "name": "Lte",
                "value": 1,
                "displayName": "Lte"
              },
              "cdma": {
                "discriminator": null,
                "name": "Cdma",
                "value": 2,
                "displayName": "Cdma"
              },
              "wcdma": {
                "discriminator": null,
                "name": "Wcdma",
                "value": 3,
                "displayName": "Wcdma"
              }
            }
          },
          "PaymentStatus": {
            "discriminator": "MyBuddyGard.Domain.Enumerations.PaymentStatus",
            "name": "PaymentStatus",
            "displayName": null,
            "selectorText": null,
            "editorHint": null,
            "values": {
              "unknown": {
                "discriminator": null,
                "name": "Unknown",
                "value": 0,
                "displayName": "Unknown"
              },
              "success": {
                "discriminator": null,
                "name": "Success",
                "value": 1,
                "displayName": "Success"
              },
              "noDefaultPaymentMethod": {
                "discriminator": null,
                "name": "NoDefaultPaymentMethod",
                "value": 2,
                "displayName": "NoDefaultPaymentMethod"
              },
              "cardDeclined": {
                "discriminator": null,
                "name": "CardDeclined",
                "value": 3,
                "displayName": "CardDeclined"
              },
              "cardExpired": {
                "discriminator": null,
                "name": "CardExpired",
                "value": 4,
                "displayName": "CardExpired"
              },
              "invalidPaymentType": {
                "discriminator": null,
                "name": "InvalidPaymentType",
                "value": 5,
                "displayName": "InvalidPaymentType"
              },
              "internalException": {
                "discriminator": null,
                "name": "InternalException",
                "value": 6,
                "displayName": "InternalException"
              }
            }
          },
          "Trigger": {
            "discriminator": "MyBuddyGard.Domain.Enumerations.Trigger",
            "name": "Trigger",
            "GeofenceActionType": {
              "discriminator": "MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType",
              "name": "GeofenceActionType",
              "displayName": null,
              "selectorText": null,
              "editorHint": null,
              "values": {
                "enter": {
                  "discriminator": null,
                  "name": "Enter",
                  "value": 0,
                  "displayName": "Enter"
                },
                "exit": {
                  "discriminator": null,
                  "name": "Exit",
                  "value": 1,
                  "displayName": "Exit"
                },
                "dwell": {
                  "discriminator": null,
                  "name": "Dwell",
                  "value": 2,
                  "displayName": "Dwell"
                }
              }
            },
            "DwellCondition": {
              "discriminator": "MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition",
              "name": "DwellCondition",
              "displayName": null,
              "selectorText": null,
              "editorHint": null,
              "values": {
                "inside": {
                  "discriminator": null,
                  "name": "Inside",
                  "value": 0,
                  "displayName": "Inside"
                },
                "outside": {
                  "discriminator": null,
                  "name": "Outside",
                  "value": 1,
                  "displayName": "Outside"
                }
              }
            },
            "DwellTimeUnit": {
              "discriminator": "MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit",
              "name": "DwellTimeUnit",
              "displayName": null,
              "selectorText": null,
              "editorHint": null,
              "values": {
                "seconds": {
                  "discriminator": null,
                  "name": "Seconds",
                  "value": 0,
                  "displayName": "Seconds"
                },
                "minutes": {
                  "discriminator": null,
                  "name": "Minutes",
                  "value": 1,
                  "displayName": "Minutes"
                }
              }
            },
            "PowerStatusType": {
              "discriminator": "MyBuddyGard.Domain.Enumerations.Trigger.PowerStatusType",
              "name": "PowerStatusType",
              "displayName": null,
              "selectorText": null,
              "editorHint": null,
              "values": {
                "device": {
                  "discriminator": null,
                  "name": "Device",
                  "value": 0,
                  "displayName": "Device"
                },
                "host": {
                  "discriminator": null,
                  "name": "Host",
                  "value": 1,
                  "displayName": "Host"
                }
              }
            },
            "WifiActionType": {
              "discriminator": "MyBuddyGard.Domain.Enumerations.Trigger.WifiActionType",
              "name": "WifiActionType",
              "displayName": null,
              "selectorText": null,
              "editorHint": null,
              "values": {
                "enter": {
                  "discriminator": null,
                  "name": "Enter",
                  "value": 0,
                  "displayName": "Enter"
                },
                "exit": {
                  "discriminator": null,
                  "name": "Exit",
                  "value": 1,
                  "displayName": "Exit"
                },
                "dwell": {
                  "discriminator": null,
                  "name": "Dwell",
                  "value": 2,
                  "displayName": "Dwell"
                }
              }
            }
          },
          "PeriodType": {
            "discriminator": "MyBuddyGard.Domain.Enumerations.PeriodType",
            "name": "PeriodType",
            "displayName": null,
            "selectorText": null,
            "editorHint": null,
            "values": {
              "day": {
                "discriminator": null,
                "name": "Day",
                "value": 0,
                "displayName": "Day"
              },
              "week": {
                "discriminator": null,
                "name": "Week",
                "value": 1,
                "displayName": "Week"
              },
              "month": {
                "discriminator": null,
                "name": "Month",
                "value": 2,
                "displayName": "Month"
              },
              "year": {
                "discriminator": null,
                "name": "Year",
                "value": 3,
                "displayName": "Year"
              }
            }
          },
          "AccountStatus": {
            "discriminator": "MyBuddyGard.Domain.Enumerations.AccountStatus",
            "name": "AccountStatus",
            "displayName": null,
            "selectorText": null,
            "editorHint": null,
            "values": {
              "disabled": {
                "discriminator": null,
                "name": "Disabled",
                "value": 0,
                "displayName": "Disabled"
              },
              "activated": {
                "discriminator": null,
                "name": "Activated",
                "value": 1,
                "displayName": "Activated"
              }
            }
          },
          "DeviceCommandResponseResult": {
            "discriminator": "MyBuddyGard.Domain.Enumerations.DeviceCommandResponseResult",
            "name": "DeviceCommandResponseResult",
            "displayName": null,
            "selectorText": null,
            "editorHint": null,
            "values": {
              "success": {
                "discriminator": null,
                "name": "Success",
                "value": 0,
                "displayName": "Success"
              },
              "queued": {
                "discriminator": null,
                "name": "Queued",
                "value": 1,
                "displayName": "Queued"
              },
              "notConnected": {
                "discriminator": null,
                "name": "NotConnected",
                "value": 2,
                "displayName": "NotConnected"
              },
              "noResponse": {
                "discriminator": null,
                "name": "NoResponse",
                "value": 3,
                "displayName": "NoResponse"
              },
              "failedOnDevice": {
                "discriminator": null,
                "name": "FailedOnDevice",
                "value": 4,
                "displayName": "FailedOnDevice"
              },
              "serviceUnavailable": {
                "discriminator": null,
                "name": "ServiceUnavailable",
                "value": 5,
                "displayName": "ServiceUnavailable"
              },
              "deviceNotSupported": {
                "discriminator": null,
                "name": "DeviceNotSupported",
                "value": 6,
                "displayName": "DeviceNotSupported"
              },
              "commandNotSupported": {
                "discriminator": null,
                "name": "CommandNotSupported",
                "value": 7,
                "displayName": "CommandNotSupported"
              },
              "invalidPostRequest": {
                "discriminator": null,
                "name": "InvalidPostRequest",
                "value": 8,
                "displayName": "InvalidPostRequest"
              },
              "invalidRequestType": {
                "discriminator": null,
                "name": "InvalidRequestType",
                "value": 9,
                "displayName": "InvalidRequestType"
              },
              "deviceNotFound": {
                "discriminator": null,
                "name": "DeviceNotFound",
                "value": 10,
                "displayName": "DeviceNotFound"
              },
              "featureNotSupported": {
                "discriminator": null,
                "name": "FeatureNotSupported",
                "value": 11,
                "displayName": "FeatureNotSupported"
              },
              "unknownError": {
                "discriminator": null,
                "name": "UnknownError",
                "value": 12,
                "displayName": "UnknownError"
              },
              "unauthorized": {
                "discriminator": null,
                "name": "Unauthorized",
                "value": 13,
                "displayName": "Unauthorized"
              },
              "restrictionExceeded": {
                "discriminator": null,
                "name": "RestrictionExceeded",
                "value": 14,
                "displayName": "RestrictionExceeded"
              }
            }
          }
        }
      }
    }
  },
  "permissions": {
    "users": {
      "discriminator": "users",
      "name": "Users"
    }
  },
  "constants": {
    "Microsoft": {
      "discriminator": "Microsoft",
      "name": "Microsoft",
      "AspNetCore": {
        "discriminator": "Microsoft.AspNetCore",
        "name": "AspNetCore",
        "Http": {
          "discriminator": "Microsoft.AspNetCore.Http",
          "name": "Http",
          "StatusCodes": {
            "discriminator": "Microsoft.AspNetCore.Http.StatusCodes",
            "name": "StatusCodes",
            "Status100Continue": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status100Continue",
              "name": "StatusCodes",
              "displayName": "Status100Continue",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 100
            },
            "Status101SwitchingProtocols": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status101SwitchingProtocols",
              "name": "StatusCodes",
              "displayName": "Status101SwitchingProtocols",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 101
            },
            "Status102Processing": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status102Processing",
              "name": "StatusCodes",
              "displayName": "Status102Processing",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 102
            },
            "Status200OK": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status200OK",
              "name": "StatusCodes",
              "displayName": "Status200OK",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 200
            },
            "Status201Created": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status201Created",
              "name": "StatusCodes",
              "displayName": "Status201Created",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 201
            },
            "Status202Accepted": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status202Accepted",
              "name": "StatusCodes",
              "displayName": "Status202Accepted",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 202
            },
            "Status203NonAuthoritative": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status203NonAuthoritative",
              "name": "StatusCodes",
              "displayName": "Status203NonAuthoritative",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 203
            },
            "Status204NoContent": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status204NoContent",
              "name": "StatusCodes",
              "displayName": "Status204NoContent",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 204
            },
            "Status205ResetContent": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status205ResetContent",
              "name": "StatusCodes",
              "displayName": "Status205ResetContent",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 205
            },
            "Status206PartialContent": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status206PartialContent",
              "name": "StatusCodes",
              "displayName": "Status206PartialContent",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 206
            },
            "Status207MultiStatus": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status207MultiStatus",
              "name": "StatusCodes",
              "displayName": "Status207MultiStatus",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 207
            },
            "Status208AlreadyReported": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status208AlreadyReported",
              "name": "StatusCodes",
              "displayName": "Status208AlreadyReported",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 208
            },
            "Status226IMUsed": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status226IMUsed",
              "name": "StatusCodes",
              "displayName": "Status226IMUsed",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 226
            },
            "Status300MultipleChoices": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status300MultipleChoices",
              "name": "StatusCodes",
              "displayName": "Status300MultipleChoices",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 300
            },
            "Status301MovedPermanently": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status301MovedPermanently",
              "name": "StatusCodes",
              "displayName": "Status301MovedPermanently",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 301
            },
            "Status302Found": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status302Found",
              "name": "StatusCodes",
              "displayName": "Status302Found",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 302
            },
            "Status303SeeOther": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status303SeeOther",
              "name": "StatusCodes",
              "displayName": "Status303SeeOther",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 303
            },
            "Status304NotModified": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status304NotModified",
              "name": "StatusCodes",
              "displayName": "Status304NotModified",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 304
            },
            "Status305UseProxy": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status305UseProxy",
              "name": "StatusCodes",
              "displayName": "Status305UseProxy",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 305
            },
            "Status306SwitchProxy": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status306SwitchProxy",
              "name": "StatusCodes",
              "displayName": "Status306SwitchProxy",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 306
            },
            "Status307TemporaryRedirect": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status307TemporaryRedirect",
              "name": "StatusCodes",
              "displayName": "Status307TemporaryRedirect",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 307
            },
            "Status308PermanentRedirect": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status308PermanentRedirect",
              "name": "StatusCodes",
              "displayName": "Status308PermanentRedirect",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 308
            },
            "Status400BadRequest": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status400BadRequest",
              "name": "StatusCodes",
              "displayName": "Status400BadRequest",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 400
            },
            "Status401Unauthorized": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status401Unauthorized",
              "name": "StatusCodes",
              "displayName": "Status401Unauthorized",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 401
            },
            "Status402PaymentRequired": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status402PaymentRequired",
              "name": "StatusCodes",
              "displayName": "Status402PaymentRequired",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 402
            },
            "Status403Forbidden": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status403Forbidden",
              "name": "StatusCodes",
              "displayName": "Status403Forbidden",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 403
            },
            "Status404NotFound": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status404NotFound",
              "name": "StatusCodes",
              "displayName": "Status404NotFound",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 404
            },
            "Status405MethodNotAllowed": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status405MethodNotAllowed",
              "name": "StatusCodes",
              "displayName": "Status405MethodNotAllowed",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 405
            },
            "Status406NotAcceptable": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status406NotAcceptable",
              "name": "StatusCodes",
              "displayName": "Status406NotAcceptable",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 406
            },
            "Status407ProxyAuthenticationRequired": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status407ProxyAuthenticationRequired",
              "name": "StatusCodes",
              "displayName": "Status407ProxyAuthenticationRequired",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 407
            },
            "Status408RequestTimeout": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status408RequestTimeout",
              "name": "StatusCodes",
              "displayName": "Status408RequestTimeout",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 408
            },
            "Status409Conflict": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status409Conflict",
              "name": "StatusCodes",
              "displayName": "Status409Conflict",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 409
            },
            "Status410Gone": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status410Gone",
              "name": "StatusCodes",
              "displayName": "Status410Gone",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 410
            },
            "Status411LengthRequired": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status411LengthRequired",
              "name": "StatusCodes",
              "displayName": "Status411LengthRequired",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 411
            },
            "Status412PreconditionFailed": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status412PreconditionFailed",
              "name": "StatusCodes",
              "displayName": "Status412PreconditionFailed",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 412
            },
            "Status413RequestEntityTooLarge": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status413RequestEntityTooLarge",
              "name": "StatusCodes",
              "displayName": "Status413RequestEntityTooLarge",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 413
            },
            "Status413PayloadTooLarge": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status413PayloadTooLarge",
              "name": "StatusCodes",
              "displayName": "Status413PayloadTooLarge",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 413
            },
            "Status414RequestUriTooLong": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status414RequestUriTooLong",
              "name": "StatusCodes",
              "displayName": "Status414RequestUriTooLong",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 414
            },
            "Status414UriTooLong": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status414UriTooLong",
              "name": "StatusCodes",
              "displayName": "Status414UriTooLong",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 414
            },
            "Status415UnsupportedMediaType": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status415UnsupportedMediaType",
              "name": "StatusCodes",
              "displayName": "Status415UnsupportedMediaType",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 415
            },
            "Status416RequestedRangeNotSatisfiable": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status416RequestedRangeNotSatisfiable",
              "name": "StatusCodes",
              "displayName": "Status416RequestedRangeNotSatisfiable",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 416
            },
            "Status416RangeNotSatisfiable": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status416RangeNotSatisfiable",
              "name": "StatusCodes",
              "displayName": "Status416RangeNotSatisfiable",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 416
            },
            "Status417ExpectationFailed": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status417ExpectationFailed",
              "name": "StatusCodes",
              "displayName": "Status417ExpectationFailed",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 417
            },
            "Status418ImATeapot": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status418ImATeapot",
              "name": "StatusCodes",
              "displayName": "Status418ImATeapot",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 418
            },
            "Status419AuthenticationTimeout": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status419AuthenticationTimeout",
              "name": "StatusCodes",
              "displayName": "Status419AuthenticationTimeout",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 419
            },
            "Status421MisdirectedRequest": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status421MisdirectedRequest",
              "name": "StatusCodes",
              "displayName": "Status421MisdirectedRequest",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 421
            },
            "Status422UnprocessableEntity": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status422UnprocessableEntity",
              "name": "StatusCodes",
              "displayName": "Status422UnprocessableEntity",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 422
            },
            "Status423Locked": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status423Locked",
              "name": "StatusCodes",
              "displayName": "Status423Locked",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 423
            },
            "Status424FailedDependency": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status424FailedDependency",
              "name": "StatusCodes",
              "displayName": "Status424FailedDependency",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 424
            },
            "Status426UpgradeRequired": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status426UpgradeRequired",
              "name": "StatusCodes",
              "displayName": "Status426UpgradeRequired",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 426
            },
            "Status428PreconditionRequired": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status428PreconditionRequired",
              "name": "StatusCodes",
              "displayName": "Status428PreconditionRequired",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 428
            },
            "Status429TooManyRequests": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status429TooManyRequests",
              "name": "StatusCodes",
              "displayName": "Status429TooManyRequests",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 429
            },
            "Status431RequestHeaderFieldsTooLarge": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status431RequestHeaderFieldsTooLarge",
              "name": "StatusCodes",
              "displayName": "Status431RequestHeaderFieldsTooLarge",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 431
            },
            "Status451UnavailableForLegalReasons": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status451UnavailableForLegalReasons",
              "name": "StatusCodes",
              "displayName": "Status451UnavailableForLegalReasons",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 451
            },
            "Status500InternalServerError": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status500InternalServerError",
              "name": "StatusCodes",
              "displayName": "Status500InternalServerError",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 500
            },
            "Status501NotImplemented": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status501NotImplemented",
              "name": "StatusCodes",
              "displayName": "Status501NotImplemented",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 501
            },
            "Status502BadGateway": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status502BadGateway",
              "name": "StatusCodes",
              "displayName": "Status502BadGateway",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 502
            },
            "Status503ServiceUnavailable": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status503ServiceUnavailable",
              "name": "StatusCodes",
              "displayName": "Status503ServiceUnavailable",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 503
            },
            "Status504GatewayTimeout": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status504GatewayTimeout",
              "name": "StatusCodes",
              "displayName": "Status504GatewayTimeout",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 504
            },
            "Status505HttpVersionNotsupported": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status505HttpVersionNotsupported",
              "name": "StatusCodes",
              "displayName": "Status505HttpVersionNotsupported",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 505
            },
            "Status506VariantAlsoNegotiates": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status506VariantAlsoNegotiates",
              "name": "StatusCodes",
              "displayName": "Status506VariantAlsoNegotiates",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 506
            },
            "Status507InsufficientStorage": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status507InsufficientStorage",
              "name": "StatusCodes",
              "displayName": "Status507InsufficientStorage",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 507
            },
            "Status508LoopDetected": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status508LoopDetected",
              "name": "StatusCodes",
              "displayName": "Status508LoopDetected",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 508
            },
            "Status510NotExtended": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status510NotExtended",
              "name": "StatusCodes",
              "displayName": "Status510NotExtended",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 510
            },
            "Status511NetworkAuthenticationRequired": {
              "discriminator": "Microsoft.AspNetCore.Http.StatusCodes.Status511NetworkAuthenticationRequired",
              "name": "StatusCodes",
              "displayName": "Status511NetworkAuthenticationRequired",
              "type": "System.Int32, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.Int32",
              "value": 511
            }
          }
        }
      }
    },
    "MyBuddyGard": {
      "discriminator": "MyBuddyGard",
      "name": "MyBuddyGard",
      "Domain": {
        "discriminator": "MyBuddyGard.Domain",
        "name": "Domain",
        "Constants": {
          "discriminator": "MyBuddyGard.Domain.Constants",
          "name": "Constants",
          "HubEvents": {
            "discriminator": "MyBuddyGard.Domain.Constants.HubEvents",
            "name": "HubEvents",
            "ReceiveDeviceLocation": {
              "discriminator": "MyBuddyGard.Domain.Constants.HubEvents.ReceiveDeviceLocation",
              "name": "HubEvents",
              "displayName": "ReceiveDeviceLocation",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "ReceiveDeviceLocation"
            }
          },
          "CustomHttpHeaders": {
            "discriminator": "MyBuddyGard.Domain.Constants.CustomHttpHeaders",
            "name": "CustomHttpHeaders",
            "BrowserTimezone": {
              "discriminator": "MyBuddyGard.Domain.Constants.CustomHttpHeaders.BrowserTimezone",
              "name": "CustomHttpHeaders",
              "displayName": "BrowserTimezone",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "x-mbg-browser-timezone"
            }
          },
          "Formats": {
            "discriminator": "MyBuddyGard.Domain.Constants.Formats",
            "name": "Formats",
            "Slug": {
              "discriminator": "MyBuddyGard.Domain.Constants.Formats.Slug",
              "name": "Formats",
              "displayName": "Slug",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "^[a-z0-9-]+$"
            },
            "CognitoPhoneNumberEscaped": {
              "discriminator": "MyBuddyGard.Domain.Constants.Formats.CognitoPhoneNumberEscaped",
              "name": "Formats",
              "displayName": "CognitoPhoneNumberEscaped",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "\\\\+[0-9]+"
            },
            "CognitoPasswordEscaped": {
              "discriminator": "MyBuddyGard.Domain.Constants.Formats.CognitoPasswordEscaped",
              "name": "Formats",
              "displayName": "CognitoPasswordEscaped",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\\\\^$*.[\\\\]{}()?\\\"!@#%&/\\\\\\\\,><\\':;|_~`=+\\\\- ])[A-Za-z0-9^$*.[\\\\]{}()?\\\"!@#%&/\\\\\\\\,><\\':;|_~`=+\\\\- ]{6,99}"
            }
          },
          "DeviceCommandIds": {
            "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds",
            "name": "DeviceCommandIds",
            "Immobilise": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.Immobilise",
              "name": "DeviceCommandIds",
              "displayName": "Immobilise",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "immobilise"
            },
            "Reset": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.Reset",
              "name": "DeviceCommandIds",
              "displayName": "Reset",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "reset"
            },
            "Find": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.Find",
              "name": "DeviceCommandIds",
              "displayName": "Find",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "find"
            },
            "Activate": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.Activate",
              "name": "DeviceCommandIds",
              "displayName": "Activate",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "activate"
            },
            "Locate": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.Locate",
              "name": "DeviceCommandIds",
              "displayName": "Locate",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "locate"
            },
            "SetupSos": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.SetupSos",
              "name": "DeviceCommandIds",
              "displayName": "SetupSos",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "setupSos"
            },
            "Contacts": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.Contacts",
              "name": "DeviceCommandIds",
              "displayName": "Contacts",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "contacts"
            },
            "UpdateRate": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.UpdateRate",
              "name": "DeviceCommandIds",
              "displayName": "UpdateRate",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "updateRate"
            },
            "SleepTime": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.SleepTime",
              "name": "DeviceCommandIds",
              "displayName": "SleepTime",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "sleeptime"
            },
            "Monitor": {
              "discriminator": "MyBuddyGard.Domain.Constants.DeviceCommandIds.Monitor",
              "name": "DeviceCommandIds",
              "displayName": "Monitor",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "monitor"
            }
          }
        },
        "Models": {
          "discriminator": "MyBuddyGard.Domain.Models",
          "name": "Models",
          "Notifications": {
            "discriminator": "MyBuddyGard.Domain.Models.Notifications",
            "name": "Notifications",
            "NotificationGroup": {
              "discriminator": "MyBuddyGard.Domain.Models.Notifications.NotificationGroup",
              "name": "NotificationGroup",
              "Account": {
                "discriminator": "MyBuddyGard.Domain.Models.Notifications.NotificationGroup.Account",
                "name": "NotificationGroup",
                "displayName": "Account",
                "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "typeName": "System.String",
                "value": "account"
              },
              "User": {
                "discriminator": "MyBuddyGard.Domain.Models.Notifications.NotificationGroup.User",
                "name": "NotificationGroup",
                "displayName": "User",
                "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
                "typeName": "System.String",
                "value": "user"
              }
            }
          }
        }
      },
      "Api": {
        "discriminator": "MyBuddyGard.Api",
        "name": "Api",
        "Constants": {
          "discriminator": "MyBuddyGard.Api.Constants",
          "name": "Constants",
          "AuthPolicies": {
            "discriminator": "MyBuddyGard.Api.Constants.AuthPolicies",
            "name": "AuthPolicies",
            "AllowCognitoIdToken": {
              "discriminator": "MyBuddyGard.Api.Constants.AuthPolicies.AllowCognitoIdToken",
              "name": "AuthPolicies",
              "displayName": "AllowCognitoIdToken",
              "type": "System.String, System.Private.CoreLib, Version=6.0.0.0, Culture=neutral, PublicKeyToken=7cec85d7bea7798e",
              "typeName": "System.String",
              "value": "AllowCognitoIdToken"
            }
          }
        }
      }
    }
  }
};
