import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from "../service/user.service";
import {map, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router, protected userService: UserService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.currentAuthenticatedUser().pipe(
      take(1),
      map(u => {
        if (u == null)
          return this.router.createUrlTree(['/','login'], {queryParams: {returnTo: route.url.join('/')}});

        return u.administrator || this.router.createUrlTree(['/']);
      })
    );
  }
}
