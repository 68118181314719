import { Injectable } from '@angular/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import {Observable, forkJoin, from, of, combineLatest, Subject, ReplaySubject, BehaviorSubject} from 'rxjs';
import {map, mergeMap, share, catchError, finalize, throttleTime, filter, take, takeUntil} from 'rxjs/operators';
import { DeviceAuthorizationRepository } from 'src/app/domain/endpoints.repositories';
import { DevicePairing } from 'src/app/models/constants/device-pairing';
import { Device as CapacitorDevice } from '@capacitor/device';
import { MyBuddyGard } from 'src/app/domain/models';
import DeviceLocation = MyBuddyGard.Domain.Models.DeviceLocation;
import Coordinates = MyBuddyGard.Domain.Models.Coordinates;
import DeviceLocationAndToken = MyBuddyGard.Api.Models.DeviceLocationAndToken;
import { LocationRepository } from 'src/app/repositories/location.repository';

interface StoredToken {
  key: string;
  token: string;
}

interface PositionOrError {
  position: Position;
  error: any;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceTrackingService {

  constructor(protected deviceAuthRepository: DeviceAuthorizationRepository,
    protected locationRepository: LocationRepository) { }

  private readonly _geolocationWatchIdStorageKey = `${DevicePairing.StorageGeoTrackingKeyPrefix}geotracking:watchid`;
  private readonly _minUpdateTimeout = 5000; // in ms
  private _geolocationWatchId: string;
  private _validationObs: Observable<StoredToken[]>;
  private _positionSubj: Subject<PositionOrError> = new Subject<PositionOrError>();
  private _validTokensCount = 0;
  private _hasValidTokens: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  private _started: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  start(): void {
    this._hasValidTokens.pipe(
      mergeMap(hasValid => combineLatest([
        // NOTE:
        // Previous geolocation watch continues to run after restarting app.
        // Stop previous watch if there are no valid tokens. A new watch will be started down the pipe if a new token is added later.
        // `hasValid === false` is intentional
        (hasValid === false ? this.stop() : of(null)),
        of(hasValid)
      ])),
      filter(([_, hasValid]) => hasValid),
      mergeMap(() => this.hasLocationPermission()),
      mergeMap(granted => granted
        ? of(granted)
        : this.requestLocationPermission()),
      mergeMap(granted => granted
        ? from(Geolocation.watchPosition({ enableHighAccuracy: true }, (p, e) => this._positionSubj.next(<PositionOrError> { position: p, error: e })))
        : of(null)),
      takeUntil(this._started),
    ).subscribe(watchId => {

      if (watchId != null) {
        // NOTE:
        // Keep track of geolocation watch ID.
        SecureStoragePlugin.set({ key: this._geolocationWatchIdStorageKey, value: watchId }).then();
        this._geolocationWatchId = watchId;
        this.startLocalWatch();
        this._started.next(true);
      }
    });
  }

  stop(): Observable<void> {
    return from(SecureStoragePlugin.get({ key: this._geolocationWatchIdStorageKey })).pipe(
      catchError(err => of(null)),
      map(res => res?.value),
      mergeMap(id => {
        let obs = id != null ? from(Geolocation.clearWatch({ id: id })) : of(null);
        return obs.pipe(map(() => id));
      }),
      mergeMap(id => id != null
          ? from(SecureStoragePlugin.remove({ key: this._geolocationWatchIdStorageKey }))
          : of(null)),
      catchError(err => of(null)),
      take(1)
    );
  }

  getToken(key: string): Observable<string> {
    return from(SecureStoragePlugin.get({ key: key })).pipe(map(res => res.value));
  }

  addToken(t: StoredToken): Observable<boolean> {
    return from(SecureStoragePlugin.set({ key: t.key, value: t.token })).pipe(map(res => {
      let success = res.value;
      if (success) {
        this._validTokensCount = this._validTokensCount > 0 ? this._validTokensCount++ : 1;
        this._hasValidTokens.next(true);
      }

      return success;
    }));
  }

  removeToken(key: string): Observable<boolean> {
    return from(SecureStoragePlugin.remove({ key: key })).pipe(
      map(res => {
        let success = res.value;

        if (success) {
          this._validTokensCount = this._validTokensCount > 0 ? this._validTokensCount-- : 0;
          this._hasValidTokens.next(this._validTokensCount > 0);
        }

        return success;
      })
    );
  }

  /**
   * Returns the state of validated tokens at the time of invocation, or
   * triggers token validation if not triggered previously then returns result, or waits for the currently active
   * validation then returns the result
   */
  hasValidTokens(): Observable<boolean> {
    let validatedTokenObs = this.getValidatedTokens().pipe(map(st => st.length > 0));
    return this._hasValidTokens.pipe(
      mergeMap(v => v !== undefined ? of(v) : validatedTokenObs)
    );
  }

  sendLocationNow(): Observable<void> {
    return from(Geolocation.getCurrentPosition({ enableHighAccuracy: true })).pipe(
      mergeMap(res => this.sendLocationUpdateFromPosition(<PositionOrError> { position: res }))
    );
  }

  sendSosNow(): Observable<void> {
    return from(Geolocation.getCurrentPosition({ enableHighAccuracy: true })).pipe(
      mergeMap(res => this.sendLocationUpdateFromPosition(<PositionOrError> { position: res }, true))
    );
  }

  hasLocationPermission(): Observable<boolean> {
    return from(Geolocation.checkPermissions()).pipe(
      map(permStatus => permStatus.location == 'granted' || permStatus.coarseLocation == 'granted'),
      // NOTE: Plugin throws exception if system location services are disabled
      catchError(() => of(false))
    );
  }

  requestLocationPermission(): Observable<boolean> {
    return from(Geolocation.requestPermissions()).pipe(
      map(permStatus => permStatus.location == 'granted' || permStatus.coarseLocation == 'granted')
    );
  }

  // Had to do it this way so updates can be throttled at will
  private startLocalWatch(): void {
    this._positionSubj.pipe(
      throttleTime(this._minUpdateTimeout),
      mergeMap(res => this.sendLocationUpdateFromPosition(res))
    ).subscribe();
  }

  private sendLocationUpdateFromPosition(data: PositionOrError, sos: boolean = false): Observable<void> {
    let position = data.position;
    let err = data.error;

    if (err != null || position == null)
      return of(null);

    let date = new Date(position.timestamp);

    let deviceLocation = <DeviceLocation> {
      locationUpdateDate: date,
      coordinates: <Coordinates> {
        longitude: position.coords?.longitude,
        latitude: position.coords?.latitude,
        accuracy: position.coords?.accuracy
      },
      speed: position.coords?.speed,
      direction: position.coords?.heading,
      sosPressed: sos
    };

    // Preferably all stored tokens were verified first
    return combineLatest([
      this.getStoredTokens(),
      from(CapacitorDevice.getBatteryInfo()),
      of(deviceLocation)
    ]).pipe(
      filter(([st, _, __]) => st.length > 0),
      map(([st, batt, location]) => st.map(t => {
          location.batteryStatus = batt.batteryLevel * 100;
          return this.updateTokenLocation(location, t);
        })),
      mergeMap((obs: Observable<void>[]) => forkJoin(obs)),
      take(1),
      map(() => null)
    );
  }

  private getStoredTokens(): Observable<StoredToken[]> {
    return from(SecureStoragePlugin.keys()).pipe(
      map(k => k.value.filter(s => s.startsWith(DevicePairing.StorageTokenKeyPrefix))),
      map(k => k.map(key => from(SecureStoragePlugin.get({ key: key })).pipe(map(v => <StoredToken> { key: key, token: v.value })))),
      mergeMap((x: Observable<StoredToken>[]) => x.length > 0 ? forkJoin(x) : of([])));
  }

  /**
   * Validate stored tokens with API, removing invalid ones
   * @private
   */
  private getValidatedTokens(): Observable<StoredToken[]> {

    if (this._validationObs == null) {
      this._validationObs = this.getStoredTokens().pipe(
        mergeMap(stored => {
          let validationRequestObs = stored.length > 0
            ? this.deviceAuthRepository.postValidateTokens(stored.map(s => s.token))
            : of(null);

          return combineLatest([of(stored), validationRequestObs]);
        }),
        mergeMap(([stored, response]) => {
          let valid = stored.filter(st => response.result.find(res => res.token == st.token)?.valid ?? false);
          stored.filter(st => !valid.some(vt => vt.key == st.key)).forEach(invalid => {
            SecureStoragePlugin.remove({ key: invalid.key }).then();
          });

          this._validTokensCount = valid.length;
          this._hasValidTokens.next(valid.length > 0);

          return of(valid);
        }),
        share(),
        finalize(() => this._validationObs = null)
      );
    }

    return this._validationObs;
  }

  private updateTokenLocation(location: DeviceLocation, storedToken: StoredToken): Observable<any> {
    let model = <DeviceLocationAndToken> {
      deviceLocation: location,
      token: storedToken.token
    };

    return this.locationRepository.postPairedDevice(model).pipe(
      catchError(err => {
        if (err?.status == 410) {
          // API responds with `Gone`
          return this.removeToken(storedToken.key);
        }

        if (err?.status == 422) {
          // Device is present but not covered with subscription
        }

        // Do not throw
        return of(err);
      })
    );
  }
}
