import { Injectable } from '@angular/core';
import { Observable, ReplaySubject} from "rxjs";
import {ParamMapService} from "./parammap.service";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private _slugSubject : ReplaySubject<string> = new ReplaySubject<string>(1);
  get slug() : Observable<string> {
    return this._slugSubject.asObservable();
  }

  private _currentSlug: string | undefined;

  constructor(
    private paramMapService : ParamMapService
  ) {

    paramMapService.paramMap.subscribe(paramMap => {
      let slug = paramMap.get("slug");
      if(slug !== this._currentSlug) {
        this._currentSlug = slug;
        this._slugSubject.next(slug);
      }
    });
  }
}
