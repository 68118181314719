import { Injectable } from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, of, throwError} from "rxjs";
import {filter, mergeMap, retryWhen, skipWhile, take} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RetryInterceptor implements HttpInterceptor{

  private disableRetry:  boolean;
  private maxRetries : number = 2;

  constructor() { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of(req).pipe(
      mergeMap((req: HttpRequest<any>) => {
        return next.handle(req).pipe(
          // Will continue the pipe if a request attempt has succeeded without errors, or exceeded max retries.
          retryWhen((errors: Observable<any>) => errors.pipe(
            filter(src => src instanceof HttpErrorResponse),
            // Enable only 2 retries, +1 for the original request.
            take(this.maxRetries + 1),
            skipWhile(() => this.disableRetry),
            mergeMap((err: HttpErrorResponse, i: number) => {
              if (i >= this.maxRetries) {
                return throwError(err);
              }

              const errorCode = err.status;

              if (errorCode == 401) {
                return of(err);
              }

              return throwError(err);
            }),
          ))
        )
      })
    );
  }
}
