import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Auth } from '@aws-amplify/auth';
import { AmplifyModules, AmplifyService } from 'aws-amplify-angular';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { Interceptor } from './interceptors/interceptor';
import { DemoMaterialModule } from './material.modules';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { RepositoryConfig } from '@intellis/entityrepository';
import { MbgApiRepositoryConfig } from './config/repository.config';
import {RetryInterceptor} from "./interceptors/retry.interceptor";
import {BaseSchemaConfig, SchemaService} from "@intellis/schema";
import {SchemaConfig} from "./config/schema.config";
import {BatteryLevelTrigger} from "./triggers/battery-level/battery-level.trigger";
import {MBG_ACTION, MBG_TRIGGER} from "./injection/tokens";
import {GeofenceTrigger} from "./triggers/geofence/geofence.trigger";
import {PowerTrigger} from "./triggers/power/power.trigger";
import {SosTrigger} from "./triggers/sos/sos.trigger";
import {SpeedTrigger} from "./triggers/speed/speed.trigger";
import {WifiTrigger} from "./triggers/wifi/wifi.trigger";
import {EmailAction} from "./actions/email/email.action";
import {ImmobiliseAction} from "./actions/immobilise/immobilise.action";
import {LocateAction} from "./actions/locate/locate.action";
import {NotificationAction} from "./actions/notification/notification.action";
import {SmsAction} from "./actions/sms/sms.action";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from "@angular-material-components/datetime-picker";
import {environment} from "../environments/environment";
import {AngularFireModule} from "@angular/fire";
import {AngularFireMessagingModule} from "@angular/fire/messaging";
// import {ServiceWorkerModule, SwRegistrationOptions} from '@angular/service-worker';
import {IonicModule} from "@ionic/angular";
import {AndroidPermissions} from "@awesome-cordova-plugins/android-permissions/ngx";
import {StartupFactory} from "./startup/startup.factory";
import {StartupService} from "./startup/startup.service";
import {
  AccountRepository, ChargeRepository,
  DeviceRepository,
  GeofenceRepository, PlanRepository,
  RulesEngineRepository, SubscriptionRepository,
  UsersRepository
} from "./domain/endpoints.repositories";
import {
  DeviceRepository as CustomDeviceRepository,
  GeofenceRepository as CustomGeofenceRepository,
  UsersRepository as CustomUsersRepository,
  RulesEngineRepository as CustomRulesEngineRepository,
  AccountRepository as CustomAccountRepository,
  PlanRepository as CustomPlanRepository,
  SubscriptionRepository as CustomSubscriptionRepository,
  ChargeRepository as CustomChargeRepository
} from "src/app/repositories/endpoints-custom.repository"

@NgModule({
  declarations: [AppComponent],
  imports: [
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,

    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,

    AmplifyUIAngularModule,

    FormsModule,
    HttpClientModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    DemoMaterialModule,

    AngularFireModule.initializeApp(environment.firebase.config),
    AngularFireMessagingModule,

    FlexLayoutModule.withConfig({
      useColumnBasisZero: false,
      printWithBreakpoints: ['md', 'lt-lg', 'lt-xl', 'gt-sm', 'gt-xs'],
    }),

    // ServiceWorkerModule.register('ngsw-worker.js'),

    IonicModule.forRoot()

  ],

  providers: [
    AndroidPermissions,

    {
      provide: APP_INITIALIZER,
      useFactory: StartupFactory.StartupService,
      deps: [StartupService],
      multi: true
    },
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth,
        });
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    {
      provide: RepositoryConfig,
      useClass: MbgApiRepositoryConfig,
    },
    SchemaService,
    {
      provide: BaseSchemaConfig,
      useClass: SchemaConfig
    },
    {
      provide: MBG_TRIGGER,
      useValue: {
        type: BatteryLevelTrigger,
        name: "Battery level",
        discriminator: "BatteryLevelTrigger",
        icon: "battery_5_bar",
      },
      multi: true,
    },
    {
      provide: MBG_TRIGGER,
      useValue: {
        type: GeofenceTrigger,
        name: "Geofence",
        discriminator: "GeofenceTrigger",
        icon: "map",
      },
      multi: true,
    },
    {
      provide: MBG_TRIGGER,
      useValue: {
        type: PowerTrigger,
        name: "Power",
        discriminator: "PowerTrigger",
        icon: "power_settings_new",
      },
      multi: true,
    },
    {
      provide: MBG_TRIGGER,
      useValue: {
        type: SosTrigger,
        name: "SOS",
        discriminator: "SosTrigger",
        icon: "sos",
      },
      multi: true,
    },
    {
      provide: MBG_TRIGGER,
      useValue: {
        type: SpeedTrigger,
        name: "Speed",
        discriminator: "SpeedTrigger",
        icon: "speed",
      },
      multi: true,
    },
    {
      provide: MBG_TRIGGER,
      useValue: {
        type: WifiTrigger,
        name: "Wifi",
        discriminator: "WifiTrigger",
        icon: "wifi",
      },
      multi: true,
    },
    {
      provide: MBG_ACTION,
      useValue: {
        type: EmailAction,
        name: "Email",
        discriminator: "EmailAction",
        icon: "mail",
      },
      multi: true,
    },
    {
      provide: MBG_ACTION,
      useValue: {
        type: ImmobiliseAction,
        name: "Immobilise",
        discriminator: "ImmobiliseAction",
        icon: "directions_car",
      },
      multi: true,
    },
    {
      provide: MBG_ACTION,
      useValue: {
        type: LocateAction,
        name: "Locate",
        discriminator: "LocateAction",
        icon: "location_on",
      },
      multi: true,
    },
    {
      provide: MBG_ACTION,
      useValue: {
        type: NotificationAction,
        name: "Notification",
        discriminator: "NotificationAction",
        icon: "message",
      },
      multi: true,
    },
    {
      provide: MBG_ACTION,
      useValue: {
        type: SmsAction,
        name: "SMS",
        discriminator: "SmsAction",
        icon: "phone_iphone",
      },
      multi: true,
    },
    // {
    //   provide: SwRegistrationOptions,
    //   useFactory: () => ({
    //     // ORIGINAL:
    //     // enabled: environment.production,
    //     enabled: true,
    //     // Register the ServiceWorker as soon as the app is stable
    //     // or after 30 seconds (whichever comes first).
    //     registrationStrategy: 'registerWhenStable:30000'
    //   })
    // },
    {
      provide: DeviceRepository,
      useClass: CustomDeviceRepository
    },
    {
      provide: GeofenceRepository,
      useClass: CustomGeofenceRepository
    },
    {
      provide: RulesEngineRepository,
      useClass: CustomRulesEngineRepository
    },
    {
      provide: UsersRepository,
      useClass: CustomUsersRepository
    },
    {
      provide: AccountRepository,
      useClass: CustomAccountRepository
    },
    {
      provide: PlanRepository,
      useClass: CustomPlanRepository
    },
    {
      provide: SubscriptionRepository,
      useClass: CustomSubscriptionRepository
    },
    {
      provide: ChargeRepository,
      useClass: CustomChargeRepository
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
