import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {MyBuddyGard} from "../../domain/models";
import {Trigger} from "../trigger/trigger.component";
import TriggerModel = MyBuddyGard.Domain.Models.Rules.Triggers.WifiTrigger;
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validator, Validators
} from "@angular/forms";
import {RulesEngineRepository, WifiRepository} from "../../domain/endpoints.repositories";
import {take, takeUntil} from "rxjs/operators";
import Wifi = MyBuddyGard.Domain.Models.Wifi;
import GeofenceActionType = MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType;
import DwellCondition = MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition;
import DwellTimeUnit = MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit;
import {Subject} from "rxjs";

@Component({
  selector: 'wifi-trigger',
  templateUrl: './wifi.trigger.html',
  styleUrls: ['./wifi.trigger.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WifiTrigger),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: WifiTrigger,
      multi: true,
    }
  ]
})
export class WifiTrigger implements Trigger<TriggerModel>, OnInit, OnDestroy {

  modelFormGroup: FormGroup;

  model : TriggerModel;

  constructor(
    fb : FormBuilder,
    protected repository : RulesEngineRepository,
    protected wifiRepository : WifiRepository
  ) {
    this.modelFormGroup = fb.group({
      wifiId: [null, Validators.required],
      dwellCondition: null,
      dwellTime: null,
      dwellTimeUnit: null,
      actionType: null,
    });

    this.preMadeDwellTime = [30, 60, 90, 120];
    this.customDwellTime = new FormControl(null);
  }

  wifis : Wifi[];

  preMadeDwellTime : number[];

  customDwellTime : FormControl;

  private _destroy: Subject<void> = new Subject<void>();

  get actionType() {
    return GeofenceActionType;
  }

  get dwellCondition() {
    return DwellCondition;
  }

  get dwellTimeUnit() {
    return DwellTimeUnit;
  }

  ngOnInit(): void {
    this.modelFormGroup.valueChanges.pipe(takeUntil(this._destroy)).subscribe(r => {
      this.onChange(Object.assign(this.model, r));
      this.onValidationChange();
    });

    // Get recent wifi list
    this.wifiRepository.recent().pipe(
      take(1)
    ).subscribe(r => {
      this.wifis = r;
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  onChange: (model : TriggerModel) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(model: TriggerModel): void {
    if (model == null) {
      // Get default values from API
      this.repository.triggerModelDefault({discriminator: 'WifiTrigger'} as TriggerModel).pipe(
        take(1)
      ).subscribe(r => {
        this.model = r as TriggerModel;
        this.modelFormGroup.patchValue(this.model);
      });
    } else {
      this.model = model;
      this.modelFormGroup.patchValue(model);

      // Resolve custom dwell time display
      let dwellTime = this.modelFormGroup.controls.dwellTime.value;
      if (this.preMadeDwellTime.find(t => t == dwellTime) == undefined) {
        this.customDwellTime.setValue(dwellTime);
      }

      // Acknowledge to parent that model is received
      // Somehow change doesn't propagate up if model is supplied
      this.onChange(this.model);
    }
  }

  customDwellTimeChanged() : void {
    let custom = this.customDwellTime.value;

    // If custom value is one of the pre-made dwell times
    if (this.preMadeDwellTime.find(t => t == custom)) {
      this.customDwellTime.setValue(null);
    }
    this.modelFormGroup.controls.dwellTime.setValue(custom);
  }

  preMadeDwellTimeChanged() : void {
    this.customDwellTime.setValue(null);
  }

  onValidationChange: any = () => {};
  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.modelFormGroup?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }
}
