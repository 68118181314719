import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import {AdminGuard} from "./guards/admin.guard";
import {StartupGuard} from "./guards/startup.guard";
import { NativeAppGuard } from './guards/native-app.guard';

const routes: Routes = [
  //
  // Cover all routes with StartupGuard
  //
  {
    path: '',
    canActivateChild: [StartupGuard],
    children: [
      {
        path: ':slug/settings',
        loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
        canActivate: [AuthGuard],
        data: { name: 'settings' }
      },
      {
        path: ':slug/trackmap',
        loadChildren: () =>
          import('./trackmap/trackmap.module').then(
            (m) => m.TrackmapModule
          ),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AdminGuard],
        data: { name: 'admin-settings' }
      },
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'accounts',
        loadChildren: () =>
          import('./pages/accounts/accounts.module').then((m) => m.AccountsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'error',
        loadChildren: () =>
          import('./pages/error/error.module').then((m) => m.ErrorModule)
      },
      {
        path: 'paired',
        loadChildren: () => import('./paired/paired.module').then(m => m.PairedModule),
        canActivate: [NativeAppGuard]
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/home',
      },
      {
        path: 'logout',
        loadChildren: () =>
          import('./logout/logout.module').then((m) => m.LogoutModule),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'pair',
        loadChildren: () => import('./pair/pair.module').then(m => m.PairModule)
      },
    ]
  }
];

@NgModule({
  // NOTE: {onSameUrlNavigation: 'reload'} without providing custom RouteReuseStrategy will only trigger router events and not reload
  // IMPORTANT: try removing the config if unexpected route behaviours are encountered to see if this is the cause
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
