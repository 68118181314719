import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {Trigger} from "../trigger/trigger.component"
import {MyBuddyGard} from "../../domain/models";
import TriggerModel = MyBuddyGard.Domain.Models.Rules.Triggers.PowerTrigger;
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {RulesEngineRepository} from "../../domain/endpoints.repositories";
import PowerStatusType = MyBuddyGard.Domain.Enumerations.Trigger.PowerStatusType;

@Component({
  selector: 'power-trigger',
  templateUrl: './power.trigger.html',
  styleUrls: ['./power.trigger.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PowerTrigger),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PowerTrigger,
      multi: true,
    }
  ]
})
export class PowerTrigger implements Trigger<TriggerModel>, OnInit, OnDestroy {

  modelFormGroup: FormGroup;

  model : TriggerModel;

  supportsHostPowerStatus: boolean = true;

  constructor(
    fb : FormBuilder,
    protected repository : RulesEngineRepository
  ) {
    this.modelFormGroup = fb.group({
      powerOn: true,
      powerStatusType: PowerStatusType.Device
    });
  }

  private _destroy: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.modelFormGroup.valueChanges.pipe(takeUntil(this._destroy)).subscribe(r => {
      this.onChange(Object.assign(this.model, r));
      this.onValidationChange();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  onChange: (model : TriggerModel) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(model: TriggerModel): void {
    if (model == null) {
      // Get default values from API
      this.repository.triggerModelDefault({discriminator: 'PowerTrigger'} as TriggerModel).pipe(
        take(1)
      ).subscribe(r => {
        this.model = r as TriggerModel;
        this.modelFormGroup.patchValue(this.model);
      });
    } else {
      this.model = model;
      this.modelFormGroup.patchValue(model);

      // Acknowledge to parent that model is received
      // Somehow change doesn't propagate up if model is supplied
      this.onChange(this.model);
    }
  }

  onValidationChange: any = () => {};
  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.modelFormGroup?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }
}
