<section [formGroup]="modelFormGroup" *ngIf="model != null">
  <div class="container-row rules-section">
    <div class="section-header">
      <h4>When battery level is</h4>
      <mat-button-toggle-group class="header-toggle-control" formControlName="isAbove">
        <mat-button-toggle [value]="true">Above</mat-button-toggle>
        <mat-button-toggle [value]="false">Below</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="slider-container">
      <div class="slider-label">
        <span class="min-val" *ngIf="modelFormGroup.controls.level?.value != null && modelFormGroup.controls.level.value > 10">5%</span>
        <span class="max-val" *ngIf="modelFormGroup.controls.level?.value != null && modelFormGroup.controls.level.value < 95">100%</span>
      </div>
      <mat-slider [min]="minLevel" [max]="maxLevel" [thumbLabel]="true" step="1" tickInterval="0" [vertical]="false" formControlName="level" (change)="levelChanged()" color="primary"></mat-slider>
    </div>
  </div>
</section>
