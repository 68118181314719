import {Injectable, OnDestroy} from "@angular/core";
import {AngularFireMessaging} from "@angular/fire/messaging";
import {NotificationRepository} from "../domain/endpoints.repositories";
import {catchError, filter, map, mergeMap, take, takeUntil} from "rxjs/operators";
import {BehaviorSubject, from, Observable, of, Subject, throwError} from "rxjs";
import {Capacitor} from "@capacitor/core";
import {Device as CapacitorDevice} from '@capacitor/device';
import {PushNotifications, PushNotificationSchema} from "@capacitor/push-notifications";
import {FCM} from "@capacitor-community/fcm";
import {MyBuddyGard} from "../domain/models";
import MbgPushNotification = MyBuddyGard.Domain.Models.Notifications.PushNotification;
import Groups = MyBuddyGard.Api.Models.Notification.Groups;
import {AndroidPermissions} from "@awesome-cordova-plugins/android-permissions/ngx";

@Injectable({providedIn: 'root'})
export class NotificationHub implements OnDestroy {
  constructor(private fireMessaging: AngularFireMessaging,
              private notification: NotificationRepository,
              private androidPermissions: AndroidPermissions
  ) {

    let requestByPlatform = !Capacitor.isNativePlatform()
      ? this.fireMessaging.requestToken
      : this.requestPermissionNative().pipe(
        filter(granted => granted),
        mergeMap(() => from(PushNotifications.register())),
        mergeMap(() => from(FCM.getToken())),
        map(res => res.token));

    requestByPlatform.pipe(take(1)).subscribe(t => {
      this._deviceRegistrationTokenSubject.next(t);
    });
  }

  private _deviceRegistrationTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _listening = false;

  destroy: Subject<void> = new Subject<void>();

  private _feed: Subject<MbgPushNotification> = new Subject<MbgPushNotification>();
  get feed(): Observable<MbgPushNotification> {
    return this._feed;
  }

  connect(model: Groups.Group): Observable<void> {
    return this._deviceRegistrationTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      mergeMap(token => this.notification.connect({token: token, groups: [model]})),
      catchError(err => {
        console.log(err);
        return throwError(err);
      }),
      map(() => {
        if (!this._listening)
          this.listen();
      })
    );
  }

  disconnect(model: Groups.Group): Observable<void> {
    return this._deviceRegistrationTokenSubject.pipe(
      filter(t => t != null),
      take(1),
      mergeMap(token => this.notification.disconnect({token: token, groups: [model]}))
    )
  }

  private listen(): void {
    if (Capacitor.isNativePlatform()) {

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {

          let push = <MbgPushNotification> {
            title: notification.title,
            message: notification.body,
            data: notification.data,
            dateDelivered: new Date()
          };

          this._feed.next(push);
        }
      );

    } else {
      // Listen for foreground messages
      this.fireMessaging.messages.pipe(takeUntil(this.destroy)).subscribe((m: any) => {

        let push = <MbgPushNotification> {
          title: m.notification.title,
          message: m.notification.body,
          data: m.data,
          dateDelivered: new Date()
        }

        this._feed.next(push);
      });
    }

    this._listening = true;
  }

  ngOnDestroy() {
    if (Capacitor.isNativePlatform())
      PushNotifications.removeAllListeners().then();

    this.destroy.next();
    this.destroy.complete();
  }

  private requestPermissionNative(): Observable<boolean> {
    return from(CapacitorDevice.getInfo()).pipe(
      mergeMap(info => {
        // If device is running Android API level 33 (i.e. Android 13)
        let isAndroid33Plus = info.platform === 'android' && parseInt(info.osVersion) >= 13;

        if (isAndroid33Plus) {
          return from(this.androidPermissions.checkPermission("android.permission.POST_NOTIFICATIONS")).pipe(
            mergeMap(res => res.hasPermission ? of(res) : from(this.androidPermissions.requestPermission("android.permission.POST_NOTIFICATIONS"))),
            map(res => res.hasPermission)
          );
        } else {
          return from(PushNotifications.requestPermissions()).pipe(map(res => res.receive === 'granted'));
        }
      })
    );
  }
}
