import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {combineLatest, Observable, of, throwError} from 'rxjs';
import {ActivatedRoute, Router} from "@angular/router";
import {catchError, filter, map, mergeMap, take} from "rxjs/operators";
import { UserService } from '../service/user.service';
import {AccountService} from "../service/account.service";
import {TokenService} from "../service/token.service";
import {TokenFactory} from "../factories/token.factory";
import {MyBuddyGard} from "../domain/models";
import CustomHttpHeaders = MyBuddyGard.Domain.Constants.CustomHttpHeaders;
import {TimezoneService} from "../service/timezone.service";
import AuthPolicies = MyBuddyGard.Api.Constants.AuthPolicies;

@Injectable()
export class Interceptor implements HttpInterceptor {

  private currentSlug: string = null;
  private currentUserId: string = null;

  constructor(
    protected accounts: AccountService,
    protected users: UserService,
    protected tokenService: TokenService,
    protected tokenFactory: TokenFactory,
    protected route: ActivatedRoute,
    protected timezone: TimezoneService,
    protected router: Router
  ) {
    this.accounts.slug.subscribe(s => this.currentSlug = s);
    this.users.user.subscribe(u => this.currentUserId = u?.id);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const transformRequest = request.headers.get("x-intellis-authorization") === 'true'
      ? of(request.headers.get("x-intellis-authorization-policies")).pipe(
          map(p => p?.split(',')?.includes(AuthPolicies.AllowCognitoIdToken)),
          mergeMap(allowsCognito => combineLatest([of(allowsCognito), this.accounts.slug])),
          filter(([allowsCognito, slug]) => allowsCognito || slug != null),
          mergeMap(([allowsCognito, slug]) => {
            return slug == null && allowsCognito
              // If there's no selected account and requested URL accepts cognito ID token, use ID token
              ? this.users.token.pipe(filter(t => t != null))
              // Else get account access token
              : this.tokenFactory.getToken(slug);
          }),
          take(1),
          map(token => {
            let headers = request.headers.set(CustomHttpHeaders.BrowserTimezone, this.timezone.getBrowserTimezone().iana);
            if (token) {
              headers = headers.set('Authorization', 'Bearer ' + token);
              request = request.clone({headers: headers});
            }
            return request}))
      : of(request);

    return transformRequest.pipe(
      mergeMap(r => next.handle(r)),
      catchError(err => {
        if (err.status === 401) {
          this.tokenService.clearToken(this.currentUserId, this.currentSlug);
          // return of(err.message);
        }
        if (err.status === 402) {
          this.router.navigate(['error', '402']).then();
        }
        // if (err.status === 403) {
        //   // this.router.navigateByUrl('/errors/error-403');
        //   // this.router.navigateByUrl('/errors/error-403');
        //   return of(err.message);
        // }
        // if (err.status === 500) {
        //   // this.alerts.alert("An error occured on the server.", alertStyle.error);
        //   return of(err.message);
        // }
        return throwError(err);
      })
    );
  }
}
