import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {MyBuddyGard} from "../../domain/models";
import TriggerModel = MyBuddyGard.Domain.Models.Rules.Triggers.SpeedTrigger;
import {Trigger} from "../trigger/trigger.component";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {RulesEngineRepository} from "../../domain/endpoints.repositories";

@Component({
  selector: 'speed-trigger',
  templateUrl: './speed.trigger.html',
  styleUrls: ['./speed.trigger.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SpeedTrigger),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SpeedTrigger,
      multi: true,
    }
  ]
})
export class SpeedTrigger implements Trigger<TriggerModel>, OnInit, OnDestroy {

  modelFormGroup: FormGroup;

  model : TriggerModel;

  constructor(
    fb : FormBuilder,
    protected repository : RulesEngineRepository
  ) {
    this.modelFormGroup = fb.group({
      isAbove: false,
      level: null,
    });
  }

  readonly minLevel : number = 5;

  readonly maxLevel : number = 250;

  private _destroy: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.modelFormGroup.valueChanges.pipe(takeUntil(this._destroy)).subscribe(r => {
      this.onChange(Object.assign(this.model, r));
      this.onValidationChange();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  onChange: (model : TriggerModel) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(model: TriggerModel): void {
    if (model == null) {
      // Get default values from API
      this.repository.triggerModelDefault({discriminator: 'SpeedTrigger'} as TriggerModel).pipe(
        take(1)
      ).subscribe(r => {
        this.model = r as TriggerModel;
        this.modelFormGroup.patchValue(this.model);
      });
    } else {
      this.model = model;
      this.modelFormGroup.patchValue(model);

      // Acknowledge to parent that model is received
      // Somehow change doesn't propagate up if model is supplied
      this.onChange(this.model);
    }
  }

  onValidationChange: any = () => {};
  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.modelFormGroup?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  levelChanged() : void {
    if (this.modelFormGroup.controls.level.value == this.minLevel) {
      this.modelFormGroup.controls.isAbove.setValue(true);
    } else if (this.modelFormGroup.controls.level.value == this.maxLevel) {
      this.modelFormGroup.controls.isAbove.setValue(false);
    }
  }
}
