<section [formGroup]="modelFormGroup">
  <div class="container-row rules-section">
    <h4>Turn immobilisation</h4>
    <div>
      <mat-button-toggle-group formControlName="onOff">
        <mat-button-toggle [value]="true">On</mat-button-toggle>
        <mat-button-toggle [value]="false">Off</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</section>