
/**************************************************************************************************************************************

             ▒▒
           ▒▒▒▒▒▒
         ▒▒▒▒▒▒▒▒▒▒
          ▒▒▒▒▒▒▒▒▒▒▒
            ▒▒▒▒▒▒▒
              ▒▒▒                     ██       ██    ██       ████████       ███████      ██            ██           ██        ███████
 ▒▒▒▒▒               ▒▒▒▒▒▒           ██       ███   ██          ██          ██           ██            ██           ██       ██
▒▒▒▒▒▒▒             ▒▒▒▒▒▒▒▒          ██       █████ ██          ██          ███████      ██            ██           ██       ███████
▒▒▒▒▒▒▒              ▒▒▒▒▒▒▒          ██       ██  ████          ██          ██           ██            ██           ██             ██
  ▒▒▒                 ▒▒▒▒            ██       ██    ██          ██          ███████      ███████       ██████       ██       ████████
          ▒▒▒▒
        ▒▒▒▒▒▒▒▒
       ▒▒▒▒▒▒▒▒▒▒▒
         ▒▒▒▒▒▒▒▒▒
           ▒▒▒▒▒

***************************************************************************************************************************************
** Code generated by Intellis.Web.Http.ApplicationSchema.SchemaTypeScriptGenerator ** Changes may be overwritten ** http://intell.is **
**************************************************************************************************************************************/

/* Extend these definitions by inheritance to avoid code being overwritten */

import { Injectable, Optional, Inject, InjectionToken } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpRequest  } from '@angular/common/http';
import { Repository, RepositoryConfig, EntityRepository, SearchableEntityRepository, PatchFeed} from '@intellis/entityrepository';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';



import { Intellis, MyBuddyGard, System } from './models';

export const ACCOUNT_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Account.Account, string>>("Patch feed for AccountController");


@Injectable({providedIn: 'root'})
export class AccountRepository extends SearchableEntityRepository<MyBuddyGard.Domain.Models.Account.Account, MyBuddyGard.Domain.Models.Account.AccountExtract, string, MyBuddyGard.Domain.Models.Account.AccountSearch>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(ACCOUNT_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Account.Account, string> = null) {
        super(config, http, 'account', patchFeed);
    }

    getBySlug(slug: string): Observable<MyBuddyGard.Domain.Models.Account.AccountInfo> {
        let params = new HttpParams()
            .set('slug',slug.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Account.AccountInfo>(this.config.apiBase + this.endpoint + '/getBySlug', { params: params, headers: headers });
    }

    checkSlugAvailability(slug: string): Observable<boolean> {
        let params = new HttpParams()
            .set('slug',slug.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<boolean>(this.config.apiBase + this.endpoint + '/checkSlugAvailability', { params: params, headers: headers });
    }

    getBySlugWithUsers(slug: string): Observable<MyBuddyGard.Domain.Models.Account.AccountWithUsers> {
        let params = new HttpParams()
            .set('slug',slug.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Account.AccountWithUsers>(this.config.apiBase + this.endpoint + '/getBySlugWithUsers', { params: params, headers: headers });
    }

    save(model: MyBuddyGard.Domain.Models.Account.AccountInfo): Observable<MyBuddyGard.Domain.Models.Account.AccountInfo> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Account.AccountInfo>(this.config.apiBase + this.endpoint + '/save', <any>model, { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class AccountPaymentsRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'accountpayments');
    }

    setup(type: string): Observable<MyBuddyGard.Domain.Models.Payments.SetupResult> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Payments.SetupResult>(this.config.apiBase + this.endpoint + '/setup', <any>type, { params: params, headers: headers });
    }

    cancelSetup(request: MyBuddyGard.Api.Models.CancelAccountPaymentsSetupRequest): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/setup', <any>request, { params: params, headers: headers });
    }

    getPaymentMethods(): Observable<(MyBuddyGard.Domain.Models.Payments.PaymentMethod)[]> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Payments.PaymentMethod)[]>(this.config.apiBase + this.endpoint + '/paymentMethods', { params: params, headers: headers });
    }

    setDefaultPayment(model: MyBuddyGard.Domain.Models.Payments.PaymentMethod): Observable<MyBuddyGard.Domain.Models.Payments.PaymentMethod> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Payments.PaymentMethod>(this.config.apiBase + this.endpoint + '/default', <any>model, { params: params, headers: headers });
    }

    delete(id: string): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/delete/' + id + '', <any>id, { params: params, headers: headers });
    }

    postPaymentAttempt(model: MyBuddyGard.Domain.Models.PaymentAttemptWithStripeStatus): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/paymentAttempt', <any>model, { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class AutomationRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'automation');
    }

    renewSubscriptions(): Observable<MyBuddyGard.Domain.Models.Subscriptions.Subscription> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Subscriptions.Subscription>(this.config.apiBase + this.endpoint + '/renewSubscriptions', { params: params, headers: headers });
    }
}

export const CHARGE_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Charges.Charge, number>>("Patch feed for ChargeController");


@Injectable({providedIn: 'root'})
export class ChargeRepository extends SearchableEntityRepository<MyBuddyGard.Domain.Models.Charges.Charge, MyBuddyGard.Domain.Models.Charges.ChargeExtract, number, MyBuddyGard.Domain.Models.Charges.ChargeSearch>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(CHARGE_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Charges.Charge, number> = null) {
        super(config, http, 'charge', patchFeed);
    }

    getByNumber(number: number): Observable<MyBuddyGard.Domain.Models.Charges.ChargeExtended> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Charges.ChargeExtended>(this.config.apiBase + this.endpoint + '/number/' + number + '', { params: params, headers: headers });
    }

    post(model: MyBuddyGard.Domain.Models.Charges.Charge): Observable<MyBuddyGard.Domain.Models.Charges.Charge> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Charges.Charge>(this.config.apiBase + this.endpoint, <any>model, { params: params, headers: headers });
    }

    initialise(model: MyBuddyGard.Domain.Models.Charges.Charge, savePayment: boolean): Observable<string> {
        let params = new HttpParams()
            .set('savePayment',savePayment.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<string>(this.config.apiBase + this.endpoint + '/initialise', <any>model, { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class CommandRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'command');
    }

    getQueued(deviceType: string, trackerId: string): Observable<any> {
        let params = new HttpParams()
            .set('deviceType',deviceType.toString())
            .set('trackerId',trackerId.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<any>(this.config.apiBase + this.endpoint + '/getQueued', { params: params, headers: headers });
    }

    post(model: MyBuddyGard.Api.Models.SendCommand): Observable<MyBuddyGard.Domain.Models.DeviceCommandResponse> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.DeviceCommandResponse>(this.config.apiBase + this.endpoint, <any>model, { params: params, headers: headers });
    }

    delete(request: MyBuddyGard.Api.Models.DeleteCommandRequest): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/delete', <any>request, { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class ConfigurationsRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'configurations');
    }

    getTrackmap(): Observable<MyBuddyGard.Api.Config.Trackmap.ITrackmapConfig> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Api.Config.Trackmap.ITrackmapConfig>(this.config.apiBase + this.endpoint + '/trackmap', { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class DeployRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'deploy');
    }

    createSchema(): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<any>(this.config.apiBase + this.endpoint + '/createschema', { params: params, headers: headers });
    }

    migrateUserAccounts(token: string): Observable<any> {
        let params = new HttpParams()
            .set('token',token.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<any>(this.config.apiBase + this.endpoint + '/migrateUserAccounts', { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class DeviceAuthorizationRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'deviceauthorization');
    }

    pair(model: MyBuddyGard.Domain.Models.Devices.Pairing.DevicePairingInfo): Observable<MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo>(this.config.apiBase + this.endpoint + '/pair', <any>model, { params: params, headers: headers });
    }

    unpair(token: string): Observable<any> {
        let params = new HttpParams()
            .set('token',token.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<any>(this.config.apiBase + this.endpoint + '/unpair', { params: params, headers: headers });
    }

    getPairedDevice(token: string): Observable<MyBuddyGard.Domain.Models.Devices.Device> {
        let params = new HttpParams()
            .set('token',token.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Devices.Device>(this.config.apiBase + this.endpoint + '/pairedDevice', { params: params, headers: headers });
    }

    postAuthorizationRequest(request: MyBuddyGard.Domain.Models.Devices.Pairing.DevicePairingInfo): Observable<MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationCode> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationCode>(this.config.apiBase + this.endpoint + '/authorizationRequest', <any>request, { params: params, headers: headers });
    }

    deleteAuthorizationRequest(deviceCode: string): Observable<any> {
        let params = new HttpParams()
            .set('deviceCode',deviceCode.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.delete<any>(this.config.apiBase + this.endpoint + '/authorizationRequest', { params: params, headers: headers });
    }

    postAuthorize(request: MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationRequest): Observable<MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationResponse> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Devices.Pairing.DeviceAuthorizationResponse>(this.config.apiBase + this.endpoint + '/authorize', <any>request, { params: params, headers: headers });
    }

    getToken(deviceCode: string): Observable<MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo> {
        let params = new HttpParams()
            .set('deviceCode',deviceCode.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Devices.Pairing.PlatformDevicePairingInfo>(this.config.apiBase + this.endpoint + '/token', { params: params, headers: headers });
    }

    postValidateTokens(tokens: (string)[]): Observable<MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResponse> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Api.Models.Pairing.DeviceTokenValidationResponse>(this.config.apiBase + this.endpoint + '/validateTokens', <any>tokens, { params: params, headers: headers });
    }
}

export const DEVICE_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Devices.Device, string>>("Patch feed for DeviceController");


@Injectable({providedIn: 'root'})
export class DeviceRepository extends EntityRepository<MyBuddyGard.Domain.Models.Devices.Device,string>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(DEVICE_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Devices.Device, string> = null) {
        super(config, http, 'device', patchFeed);
    }

    getAccountDevices(slug: string): Observable<(MyBuddyGard.Domain.Models.Devices.Device)[]> {
        let params = new HttpParams()
            .set('slug',slug.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Devices.Device)[]>(this.config.apiBase + this.endpoint + '/accountDevices', { params: params, headers: headers });
    }

    sendCommand(trackerId: string, command: string): Observable<any> {
      let params = new HttpParams()
        .set('trackerId',trackerId.toString())
        .set('command', command.toString())
      ;
      let headers = new HttpHeaders()
        .set('x-intellis-client-tag', this.clientTag)
        .set('x-intellis-authorization', 'true')
        .set('x-intellis-authorization-policies', '')
      ;
      const response = this.http.post<any>(
        `${this.config.apiBase}${this.endpoint}/sendCommand`,
        null,
        { params: params, headers: headers }
      );

      response.subscribe(
        (res) => {
          console.log('HTTP Response:', res);
        },
        (err) => {
          console.error('HTTP Error:', err);
        }
      );
      return response;
    }

    save(model: MyBuddyGard.Domain.Models.Devices.SaveDevice): Observable<MyBuddyGard.Domain.Models.Devices.SaveDeviceResponse> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Devices.SaveDeviceResponse>(this.config.apiBase + this.endpoint + '/save', <any>model, { params: params, headers: headers });
    }

    setImmobilisation(model: MyBuddyGard.Domain.Models.Devices.Device): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/immobilisation', <any>model, { params: params, headers: headers });
    }

    deleteDevice(id: string): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/delete/' + id + '', <any>id, { params: params, headers: headers });
    }
}

export const GEOFENCE_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Geofences.Geofence, string>>("Patch feed for GeofenceController");


@Injectable({providedIn: 'root'})
export class GeofenceRepository extends EntityRepository<MyBuddyGard.Domain.Models.Geofences.Geofence,string>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(GEOFENCE_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Geofences.Geofence, string> = null) {
        super(config, http, 'geofence', patchFeed);
    }

    accountGeofences(): Observable<(MyBuddyGard.Domain.Models.Geofences.Geofence)[]> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Geofences.Geofence)[]>(this.config.apiBase + this.endpoint + '/accountGeofences', { params: params, headers: headers });
    }

    save(model: MyBuddyGard.Domain.Models.Geofences.Geofence): Observable<MyBuddyGard.Domain.Models.Geofences.Geofence> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Geofences.Geofence>(this.config.apiBase + this.endpoint + '/save', <any>model, { params: params, headers: headers });
    }

    deleteGeofence(id: string): Observable<MyBuddyGard.Domain.Models.Geofences.Geofence> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Geofences.Geofence>(this.config.apiBase + this.endpoint + '/delete/' + id + '', <any>id, { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class HealthCheckRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'healthcheck');
    }

    get(): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<any>(this.config.apiBase + this.endpoint, { params: params, headers: headers });
    }
}

export const LOCATION_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Locations.Location, number>>("Patch feed for LocationController");


@Injectable({providedIn: 'root'})
export class LocationRepository extends EntityRepository<MyBuddyGard.Domain.Models.Locations.Location,number>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(LOCATION_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Locations.Location, number> = null) {
        super(config, http, 'location', patchFeed);
    }

    updateLocation(location: MyBuddyGard.Domain.Models.DeviceLocation): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/updateLocation', <any>location, { params: params, headers: headers });
    }

    postPairedDevice(request: MyBuddyGard.Api.Models.DeviceLocationAndToken): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/paired-device', <any>request, { params: params, headers: headers });
    }

    deviceHistory(deviceId: string, start: Date, end: Date): Observable<(MyBuddyGard.Domain.Models.Locations.Location)[]> {
        let params = new HttpParams()
            .set('deviceId',deviceId.toString())
            .set('start',start.toString())
            .set('end',end.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Locations.Location)[]>(this.config.apiBase + this.endpoint + '/deviceHistory', { params: params, headers: headers });
    }

    debug(enable: boolean, deviceId: string | null): Observable<any> {
        let params = new HttpParams()
            .set('enable',enable.toString())
            .set('deviceId',deviceId.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<any>(this.config.apiBase + this.endpoint + '/debug', { params: params, headers: headers });
    }

    getDeviceLastLocation(deviceId: string): Observable<MyBuddyGard.Domain.Models.Locations.Location> {
        let params = new HttpParams()
            .set('deviceId',deviceId.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Locations.Location>(this.config.apiBase + this.endpoint + '/device-last', { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class MeRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'me');
    }

    myAccounts(token: string): Observable<(MyBuddyGard.Domain.Models.Account.AccountExtract)[]> {
        let params = new HttpParams()
            .set('token',token.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Account.AccountExtract)[]>(this.config.apiBase + this.endpoint + '/myAccounts', { params: params, headers: headers });
    }

    getAccessToken(slug: string, token: string): Observable<any> {
        let params = new HttpParams()
            .set('slug',slug.toString())
            .set('token',token.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<any>(this.config.apiBase + this.endpoint + '/getAccessToken', { params: params, headers: headers });
    }

    resetPassword(request: MyBuddyGard.Api.Models.ResetPassword.ResetPasswordRequest): Observable<MyBuddyGard.Api.Models.ResetPassword.ResetPasswordResponse> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'false')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Api.Models.ResetPassword.ResetPasswordResponse>(this.config.apiBase + this.endpoint + '/resetPassword', <any>request, { params: params, headers: headers });
    }
}

@Injectable({providedIn: 'root'})
export class NotificationRepository extends Repository {

    constructor(config: RepositoryConfig, http: HttpClient) {
        super(config, http, 'notification');
    }

    connect(request: MyBuddyGard.Api.Models.Notification.ConnectionRequest): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/connect', <any>request, { params: params, headers: headers });
    }

    disconnect(request: MyBuddyGard.Api.Models.Notification.ConnectionRequest): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/disconnect', <any>request, { params: params, headers: headers });
    }

    sendTestNotification(target: MyBuddyGard.Api.Models.Notification.Groups.Group): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/send-test', <any>target, { params: params, headers: headers });
    }

    unread(ascending: boolean): Observable<(MyBuddyGard.Domain.Models.Notifications.INotification)[]> {
        let params = new HttpParams()
            .set('ascending',ascending.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Notifications.INotification)[]>(this.config.apiBase + this.endpoint + '/unread', { params: params, headers: headers });
    }

    seen(notifications: (MyBuddyGard.Domain.Models.Notifications.PushNotification)[]): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/seen', <any>notifications, { params: params, headers: headers });
    }
}

export const PLAN_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Plans.Plan, string>>("Patch feed for PlanController");


@Injectable({providedIn: 'root'})
export class PlanRepository extends SearchableEntityRepository<MyBuddyGard.Domain.Models.Plans.Plan, MyBuddyGard.Domain.Models.Plans.PlanExtract, string, MyBuddyGard.Domain.Models.Plans.PlanSearch>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(PLAN_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Plans.Plan, string> = null) {
        super(config, http, 'plan', patchFeed);
    }

    all(): Observable<(MyBuddyGard.Domain.Models.Plans.Plan)[]> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Plans.Plan)[]>(this.config.apiBase + this.endpoint + '/all', { params: params, headers: headers });
    }

    available(): Observable<(MyBuddyGard.Domain.Models.Plans.Plan)[]> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Plans.Plan)[]>(this.config.apiBase + this.endpoint + '/available', { params: params, headers: headers });
    }

    checkSlugAvailability(slug: string): Observable<boolean> {
        let params = new HttpParams()
            .set('slug',slug.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<boolean>(this.config.apiBase + this.endpoint + '/checkSlugAvailability', { params: params, headers: headers });
    }

    save(model: MyBuddyGard.Api.Models.SavePlan): Observable<MyBuddyGard.Domain.Models.Plans.Plan> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Plans.Plan>(this.config.apiBase + this.endpoint + '/save', <any>model, { params: params, headers: headers });
    }

    deletePlan(id: string): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/delete/' + id + '', <any>id, { params: params, headers: headers });
    }
}

export const RULESENGINE_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Rules.Rule, string>>("Patch feed for RulesEngineController");


@Injectable({providedIn: 'root'})
export class RulesEngineRepository extends EntityRepository<MyBuddyGard.Domain.Models.Rules.Rule,string>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(RULESENGINE_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Rules.Rule, string> = null) {
        super(config, http, 'rulesengine', patchFeed);
    }

    getByAccount(): Observable<(MyBuddyGard.Domain.Models.Rules.Rule)[]> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Rules.Rule)[]>(this.config.apiBase + this.endpoint, { params: params, headers: headers });
    }

    triggerModelDefault(model: MyBuddyGard.Domain.Models.Rules.Triggers.Trigger): Observable<MyBuddyGard.Domain.Models.Rules.Triggers.Trigger> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Rules.Triggers.Trigger>(this.config.apiBase + this.endpoint + '/triggerModelDefault', <any>model, { params: params, headers: headers });
    }

    save(model: MyBuddyGard.Domain.Models.Rules.Rule): Observable<MyBuddyGard.Domain.Models.Rules.Rule> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Rules.Rule>(this.config.apiBase + this.endpoint + '/save', <any>model, { params: params, headers: headers });
    }

    deleteRule(id: string): Observable<MyBuddyGard.Domain.Models.Rules.Rule> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Rules.Rule>(this.config.apiBase + this.endpoint + '/delete/' + id + '', <any>id, { params: params, headers: headers });
    }

    getActiveActionTimers(): Observable<MyBuddyGard.Domain.Models.Rules.ActionTimerResult> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Rules.ActionTimerResult>(this.config.apiBase + this.endpoint + '/actionTimer', { params: params, headers: headers });
    }

    cancelActionTimer(timerId: string): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/actionTimer/' + timerId + '', <any>timerId, { params: params, headers: headers });
    }
}

export const SUBSCRIPTION_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Subscriptions.Subscription, string>>("Patch feed for SubscriptionController");


@Injectable({providedIn: 'root'})
export class SubscriptionRepository extends EntityRepository<MyBuddyGard.Domain.Models.Subscriptions.Subscription,string>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(SUBSCRIPTION_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Subscriptions.Subscription, string> = null) {
        super(config, http, 'subscription', patchFeed);
    }

    getAccountSubscriptions(slug: string): Observable<(MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage)[]> {
        let params = new HttpParams()
            .set('slug',slug.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage)[]>(this.config.apiBase + this.endpoint + '/accountSubscriptions', { params: params, headers: headers });
    }

    getWithCoverage(id: string): Observable<MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage> {
        let params = new HttpParams()
            .set('id',id.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Subscriptions.SubscriptionWithCoverage>(this.config.apiBase + this.endpoint + '/getWithCoverage', { params: params, headers: headers });
    }

    cancel(id: string): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/cancel/' + id + '', <any>id, { params: params, headers: headers });
    }

    defaultRenewalOption(model: MyBuddyGard.Domain.Models.Subscriptions.Subscription): Observable<any> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.post<any>(this.config.apiBase + this.endpoint + '/defaultRenewalOption', <any>model, { params: params, headers: headers });
    }
}

export const USERS_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Users.User, string>>("Patch feed for UsersController");


@Injectable({providedIn: 'root'})
export class UsersRepository extends EntityRepository<MyBuddyGard.Domain.Models.Users.User,string>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(USERS_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Users.User, string> = null) {
        super(config, http, 'users', patchFeed);
    }

    tokenedSearch(parameters: MyBuddyGard.Domain.Models.Users.UserSearch): Observable<MyBuddyGard.Domain.Models.Users.TokenedUserExtractCollection> {
        let params = <any>parameters
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Users.TokenedUserExtractCollection>(this.config.apiBase + this.endpoint + '/tokenedsearch', { params: params, headers: headers });
    }

    getByUserName(userName: string): Observable<MyBuddyGard.Domain.Models.Users.User> {
        let params = new HttpParams()
            .set('userName',userName.toString())
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.get<MyBuddyGard.Domain.Models.Users.User>(this.config.apiBase + this.endpoint + '/getByUserName', { params: params, headers: headers });
    }

    save(model: MyBuddyGard.Domain.Models.Users.UserForm): Observable<MyBuddyGard.Domain.Models.Users.User> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', 'AllowCognitoIdToken')
        ;
        return this.http.post<MyBuddyGard.Domain.Models.Users.User>(this.config.apiBase + this.endpoint + '/save', <any>model, { params: params, headers: headers });
    }
}

export const WIFI_PATCH_FEED = new InjectionToken<PatchFeed<MyBuddyGard.Domain.Models.Wifi, string>>("Patch feed for WifiController");


@Injectable({providedIn: 'root'})
export class WifiRepository extends EntityRepository<MyBuddyGard.Domain.Models.Wifi,string>  {

    constructor(config: RepositoryConfig, http: HttpClient, @Optional() @Inject(WIFI_PATCH_FEED) protected patchFeed: PatchFeed<MyBuddyGard.Domain.Models.Wifi, string> = null) {
        super(config, http, 'wifi', patchFeed);
    }

    recent(): Observable<(MyBuddyGard.Domain.Models.Wifi)[]> {
        let params = new HttpParams()
        ;
        let headers = new HttpHeaders()
            .set('x-intellis-client-tag', this.clientTag)
            .set('x-intellis-authorization', 'true')
            .set('x-intellis-authorization-policies', '')
        ;
        return this.http.get<(MyBuddyGard.Domain.Models.Wifi)[]>(this.config.apiBase + this.endpoint + '/recent', { params: params, headers: headers });
    }
}
