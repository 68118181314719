<section [formGroup]="modelFormGroup" *ngIf="model != null">
  <div class="container-row rules-section">
    <h4>Geofence</h4>
    <mat-form-field appearance="fill">
      <mat-label>Select a geofence</mat-label>
      <mat-select formControlName="geofenceId">
        <mat-option *ngFor="let geofence of geofences" [value]="geofence.id">{{geofence.name}}</mat-option>
      </mat-select>
      <mat-error *ngIf="modelFormGroup.controls.geofenceId.invalid">Please select a geofence</mat-error>
    </mat-form-field>
  </div>

  <div class="container-row rules-section">
    <h4>Action</h4>
    <div>
      <mat-button-toggle-group formControlName="actionType">
        <mat-button-toggle [value]="actionType.Enter">Enter</mat-button-toggle>
        <mat-button-toggle [value]="actionType.Exit">Exit</mat-button-toggle>
        <mat-button-toggle [value]="actionType.Dwell">Dwell</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="container-row rules-section" *ngIf="modelFormGroup.controls.actionType.value == actionType.Dwell">
    <h4>Condition</h4>
    <div>
      <mat-button-toggle-group formControlName="dwellCondition">
        <mat-button-toggle [value]="dwellCondition.Inside">In</mat-button-toggle>
        <mat-button-toggle [value]="dwellCondition.Outside">Out</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="container-row rules-section" *ngIf="modelFormGroup.controls.actionType.value == actionType.Dwell">
    <h4>Duration</h4>
    <div>
      <mat-button-toggle-group formControlName="dwellTime" (change)="preMadeDwellTimeChanged()">
        <mat-button-toggle *ngFor="let dwellTime of preMadeDwellTime" [value]="dwellTime">{{dwellTime}}</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <mat-form-field class="custom-duration-input" appearance="fill">
      <mat-label>or custom duration</mat-label>
      <input matInput type="number" [formControl]="customDwellTime" (change)="customDwellTimeChanged()">
    </mat-form-field>
  </div>

  <div class="container-row rules-section" *ngIf="modelFormGroup.controls.actionType.value == actionType.Dwell">
    <h4>Unit</h4>
    <div>
      <mat-button-toggle-group formControlName="dwellTimeUnit">
        <mat-button-toggle [value]="dwellTimeUnit.Seconds">Seconds</mat-button-toggle>
        <mat-button-toggle [value]="dwellTimeUnit.Minutes">Minutes</mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
</section>
