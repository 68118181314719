import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from "rxjs";
import {AccountService} from "./account.service";
import {switchMap, takeUntil, tap} from "rxjs/operators";
import {NotificationRepository} from "../domain/endpoints.repositories";
import {MyBuddyGard} from "../domain/models";
import {AngularFireMessaging} from "@angular/fire/messaging";
import {PluginListenerHandle} from "@capacitor/core";
import {NotificationHub} from "../notification/notification-hub";
import MbgPushNotification = MyBuddyGard.Domain.Models.Notifications.PushNotification;
import {App} from "@capacitor/app";
import Groups = MyBuddyGard.Api.Models.Notification.Groups;

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {

  constructor(private accounts: AccountService,
              private fireMessaging: AngularFireMessaging,
              private notification: NotificationRepository,
              private hub: NotificationHub) {
  }

  protected destroy: Subject<void> = new Subject<void>();

  private _subscribed = false;
  private readonly _checkInterval = 30000; // in ms
  private _appStateChangeListenerHandle: PluginListenerHandle
  private _listening = false;
  private _timeoutId = null;
  private _timeout = true;

  private _list: BehaviorSubject<MbgPushNotification[]> = new BehaviorSubject<MbgPushNotification[]>([]);
  get list(): Observable<MbgPushNotification[]> {
    return this._list;
  };

  subscribeToFeed(): void {
    if (this._subscribed)
      return;

    // Load unread notifications (account-related only)
    this.accounts.slug.pipe(
      tap(() => this._list.next([])),
      switchMap(s => combineLatest([
        of(s),
        s != null
          ? this.notification.unread(false)
          : of([])
      ])),
      takeUntil(this.destroy)
    ).subscribe(([slug, unread]) => {
      this._list.next(unread);

      if (!this._listening)
        this._listening = true;
    });

    this._appStateChangeListenerHandle = App.addListener("appStateChange", (appState) => {
      if (!this._listening)
        return;

      if (this._timeout && this._timeoutId == null) {
        this._timeoutId = setTimeout(() => {
          this._timeout = false;
          this._timeoutId = null;
        }, this._checkInterval);
      } else if (appState.isActive && !this._timeout) {
        this._timeout = true;
        this.notification.unread(true).subscribe(l => this._list.next(l));
      }
    });

    // Subscribe to notification feed
    // Note: Messages will not start coming in until user or account is connected
    this.hub.feed.subscribe(m => {
      this._list.next([m, ...this._list.value]);
    });

    this._subscribed = true;
  }

  connectUser(): Observable<void> {
    return this.hub.connect(<Groups.User> { discriminator: 'User' });
  }

  connectAccount(slug: string): Observable<void> {
    return this.hub.connect(<Groups.Account> { discriminator: 'Account', slug: slug });
  }

  disconnectUser(): Observable<void> {
    return this.hub.disconnect(<Groups.User> { discriminator: 'User' });
  }

  disconnectAccount(slug: string): Observable<void> {
    return this.hub.disconnect(<Groups.Account> { discriminator: 'Account', slug: slug });
  }

  clearList(): void {
    this.notification.seen(this._list.value)
      .subscribe(() => this._list.next([]));
  }

  ngOnDestroy() {
    this._appStateChangeListenerHandle.remove().then();
    this.destroy.next();
    this.destroy.complete();
  }
}
