import {ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree} from "@angular/router";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {StartupService} from "../startup/startup.service";

@Injectable({
  providedIn: 'root'
})
export class StartupGuard implements CanActivateChild {
  constructor(private startupService: StartupService) {
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Activation rules are in the service
    return this.startupService.canActivate(state.url);
  }
}
