import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  setToken(userId: string, slug: string, token: string) {
    localStorage.setItem(`token:${userId}:${slug}`, token);
  }

  clearToken(userId: string, slug: string) {
    localStorage.removeItem(`token:${userId}:${slug}`);
  }

  clearTokens(userId: string) {
    let keys = [];
    for(let i = 0; i < localStorage.length; i++) {
      if(localStorage.key(i).startsWith(`token:${userId}:`))
        keys.push(localStorage.key(i));
    }
    keys.forEach(k => localStorage.removeItem(k));
  }
}
