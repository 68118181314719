import {Country} from "./country";

export class Timezone {
  // Readable name
  name: string;

  // Name from IANA database
  iana: string;

  countries: Country[]
}
