import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validators
} from "@angular/forms";
import {Action} from "../action/action.component";
import {MyBuddyGard} from "../../domain/models";
import ActionModel = MyBuddyGard.Domain.Models.Rules.Actions.EmailAction;
import {filter, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {RulesEngineRepository} from "../../domain/endpoints.repositories";

@Component({
  selector: 'email-action',
  templateUrl: './email.action.html',
  styleUrls: ['./email.action.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailAction),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EmailAction,
      multi: true,
    }
  ]
})
export class EmailAction implements Action<ActionModel>, OnInit, OnDestroy {

  modelFormGroup: FormGroup;

  model : ActionModel;

  mergeFieldSelector : FormControl;

  @Input('data') data : any;

  constructor(
    protected fb : FormBuilder,
    protected repository : RulesEngineRepository
    ) {
    this.modelFormGroup = fb.group({
      sendTo: ['', Validators.required],
      message: '',
    });

    this.model = {
      sendTo: '',
      message: '',
      discriminator : 'EmailAction'
    } as ActionModel;

    this.mergeFieldSelector = new FormControl('');

    this.mergeFieldSelector.valueChanges
      .pipe(
        filter(val => val != null),
        takeUntil(this._destroy)
      ).subscribe(v => {
        // Automatically add value to text area

        this.insertField();
      });
  }

  private _destroy: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.modelFormGroup.valueChanges.pipe(takeUntil(this._destroy)).subscribe(r => {
      this.onChange(Object.assign(this.model, r));
      this.onValidationChange();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  onChange: (model : ActionModel) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(model: ActionModel): void {
    if (model != null) {
      this.model = model;
      this.modelFormGroup.patchValue(model);
    } else {
      // Acknowledge to parent that model is received
      // Somehow change doesn't propagate up if model is supplied
      this.onChange(this.model);
    }
  }

  onValidationChange: any = () => {};
  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.modelFormGroup?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  insertField() : void {
    let currentMessage = this.modelFormGroup.controls.message.value ?? '';
    let mergeField = this.mergeFieldSelector.value ?? '';

    currentMessage += mergeField != ''
      ? `{{${mergeField}}}`
      : '';

    this.modelFormGroup.controls.message.setValue(currentMessage);
    this.mergeFieldSelector.setValue(null);
  }
}
