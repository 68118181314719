import {Component, forwardRef, OnDestroy, OnInit} from '@angular/core';
import {Trigger} from "../trigger/trigger.component";
import {MyBuddyGard} from "../../domain/models";
import TriggerModel = MyBuddyGard.Domain.Models.Rules.Triggers.GeofenceTrigger;
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR, ValidationErrors,
  Validators
} from "@angular/forms";
import {take, takeUntil} from "rxjs/operators";
import Geofence = MyBuddyGard.Domain.Models.Geofences.Geofence;
import GeofenceActionType = MyBuddyGard.Domain.Enumerations.Trigger.GeofenceActionType;
import DwellCondition = MyBuddyGard.Domain.Enumerations.Trigger.DwellCondition;
import DwellTimeUnit = MyBuddyGard.Domain.Enumerations.Trigger.DwellTimeUnit;
import {Subject} from "rxjs";
import {GeofenceRepository, RulesEngineRepository} from "../../domain/endpoints.repositories";

@Component({
  selector: 'geofence-trigger',
  templateUrl: './geofence.trigger.html',
  styleUrls: ['./geofence.trigger.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeofenceTrigger),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: GeofenceTrigger,
      multi: true,
    }
  ]
})
export class GeofenceTrigger implements Trigger<TriggerModel>, OnInit, OnDestroy {

  constructor(
    fb : FormBuilder,
    protected repository : RulesEngineRepository,
    protected geofenceRepository : GeofenceRepository
  ) {
    this.modelFormGroup = fb.group({
      actionType: null,
      dwellCondition: null,
      dwellTime: null,
      geofenceId: [null, Validators.required],
      dwellTimeUnit: null,
    });

    this.preMadeDwellTime = [30, 60, 90, 120];
    this.customDwellTime = new FormControl(null);
  }

  modelFormGroup: FormGroup;
  model : TriggerModel;
  geofences : Geofence[];
  preMadeDwellTime : number[];
  customDwellTime : FormControl;

  private _destroy: Subject<void> = new Subject<void>();

  get actionType() {
    return GeofenceActionType;
  }

  get dwellCondition() {
    return DwellCondition;
  }

  get dwellTimeUnit() {
    return DwellTimeUnit;
  }

  ngOnInit(): void {
    this.modelFormGroup.valueChanges.pipe(takeUntil(this._destroy)).subscribe(r => {
      this.onChange(Object.assign(this.model, r));
      this.onValidationChange();
    });

    // Get geofence list
    this.geofenceRepository.accountGeofences()
      .pipe(take(1))
      .subscribe(res => {
        this.geofences = res;
      });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  onChange: (model : TriggerModel) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(model: TriggerModel): void {
    if (model == null) {
      // Get default values from API
      this.repository.triggerModelDefault({discriminator: 'GeofenceTrigger'} as TriggerModel).pipe(
        take(1)
      ).subscribe(r => {
        this.model = r as TriggerModel;
        this.modelFormGroup.patchValue(this.model);
      });
    } else {
      this.model = model;
      this.modelFormGroup.patchValue(this.model);

      // Resolve custom dwell time display
      let dwellTime = this.modelFormGroup.controls.dwellTime.value;
      if (this.preMadeDwellTime.find(t => t == dwellTime) == undefined) {
        this.customDwellTime.setValue(dwellTime);
      }

      // Acknowledge to parent that model is received
      // Somehow change doesn't propagate up if model is supplied
      this.onChange(this.model);
    }
  }

  customDwellTimeChanged() : void {
    let custom = this.customDwellTime.value;

    // If custom value is one of the pre-made dwell times
    if (this.preMadeDwellTime.find(t => t == custom)) {
      this.customDwellTime.setValue(null);
    }
    this.modelFormGroup.controls.dwellTime.setValue(custom);
  }

  preMadeDwellTimeChanged() : void {
    this.customDwellTime.setValue(null);
  }

  onValidationChange: any = () => {};
  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.modelFormGroup?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }
}
