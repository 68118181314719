import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors
} from "@angular/forms";
import {MyBuddyGard} from "../../domain/models";
import ActionModel = MyBuddyGard.Domain.Models.Rules.Actions.LocateAction;
import {Action} from "../action/action.component";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'locate-action',
  templateUrl: './locate.action.html',
  styleUrls: ['./locate.action.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocateAction),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LocateAction,
      multi: true,
    }
  ]
})
export class LocateAction implements Action<ActionModel>, OnInit, OnDestroy {

  modelFormGroup: FormGroup;

  model : ActionModel;

  @Input('data') data : any;

  constructor(protected fb : FormBuilder) {
    this.modelFormGroup = fb.group({ });

    this.model = {
      discriminator: 'LocateAction'
    } as ActionModel;
  }

  private _destroy: Subject<void> = new Subject<void>();

  ngOnInit(): void {
    this.modelFormGroup.valueChanges.pipe(takeUntil(this._destroy)).subscribe(r => {
      this.onChange(Object.assign(this.model, r));
      this.onValidationChange();
    });
  }

  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  onChange: (model : ActionModel) => void;
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  writeValue(model: ActionModel): void {
    if (model != null) {
      this.model = model;
      this.modelFormGroup.patchValue(model);
    } else {
      // Acknowledge to parent that model is received
      // Somehow change doesn't propagate up if model is supplied
      this.onChange(this.model);
    }
  }

  onValidationChange: any = () => {};
  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.modelFormGroup?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }
}
